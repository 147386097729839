import styled from 'styled-components/macro';
import ReadMore from '../../../ReadMore/ReadMore';

export const Container = styled.div`
  display: flex;
`;

export const InputLabel = styled.label`
  margin-right: 0.5rem;
  white-space: nowrap;
`;

export const ReadMoreLink = styled(ReadMore)<{ withMargin?: boolean }>`
  margin-left: ${({ withMargin }) => (withMargin ? 'auto' : undefined)};
`;
