import { AllowListActionTypes, IAllowListState, AllowListTypesEnum } from './allowList.types';

const initialState = {
  allowList: { status: 'idle', data: {}, error: '' } as IAllowListState,
  applyChanges: { status: 'idle', error: '' } as BaseState
};

export type AllowListState = typeof initialState;

const allowListReducer = (state = initialState, action: AllowListActionTypes): AllowListState => {
  switch (action.type) {
    case AllowListTypesEnum.GET_ALLOW_LIST_REQUEST:
      return {
        ...state,
        allowList: {
          ...state.allowList,
          status: 'pending',
          error: ''
        }
      };
    case AllowListTypesEnum.APPLY_ALL_CHANGES_REQUEST:
      return {
        ...state,
        applyChanges: {
          status: 'pending',
          error: ''
        }
      };
    case AllowListTypesEnum.GET_ALLOW_LIST_SUCCESS:
      return {
        ...state,
        allowList: {
          ...state.allowList,
          data: { ...action.payload },
          status: 'resolved'
        }
      };
    case AllowListTypesEnum.APPLY_ALL_CHANGES_SUCCESS:
      return {
        ...state,
        allowList: {
          ...state.allowList,
          data: { ...action.payload },
          status: 'resolved'
        },
        applyChanges: {
          status: 'resolved',
          error: ''
        }
      };

    case AllowListTypesEnum.GET_ALLOW_LIST_FAILURE:
      return {
        ...state,
        allowList: {
          ...state.allowList,
          status: 'rejected',
          error: action.payload
        }
      };

    case AllowListTypesEnum.APPLY_ALL_CHANGES_FAILURE:
      return {
        ...state,
        applyChanges: {
          status: 'rejected',
          error: action.payload
        }
      };
    case AllowListTypesEnum.CLEAR_ALLOW_LIST:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default allowListReducer;
