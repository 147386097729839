import styled from 'styled-components/macro';
import { Typography } from '@redislabsdev/redis-ui-components';

export const SignInFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 7.2rem 0;
`;

export const SignInTitle = styled(Typography.Heading)`
  color: ${({ theme }) => theme.semantic.color.text.neutral800};
`;

export const SignupContainer = styled(Typography.Body)`
  white-space: pre-wrap;
  text-align: center;
  & a {
    text-decoration: underline;
  }
`;
