import { useState, useEffect } from 'react';

const useWindowDimensions = () => {
  const getWindowDimensions = () => {
    const { innerWidth: screenWidth, innerHeight: screenHeight } = window;

    return { screenWidth, screenHeight };
  };

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const handleResize = () => setWindowDimensions(getWindowDimensions());

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
