import {
  ClusterRolesTypesEnum,
  CreateClusterRoleRequestAction,
  CreateClusterRoleSuccessAction,
  CreateUpdateClusterRolePayload,
  UpdateClusterRoleRequestAction,
  UpdateClusterRoleSuccessAction
} from './clusterRoles.types';

export const createClusterRole = (
  newClusterRole: CreateUpdateClusterRolePayload['clusterRole'],
  handleResponse: CreateUpdateClusterRolePayload['handleResponse']
): CreateClusterRoleRequestAction => ({
  type: ClusterRolesTypesEnum.CREATE_CLUSTER_ROLE_REQUEST,
  payload: { clusterRole: newClusterRole, handleResponse }
});

export const createClusterRoleSuccess = (data: ClusterRole): CreateClusterRoleSuccessAction => ({
  type: ClusterRolesTypesEnum.CREATE_CLUSTER_ROLE_SUCCESS,
  payload: data
});

export const updateClusterRole = (
  updatedClusterRole: CreateUpdateClusterRolePayload['clusterRole'],
  handleResponse: CreateUpdateClusterRolePayload['handleResponse']
): UpdateClusterRoleRequestAction => ({
  type: ClusterRolesTypesEnum.UPDATE_CLUSTER_ROLE_REQUEST,
  payload: { clusterRole: updatedClusterRole, handleResponse }
});

export const updateClusterRoleSuccess = (data: ClusterRole): UpdateClusterRoleSuccessAction => ({
  type: ClusterRolesTypesEnum.UPDATE_CLUSTER_ROLE_SUCCESS,
  payload: data
});
