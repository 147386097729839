import { ApiPaths } from 'services/api/apiPaths';
import { SignUpFormValues } from '../components/SignUpForm/SignUpForm.types';
import RedisApiAxiosInstance from '../../../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { okNoContentStatus } from '../../../../../utils/constants/api/statuses';

export const DEFAULT_NEW_USER_OBJECT = {
  company: '',
  country_code: 'ro',
  country: 'Romania',
  state_code: '',
  state: '',
  promotional_emails: false,
  phone: '',
  product_type: 'unifiedrc',
  marketo_params: [],
  utm_content: '',
  utm_source: '',
  utm_campaign: '',
  utm_medium: '',
  utm_term: '',
  lead_ip: ''
};

const registerUserRequest = async (
  newUserObj: SignUpFormValues,
  successCallback: () => void,
  alreadyExistingCallback: (e) => void
) => {
  try {
    const { status } = await RedisApiAxiosInstance.post(`${ApiPaths.USERS}/registration`, {
      ...DEFAULT_NEW_USER_OBJECT,
      ...newUserObj,
      time_stamp: new Date().toISOString()
    });
    if (status === okNoContentStatus) {
      successCallback();
    }
  } catch (e) {
    alreadyExistingCallback(e);
  }
};

export default registerUserRequest;
