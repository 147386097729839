import { FC, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as S from './SignUpForm.style';
import { ThanksForSignUp } from '../../../../../../components';
import useBuildValidationSchema from '../../hooks/useBuildValidationSchema';
import registerUserRequest from '../../utils/registerUser';
import { extractErrorAndShowToast } from '../../../../../../utils/helpers/extractErrorAndShowToast';
import SignUpForm from './SignUpForm';
import { INITIAL_VALUES } from './SignUpForm.constants';
import { SignUpFormValues } from './SignUpForm.types';
import { i18translation } from '../../../../../../locale/translations/i18NsPaths';
import { routes } from '../../../../../../utils/constants/routes';

const SignUpFormWrapper: FC = () => {
  const { t } = useTranslation();
  const validationSchema = useBuildValidationSchema(t);
  const [signedUp, setSignedUp] = useState<boolean>(false);
  const keyPrefix = i18translation.LoginScreen.signUpScreen;
  const handleSubmit = async (
    formValues: SignUpFormValues,
    formikHelpers: FormikHelpers<SignUpFormValues>
  ) => {
    const successCb = () => {
      formikHelpers.resetForm();
      setSignedUp(true);
    };
    const errorCb = (e) => {
      extractErrorAndShowToast(e);
    };

    await registerUserRequest(formValues, successCb, errorCb);
  };

  if (signedUp) {
    return <ThanksForSignUp />;
  }

  return (
    <>
      <S.FormSide>
        <S.FormWrapper>
          <S.FormTitle paddingBottom="3rem">{t(keyPrefix.signUp)}</S.FormTitle>
          <Formik
            initialValues={INITIAL_VALUES}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            component={SignUpForm}
            validateOnChange
          />
        </S.FormWrapper>
        <S.BottomTextWithLink>
          {t(keyPrefix.alreadyHaveAccount)}
          <Link data-testid="link--sign-in" to={routes.root}>
            {t(keyPrefix.signIn)}
          </Link>
        </S.BottomTextWithLink>
      </S.FormSide>
    </>
  );
};

export default SignUpFormWrapper;
