import styled from 'styled-components/macro';
import { Button, Typography } from '@redislabsdev/redis-ui-components';

export const UserFormInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14rem;
  align-items: flex-start;
  justify-content: flex-start;
  width: 40rem;
`;

export const UserFormInfoTitle = styled(Typography.Heading)`
  color: ${({ theme }) => theme.semantic.color.text.neutral800};
  white-space: pre-wrap;
`;
export const UserFormInfoSubTitle = styled(Typography.Body)`
  color: ${({ theme }) => theme.semantic.color.text.neutral800};
  font-size: 1.8rem;
  margin-bottom: 3rem;
  margin-top: 0.8rem;
`;

export const UserFormInfoText = styled(Typography.Body)`
  color: ${({ theme }) => theme.semantic.color.text.neutral700};
  margin-top: 1.6rem;
  white-space: pre-wrap;
`;
export const ButtonContainer = styled(Button)`
  align-self: flex-end;
  margin-top: 5rem;
`;
