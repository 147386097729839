import { takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
// api calls
import * as calls from './paymentMethods';
// actions
import * as actions from './paymentMethods.action';
import * as subsAndDBsActions from '../subscriptions/subscriptions.actions';
import {
  PaymentMethodsTypesEnum,
  IPaymentInfo,
  DeletePaymentMethodAction,
  AddSubsToPaymentMethodAction
} from './paymentMethods.types';
import { extractErrorAndShowToast } from '../../utils/helpers/extractErrorAndShowToast';

function* getPaymentsInfo() {
  try {
    const { data }: AxiosResponse<{ 'payment-infos': IPaymentInfo[] }> = yield call(
      calls.getPaymentsInfo
    );

    yield put(actions.getPaymentsInfoSuccess(data['payment-infos']));
  } catch (e) {
    if (e.response) {
      const { data } = e.response;
      yield put(actions.getPaymentsInfoFailure(data));
      extractErrorAndShowToast(e);
    }
  }
}

function* deletePaymentMethod(action: DeletePaymentMethodAction) {
  try {
    yield call(calls.deletePaymentInfo, action.payload);
    yield put(actions.deletePaymentMethodSuccess(action.payload));
  } catch (e) {
    if (e.response) {
      const { data } = e.response;
      yield put(actions.deletePaymentMethodFailure(data));
      extractErrorAndShowToast(e);
    }
  }
}

function* addSubsToPaymentMethod({ payload }: AddSubsToPaymentMethodAction) {
  try {
    yield call(calls.addSubsToPaymentInfo, payload);
    yield put(actions.addSubsToPaymentInfoSuccess(payload));
    yield put(subsAndDBsActions.getSubscriptions());

    if (payload.onSuccess) {
      payload.onSuccess();
    }
  } catch (e) {
    if (e.response) {
      const { data } = e.response;
      yield put(actions.addSubsToPaymentInfoFailure(data));
      extractErrorAndShowToast(e);

      if (payload.onError) {
        payload.onError();
      }
    }
  }
}

function* paymentMethodsSaga() {
  yield takeLatest(PaymentMethodsTypesEnum.GET_PAYMENTS_INFO_REQUEST, getPaymentsInfo);
  yield takeLatest(PaymentMethodsTypesEnum.DELETE_PAYMENT_METHOD_REQUEST, deletePaymentMethod);
  yield takeLatest(
    PaymentMethodsTypesEnum.ADD_SUBS_TO_PAYMENT_METHOD_REQUEST,
    addSubsToPaymentMethod
  );
}

export default paymentMethodsSaga;
