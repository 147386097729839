import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';
import * as Sentry from '@sentry/react';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import reducerHandler from './reducer.root';
import { rbacMiddleware } from './middleware/rbacMiddleware';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: () => {
    return null;
  },
  actionTransformer: (action) => {
    return {
      ...action,
      payload: null
    };
  }
});

export const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  reducerHandler,
  composeWithDevTools(applyMiddleware(rbacMiddleware, sagaMiddleware), sentryReduxEnhancer)
);

export default store;
