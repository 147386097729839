import { AxiosResponse } from 'axios';
import { omit } from 'lodash';
import { ApiPaths } from 'services/api/apiPaths';
import RedisApiAxiosInstance from '../../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import {
  JoinConfigInterface,
  SamlAccount,
  SamlConfig,
  SamlConfigDomain,
  SamlConfigDomainCreateReqInterface,
  SamlConfigDomainReqInterface,
  SamlCreateReqInterface,
  SamlFormInterface,
  SamlUpdateReqInterface,
  TestConnectionInterface
} from '../SingleSignOn.types';

export const getSamlExistingConfig = async (samlConfigId) => {
  const { data } = await RedisApiAxiosInstance.get<SamlConfig>(`${ApiPaths.SAML}/${samlConfigId}`);

  return data;
};

export const updateSamlExistingConfigReq = async (
  params: Omit<SamlFormInterface, 'errors' | 'status' | 'token' | 'allAccountsCount'>
) => {
  const data: SamlUpdateReqInterface = {
    ...omit(params, ['failureReason', 'failureReasonExtended', 'oktaIdentityProvider', 'accounts']),
    accounts: params.accounts.map((id) => ({
      id: Number(id)
    }))
  };

  return RedisApiAxiosInstance.put<SamlUpdateReqInterface, AxiosResponse<SamlConfig>>(
    `${ApiPaths.SAML}/${params.id}`,
    data
  );
};

export const createSamlConfigReq = async (params: SamlCreateReqInterface) => {
  return RedisApiAxiosInstance.post<SamlCreateReqInterface, AxiosResponse<SamlConfig>>(
    ApiPaths.SAML,
    params
  );
};

export const initializeSamlConfigReq = async () => {
  const { data } = await RedisApiAxiosInstance.post<SamlConfig>(ApiPaths.SAML);

  return data;
};

export const getSamlAvailableAccounts = async (samlId: string) => {
  const { data } = await RedisApiAxiosInstance.get<{ saml_accounts: SamlAccount[] }>(
    `${ApiPaths.SAML}/${samlId}/accounts`
  );

  return data;
};

export const getSamlLogoutUrl = async () => {
  const { data } = await RedisApiAxiosInstance.get(`${ApiPaths.SAML}/logout_url`);

  return data;
};

export const deactivateSamlConfigReq = async (samlId, accountId) => {
  return RedisApiAxiosInstance.put(`${ApiPaths.SAML}/${samlId}/deactivate/${accountId}`);
};

export const sendSamlTokenReq = async (params: JoinConfigInterface) => {
  return RedisApiAxiosInstance.put(`${ApiPaths.SAML}/join`, params);
};

export const testConnectionStart = async (samlId: string) => {
  const { data } = await RedisApiAxiosInstance.get<TestConnectionInterface>(
    `${ApiPaths.SAML}/${samlId}/test_conn_start`
  );

  return data;
};

export const samlTestConnectionEnd = async (samlId, params: TestConnectionInterface) => {
  const { data } = await RedisApiAxiosInstance.put(
    `${ApiPaths.SAML}/${samlId}/test_conn_end`,
    params
  );

  return data;
};

export const addDomainReq = async ({
  samlId,
  domain
}: SamlConfigDomainCreateReqInterface): Promise<SamlConfigDomain> => {
  const { data } = await RedisApiAxiosInstance.post(`${ApiPaths.SAML}/${samlId}/domain`, {
    domain
  });

  return data;
};

export const deleteDomainReq = async ({ samlId, id }: SamlConfigDomainReqInterface) => {
  const { data } = await RedisApiAxiosInstance.delete(`${ApiPaths.SAML}/${samlId}/domain/${id}`);

  return data;
};

export const verifyDomainReq = async ({
  samlId,
  id
}: SamlConfigDomainReqInterface): Promise<SamlConfigDomain> => {
  const { data } = await RedisApiAxiosInstance.post(
    `${ApiPaths.SAML}/${samlId}/domain/${id}/verify`
  );

  return data;
};

export const handleDownload = (accountId: number = 0) => {
  const downloadMetaData = async (url: string) => {
    const { data, headers = {} } = await RedisApiAxiosInstance.get<Blob>(url, {
      responseType: 'blob'
    });

    const filename = (headers['content-dispositions'] ?? '')
      .replace(/\s|\t|\n/gi, '')
      .split('filename=')[1];

    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(data);
    link.download = filename ?? `rediscloud-${accountId}-SP-metadata.xml`;
    link.click();
  };

  // noinspection JSIgnoredPromiseFromCall
  downloadMetaData(`${ApiPaths.SAML}/metadata`);
};
