import { AxiosResponse } from 'axios';
import { call, delay, put, take, select } from 'redux-saga/effects';
import { i18translation } from 'locale/translations/i18NsPaths';
import i18n from 'locale/i18n';
import { bdbsApi } from 'services/api/resources/bdbs/bdbs.resource';
import * as actions from './databases.actions';
import { planCallsInterval } from '../../utils/constants/api/intervals';
import { rcpErrorStatuses } from '../createSubscription/pro/pro.constants';
import {
  DatabasesActionTypes,
  UserUpdateSubsPriceConfirmationAction,
  RcpUpdatePayload,
  NewPriceData
} from './databases.types';
import { setNewDbRcpPlan } from './databases.actions';
import { setOldPricingPriceDetails } from '../../components/oldPricing/oldPricing.action';
import getUpdatedRcpPriceDetails from '../../components/oldPricing/utils/getUpdatedRcpPriceDetails';
import {
  newDbRcpPlanSelector,
  databasesBySubIdSelector,
  newPriceDataSelector
} from './databases.selectors';
import { getPriceData } from './rcpUpdateFlow.utils';
import { isAgentTypeSupportOrSuper } from '../auth/auth.selectors';
import { rcpsApi } from '../../services/api/resources/rcps/rcps.resource';
import { shardTypePricingsApi } from '../../services/api/resources/shardTypePricings/shardTypePricings.resource';

const keyPrefix = i18translation.createSubscription.pro.serverErrors;

export function* rcpUpdateFlow(payload: RcpUpdatePayload) {
  const { updatedRcp, isCreateMode, setSubmitting, selectedDatabase } = payload;
  const newPriceData: NewPriceData = yield getNewPriceDataSaga(payload);

  if (newPriceData.isPriceHigher) {
    const isInternal = updatedRcp.cloud_account.ownership === 'internal';
    const isSuperOrSupportAgent = yield select(isAgentTypeSupportOrSuper);

    const shouldPlan = !isInternal || isSuperOrSupportAgent;

    if (shouldPlan) {
      yield updateRcpAndWaitForStatus(updatedRcp);
    }

    yield put(actions.setNewPriceData(newPriceData));

    const { payload: didConfirmed }: UserUpdateSubsPriceConfirmationAction =
      yield waitForUserConfirmation(setSubmitting);

    if (didConfirmed) {
      setSubmitting(true);
      if (!shouldPlan) {
        yield updateRcpAndWaitForStatus(updatedRcp);
      }
    } else {
      return { shouldUpdate: false, didConfirmClustering: false };
    }
  } else {
    yield updateRcpAndWaitForStatus(updatedRcp);
  }

  const didConfirmClustering: boolean = yield checkIfClusteringRequiredAndConfirm(
    selectedDatabase,
    isCreateMode,
    setSubmitting
  );

  const updateBdb = updatedRcp.databases_to_update.find(
    ({ bdb_id }) => bdb_id === selectedDatabase.id
  );

  const scalingFactorUpdated =
    updateBdb?.scaling_factor !== selectedDatabase?.search_scaling_factor;

  yield checkIfOptimizationRequiredAndConfirm(isCreateMode, setSubmitting, scalingFactorUpdated);

  yield validateProvisionAndWaitForStatus(updatedRcp.id);

  return { shouldUpdate: true, didConfirmClustering };
}

function* getNewPriceDataSaga(payload: RcpUpdatePayload) {
  const newDbShardPricingList: ShardTypePricingRegion[] = yield call(
    getShardTypePricingsFromCalculator,
    payload.shardTypePricingPostBody,
    payload.selectedSubscription.id
  );

  const subscriptionBdbs: Bdb[] = yield select(
    databasesBySubIdSelector(payload.selectedSubscription.id)
  );
  let bdbsShardTypePricingRegion = {};

  if (payload.enableGetBdbsOptimizedQuery) {
    bdbsShardTypePricingRegion = yield call(getBdbsPricing, payload.selectedSubscription.id);
  }

  return getPriceData({
    ...payload,
    newDbShardPricingList,
    subscriptionBdbs,
    bdbsShardTypePricingRegion
  });
}

export function* oldPricingRcpUpdateFlow(payload: RcpUpdatePayload) {
  const { setSubmitting, updatedRcp, selectedSubscription, selectedDatabase, isCreateMode } =
    payload;

  const planStateResponse: RcpPlanStatusResponse = yield updateRcpAndWaitForStatus(updatedRcp);

  const updatedRcpPriceDetails = getUpdatedRcpPriceDetails(
    planStateResponse,
    selectedSubscription.rcp
  );

  if (
    updatedRcpPriceDetails.totalAdditionalPrice > 0 ||
    (selectedSubscription.rcp.rcp_type === 'reserved' &&
      updatedRcpPriceDetails.additionalNodes?.length)
  ) {
    yield put(setOldPricingPriceDetails(updatedRcpPriceDetails));

    const { payload: didConfirmed }: UserUpdateSubsPriceConfirmationAction =
      yield waitForUserConfirmation(setSubmitting);

    if (!didConfirmed) {
      return { shouldUpdate: false, didConfirmClustering: false };
    }
  }

  const didConfirmClustering: boolean = yield checkIfClusteringRequiredAndConfirm(
    selectedDatabase,
    isCreateMode,
    setSubmitting
  );

  if (!didConfirmClustering) {
    setSubmitting(true);
  }

  yield validateProvisionAndWaitForStatus(updatedRcp.id);

  return { shouldUpdate: true, didConfirmClustering };
}

function* waitForUserConfirmation(setSubmitting: (isSubmitting: boolean) => void) {
  setSubmitting(false);

  return yield take(DatabasesActionTypes.USER_UPDATE_SUBS_PRICE_CONFIRMATION);
}

function* getShardTypePricingsFromCalculator(
  shardTypePricingPostBody: CalculatorPayload,
  subscriptionId: number
) {
  const {
    data: { shardTypePricings }
  }: AxiosResponse<ShardTypePricingListResponse> = yield call(
    shardTypePricingsApi.calculateSubscriptionPrice,
    shardTypePricingPostBody,
    subscriptionId
  );

  return shardTypePricings[0].regions;
}

function* getBdbsPricing(subscription: number) {
  const {
    data: { bdbsShardTypePricingBdbRegion }
  }: AxiosResponse<{ bdbsShardTypePricingBdbRegion: BdbsShardTypePricingBdbRegion }> = yield call(
    bdbsApi.getPricing,
    { subscription }
  );

  return bdbsShardTypePricingBdbRegion;
}

function* updateRcpAndWaitForStatus(updatedRcp: SubsRcp) {
  yield call(rcpsApi.update, updatedRcp);
  yield delay(planCallsInterval);
  while (true) {
    const { data: rcpPlanStatus }: AxiosResponse<RcpPlanStatusResponse> = yield call(
      rcpsApi.planStatus,
      updatedRcp.id
    );

    if (rcpPlanStatus.status === 'update_done') {
      yield put(setNewDbRcpPlan(rcpPlanStatus));

      return rcpPlanStatus;
    }

    if (rcpErrorStatuses.some((status) => status === rcpPlanStatus.status)) {
      throw new Error(i18n.t(keyPrefix.rcpPlanGeneralError));
    }

    yield delay(planCallsInterval);
  }
}

function* validateProvisionAndWaitForStatus(rcpId: number) {
  yield call(rcpsApi.validateProvision, rcpId);

  yield delay(planCallsInterval);

  while (true) {
    const { data: rcpValidateProvisionResponse }: AxiosResponse<RcpValidateProvisionResponse> =
      yield call(rcpsApi.validateProvisionStatus, rcpId);

    if (rcpValidateProvisionResponse.status === 'validate_provision_done') {
      return;
    }

    if (rcpValidateProvisionResponse.status === 'validate_provision_error') {
      throw new Error(i18n.t(keyPrefix.rcpValidateProvisionError));
    }

    yield delay(planCallsInterval);
  }
}

function* checkIfClusteringRequiredAndConfirm(
  selectedDatabase: Bdb,
  isCreateMode: boolean,
  setSubmitting: (isSubmitting: boolean) => void
) {
  const isClusteringEnabled = selectedDatabase?.shards_count > 1;
  if (!isClusteringEnabled && !isCreateMode) {
    const planStatusResponse: RcpPlanStatusResponse = yield select(newDbRcpPlanSelector);
    const rcpDb = planStatusResponse.databases.find((db) => db.bdb_id === selectedDatabase.id);

    if (rcpDb && rcpDb.shards_count / (rcpDb.replication ? 2 : 1) > 1) {
      setSubmitting(false);
      yield put(actions.setShouldShowClusteringDialog());
      yield take(DatabasesActionTypes.USER_CLUSTERING_CONFIRMATION);
      setSubmitting(true);

      return true;
    }
  }

  return false;
}

function* checkIfOptimizationRequiredAndConfirm(
  isCreateMode: boolean,
  setSubmitting: (isSubmitting: boolean) => void,
  scalingFactorUpdated: boolean
) {
  const newPriceData: NewPriceData = yield select(newPriceDataSelector);
  const planStatusResponse: RcpPlanStatusResponse = yield select(newDbRcpPlanSelector);

  if (
    (planStatusResponse?.optimization && !isCreateMode && !newPriceData?.isPriceHigher) ||
    scalingFactorUpdated
  ) {
    setSubmitting(false);
    yield put(actions.setShouldShowBdbOptimizationDialog());
    yield take(DatabasesActionTypes.USER_BDB_OPTIMIZATION_CONFIRMATION);
    setSubmitting(true);
  }
}
