import { PermissionKey } from 'components/PermissionsProtectors/protectedComponents.types';
import { isSubFlexible, isSubAa } from './subType';

export const getCreateDbBtnPermissions = (subscription: Subscription) => {
  const permissions: PermissionKey = ['bdb_create', 'subscription_get_cluster'];
  if (isSubFlexible(subscription)) {
    permissions.push('rcp_update', 'rcp_provision');
  }
  if (isSubAa(subscription)) {
    permissions.push('change_request_create', 'change_request_get', 'create_change_execute');
  }

  return permissions;
};
