import { parametersQueries } from 'queryClient/parameters/parameters.queries';

const useServiceHealthCheck = (onServiceHealthOk: Function) => {
  const paramsQuery = parametersQueries.useSystemParameters({
    retry: false,
    refetchOnMount: true,
    refetchInterval: (_, query) => {
      return query.state.error ? 5000 : false;
    }
  });

  if (paramsQuery.isSuccess && paramsQuery.isFetched) {
    onServiceHealthOk();
  }
};

export default useServiceHealthCheck;
