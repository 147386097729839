import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { routes } from 'utils/constants/routes';
import { logout } from '../store/auth/auth.action';

const useLogOut = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useDispatch();
  const { isAuthenticated: isOktaAuthenticated } = authState || {};

  const performLogout = async (url = '') => {
    const localOktaTokens = await oktaAuth.tokenManager.getTokens();
    const localTokensLength = Object.keys(localOktaTokens).length;

    oktaAuth?.authStateManager.unsubscribe();
    if (isOktaAuthenticated || localTokensLength) {
      await oktaAuth?.signOut({
        clearTokensBeforeRedirect: true
      });
      if (url !== '') {
        window.sessionStorage.setItem('oktaLogOutRedirect', encodeURIComponent(url));
      }
    } else {
      dispatch(
        logout({
          redirect: url !== '' ? url : routes.login
        })
      );
    }
  };

  return { performLogout, isOktaAuthenticated };
};

export default useLogOut;
