import useScript from 'hooks/useScript';

interface Props {
  script: string;
}

export const BotProtectionScript = ({ script }: Props) => {
  useScript(script);

  return null;
};
