import emailPatternBg from '../../../assets/icons/EmailPatternBg.svg';
import RedisLogo from '../../../components/LoginRelatedComponents/RedisLogo';
import * as S from './RedisBackground.style';

const RedisBackground: React.FC = ({ children }) => {
  return (
    <S.SocialLoginContainer>
      <img src={emailPatternBg} alt="background with starts" />
      <RedisLogo />
      {children}
    </S.SocialLoginContainer>
  );
};

export default RedisBackground;
