/* eslint-disable @typescript-eslint/naming-convention */
import { OverviewData } from '../../../Overview.types';
import convertHourToMonthPrice from '../../../../../../../../../utils/convertHourToMonthPrice';
import getSubscriptionHourlyPrice from '../../../../../../../../../utils/cloudPricings/getSubscriptionHourlyPrice';

const getSubscriptionPrice = ({
  isEssential,
  subscription,
  plan,
  isCloudAccountExternal
}: Partial<OverviewData>): number => {
  const { minimal_pricing_regions, shard_type_pricing_regions } = subscription;
  let price = 0;
  if (!isEssential) {
    if (shard_type_pricing_regions.length) {
      price = convertHourToMonthPrice(
        getSubscriptionHourlyPrice({
          minimalPricing: minimal_pricing_regions,
          shardTypePricings: shard_type_pricing_regions,
          plan
        })
      );
    } else if (!isCloudAccountExternal) {
      const minimalHourlyPrice = minimal_pricing_regions.reduce(
        (acc, pricingRegion) => acc + pricingRegion.price,
        0
      );
      price = convertHourToMonthPrice(minimalHourlyPrice);
    }
  } else {
    const priceEssential = +plan?.price?.toFixed(2);
    price = priceEssential || price;
  }

  return price;
};

export default getSubscriptionPrice;
