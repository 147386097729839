import { CloudProviders } from '../../components/CloudProvider/CloudProvider.types';

export interface FindRegionMinimalPriceProps {
  minimalPricingList: SubsMinimalPricing[];
  provider: CloudProviders;
  rof: boolean;
  regionName: string;
}

const findRegionPriceItem = ({
  minimalPricingList,
  provider,
  rof,
  regionName
}: FindRegionMinimalPriceProps) => {
  return minimalPricingList.find((minimalPriceItem) => {
    return (
      minimalPriceItem.cloud_name.toLocaleLowerCase() === provider &&
      minimalPriceItem.region_name === regionName &&
      minimalPriceItem.rof === rof
    );
  });
};

export default findRegionPriceItem;
