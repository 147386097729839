import { Config } from 'config';
import { routes } from 'utils/constants/routes';

const RiCloud = () => {
  const riCloudUrl = `${Config.REDIS_INSIGHT_CLOUD_BASE_URL}`;

  // out of this url, https://{host}/#/ri-cloud/{bdbId}/browser, extract /{bdbId}/browser
  const path = window.location.hash.slice(`#${routes.riCloud}`.length);

  window.location.href = `${riCloudUrl}${path}`;

  return null;
};

export default RiCloud;
