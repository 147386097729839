import styled from 'styled-components/macro';
import { Typography } from '@redislabsdev/redis-ui-components';

export const OrText = styled(Typography.Body)`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  word-break: normal;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.semantic.color.text.neutral800};
  margin-bottom: 3.2rem;
  margin-top: 4rem;

  & hr {
    background-color: ${({ theme }) => theme.semantic.color.background.neutral400};
    width: 100%;
    height: 1px;
    margin-right: 1rem;
    margin-left: 1rem;
  }
`;
