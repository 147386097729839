import { Link, Typography } from '@redislabsdev/redis-ui-components';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { useHistory } from 'react-router-dom';
import * as S from './Maintenance.style';
import useServiceHealthCheck from './hooks/useServiceHealthCheck';

const STATUS_LINK = 'https://status.redis.io';

const Maintenance = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const keyPrefix = i18translation.maintenance;

  useServiceHealthCheck(() => {
    history.push('/');
  });

  return (
    <S.Container>
      <S.RedisLogo />
      <S.ContentContainer>
        <Typography.Heading>{t(keyPrefix.title)}</Typography.Heading>
        <Typography.Body>{t(keyPrefix.body)}</Typography.Body>
        <S.MaintenanceIcon />
        <Typography.Body>
          {t(keyPrefix.supportiveText1)}
          <Link href={STATUS_LINK}>{STATUS_LINK}</Link>
          {t(keyPrefix.supportiveText2)}
        </Typography.Body>
      </S.ContentContainer>
    </S.Container>
  );
};

export default Maintenance;
