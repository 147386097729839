export const isSubAa = (sub: Subscription) => Boolean(sub?.aa_rcp);
export const isSubFlexible = (sub: Subscription) => Boolean(sub?.rcp);
export const isSubReserved = (sub: Subscription) =>
  isSubFlexible(sub) && sub.rcp.rcp_type === 'reserved';
export const isSubExternal = (sub: Subscription) =>
  isSubFlexible(sub) && sub.rcp.cloud_account?.ownership === 'external';
export const isSubInternal = (sub: Subscription) =>
  isSubFlexible(sub) && sub.rcp.cloud_account?.ownership === 'internal';
export const isSubFixed = (sub: Subscription) => !isSubAa(sub) && !isSubFlexible(sub);

export const getSubType = (sub: Subscription) => {
  return {
    isFlexible: isSubFlexible(sub),
    isAA: isSubAa(sub),
    isReserved: isSubReserved(sub),
    isFixed: isSubFixed(sub),
    isExternal: isSubExternal(sub),
    isInternal: isSubInternal(sub)
  };
};
