import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { i18translation } from '../../../locale/translations/i18NsPaths';
import { showToast } from '../../../components/Toast/showToast';
import useAccount from '../../../hooks/useAccount';
import { getQueryParam } from '../../../utils/queryParam/getQueryParam';
import { deleteQueryParam } from '../../../utils/queryParam/deleteQueryParam';

const isMapped = (loggedInAccountId: number, accountsList: string) => {
  if (!loggedInAccountId) {
    return false;
  }
  if (!accountsList) {
    return false;
  }

  return accountsList.split(', ').includes(loggedInAccountId?.toString());
};

const useAwsMarketPlaceQueryParamsMessage = () => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.awsMarketPlace.messages;
  const { account } = useAccount();
  const infoParam = getQueryParam('info');
  const accountParam = getQueryParam('account');
  const accountsParam = getQueryParam('accounts');

  useEffect(() => {
    if (!infoParam || !account) {
      return;
    }
    const loggedInAccountId = account?.id;
    if (infoParam === 'marketplace-account-bound-correctly' && (accountParam || accountsParam)) {
      if (accountParam && !isMapped(loggedInAccountId, accountParam)) {
        showToast(
          t(keyPrefix.accountBoundCorrectlyDifferentLoggedIn, {
            mappedAccount: accountParam,
            loggedInAccount: loggedInAccountId
          }),
          'success'
        );
      } else if (accountsParam && !isMapped(loggedInAccountId, accountsParam)) {
        showToast(
          t(keyPrefix.accountsBoundCorrectlyDifferentLoggedIn, {
            mappedAccounts: accountsParam,
            loggedInAccount: loggedInAccountId
          }),
          'success'
        );
      } else {
        // account is logged in to one of accountParam or accountParams
        showToast(
          t(keyPrefix.accountBoundCorrectly, {
            account: loggedInAccountId
          }),
          'success'
        );
      }
      deleteQueryParam('info');
    }
  }, [account]);
};

export default useAwsMarketPlaceQueryParamsMessage;
