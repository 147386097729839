import { createGlobalStyle } from 'styled-components/macro';
import { smallScreenQuery, remSizes, mediumScreenQuery, largeScreenQuery } from './constants.style';

const calcRemInPercentage = (remSize: number) => `${((remSize * 10) / 16) * 100}%`;

export const ResponsiveRem = createGlobalStyle`
    :root {
        @media only ${smallScreenQuery} {
            font-size: ${calcRemInPercentage(remSizes.small)};
        }
        @media only ${mediumScreenQuery} {
            font-size: ${calcRemInPercentage(remSizes.medium)};
        }
        @media only ${largeScreenQuery} {
            font-size: ${calcRemInPercentage(remSizes.large)};
        }
    }
`;

export const rem = (num: number) => num / 10;
