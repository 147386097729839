import { LDContext, useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useState, createContext } from 'react';
import { useSelector } from 'react-redux';
import { accountIdSelector, isLoggedInSelector } from 'store/auth/auth.selectors';
import { usersQueries } from 'queryClient/users/users.queries';
import { useNewCustomerLoginStore } from 'screens/NewCustomer/store/useNewCustomerLoginStore';
import { Config } from '../../config';
import { FeatureFlagsType } from './featureFlags.types';
import useAccount from '../../hooks/useAccount';

const identifiableEnvs = ['app', 'app-staging.qa'];

export const FeatureFlagsContext = createContext<{
  isLoading: boolean;
  flags: FeatureFlagsType;
}>({ isLoading: true, flags: {} as FeatureFlagsType });

export const FeatureFlagsContextProvider = ({ children }) => {
  const flags = useFlags<FeatureFlagsType>();
  const client = useLDClient();
  const accountId = useSelector(accountIdSelector);
  const { account } = useAccount();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { data: userData } = usersQueries.useCurrentUser({ enabled: isLoggedIn });
  const [isLoading, setIsLoading] = useState(true);
  const newCustomerLoginStore = useNewCustomerLoginStore();

  useEffect(() => {
    if (!client) {
      setIsLoading(false);

      return;
    }

    if (!userData) {
      return;
    }

    const userContext: LDContext = {
      key: identifiableEnvs.includes(Config.API_ENV) ? String(userData.user_id) : Config.API_ENV,
      email: userData.email,
      custom: {
        accountId: Number(accountId),
        isFirstLogin: newCustomerLoginStore.isFirstLogin,
        is_sso_gcp: !!account?.is_sso_gcp
      },
      anonymous: false
    };

    client.identify(userContext, null, () => {
      setIsLoading(false);
    });
  }, [client, accountId, userData, account?.is_sso_gcp]);

  return (
    <FeatureFlagsContext.Provider value={{ isLoading, flags }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
