import { omit } from 'lodash';
import { AccessPermissions, PermissionsDict } from '../../../store/auth/auth.types';
import {
  GCP_USER_BLOCKED_PERMISSIONS,
  PAAS_USER_BLOCKED_PERMISSIONS,
  POC_USER_BLOCKED_PERMISSIONS,
  SAML_USER_BLOCKED_PERMISSIONS
} from './permissions.constants';

const filterPermissions = (ssoPermissions: AccessPermissions[]) => (permissions: PermissionsDict) =>
  omit(permissions, ...ssoPermissions) as PermissionsDict;

export const filterPaasPermissions = filterPermissions(PAAS_USER_BLOCKED_PERMISSIONS);
export const filterGcpPermissions = filterPermissions(GCP_USER_BLOCKED_PERMISSIONS);
export const filterPocPermissions = filterPermissions(POC_USER_BLOCKED_PERMISSIONS);
export const filterSamlPermissions = filterPermissions(SAML_USER_BLOCKED_PERMISSIONS);
