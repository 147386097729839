import { matchPath } from 'react-router-dom';
import { routes } from './constants/routes';
import history from '../hashHistory';

export const isOnSubsOrDatabasesRoute = (pathName?: string) => {
  const pathname = pathName || history.location.pathname;

  const isDatabasesRoute = !!matchPath(pathname, {
    path: routes.databases.root,
    exact: false
  });

  const isCrdbOnDatabasesRoute = !!matchPath(pathname, {
    path: routes.databases.viewCrdb.root,
    exact: false
  });

  const isBdbOnDatabasesRoute = !!matchPath(pathname, {
    path: routes.databases.viewBdb.root,
    exact: false
  });

  const isSubRoute = !!matchPath(pathname, {
    path: routes.subscriptions.root,
    exact: false
  });

  return {
    isDatabasesRoute,
    isSubRoute,
    isOnDbsOrSubs: isDatabasesRoute || isSubRoute,
    isCrdbOnDatabasesRoute,
    isBdbOnDatabasesRoute
  };
};
