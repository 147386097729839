import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../../utils/constants/routes';
import { activateInvitationCallbackRequest } from '../../utils/resetPassword';
import UserFormInfo from '../UserFormInfo/UserFormInfo';
import { i18translation } from '../../../../locale/translations/i18NsPaths';
import { useLoginContext } from '../../Login.context';

const ActivateInvitationCallback = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [showPasswordExpired, setShowPasswordExpired] = useState(false);
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen.resetPasswordScreen;
  const showLoaderContext = useLoginContext();
  const successCallback = ({ key, accId }: { key: string; accId: string }) => {
    showLoaderContext.setShowLoader(false);
    if (!key && !accId) {
      history.push(routes.login);
    } else {
      history.push(`${routes.resetPassword}?invitation=true&key=${key}&id=${accId}`);
    }
  };
  const errorCallback = () => {
    showLoaderContext.setShowLoader(false);
    setShowPasswordExpired(true);
  };

  useEffect(() => {
    showLoaderContext.setShowLoader(true);
    activateInvitationCallbackRequest({ search, successCallback, errorCallback });
  }, []); // eslint-disable-line

  if (showPasswordExpired) {
    return <UserFormInfo title={t(keyPrefix.passResetLinkExpired)} />;
  }

  return null;
};

export default ActivateInvitationCallback;
