import { FC, ReactNode, useCallback, useMemo } from 'react';
import {
  FormField,
  Select,
  SelectValueRender,
  Typography
} from '@redislabsdev/redis-ui-components';
import * as S from './InputFields.style';

interface IProps {
  countries: Country[];
  onCountrySelect: (selectedCountry: Country) => void;
  selectedCountryCode: string;
  label: string;
  width: string;
  error?: string;
  disabled?: boolean;
  info?: ReactNode;
}

const valueRender: SelectValueRender = ({ option }) => (
  <>
    <S.CountryFlag countryCode={option.value} />
    <Typography.Body ellipsis tooltipOnEllipsis>
      {option.label}
    </Typography.Body>
  </>
);

const CountrySelectInput: FC<IProps> = ({
  label,
  width,
  countries,
  selectedCountryCode,
  onCountrySelect,
  error,
  disabled,
  info = null
}) => {
  const options = useMemo(
    () =>
      countries.map((country) => ({
        value: country.code,
        label: country.name,
        disabled: country.disabled
      })),
    [countries]
  );

  const handleChange = useCallback(
    (value) => onCountrySelect(countries.find(({ code }) => code === value)),
    [countries, onCountrySelect]
  );

  return (
    <S.FormFieldCompose $width={width}>
      <FormField.Label
        data-testid="label--country"
        label={
          <S.Flex>
            {label}
            {info}
          </S.Flex>
        }
      />
      <Select
        error={error}
        disabled={disabled}
        options={options}
        onChange={handleChange}
        value={selectedCountryCode}
        data-testid="selectbox--country-name"
        maxVisibleItems={6}
        virtualized
        searchable
        contentWidth="34rem"
        valueRender={valueRender}
      />
    </S.FormFieldCompose>
  );
};

export default CountrySelectInput;
