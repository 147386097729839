export const subscriptionPostBodyDefaults = {
  period: null,
  zone_id: null,
  next_payment_date: null,
  expiration_date: null,
  billing_item: null,
  initial_charge: null,
  status: null,
  backup_interval_configurable: false,
  empty_bdb_password_allowed: false,
  isAA: false,
  shard_type_pricing_regions: [],
  rcp: null,
  minimal_pricing_regions: [],
  aa_change_request: null
};

export const essentialSubscriptionPayNowUrlTarget = 'bluesnap pay-now create subscription';
