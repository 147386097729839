import { useState } from 'react';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Button, FormField, Input } from '@redislabsdev/redis-ui-components';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { okNoContentStatus } from 'utils/constants/api/statuses';
import * as S from './ForgotPasswordForm.style';
import { emailValidationSchema } from '../../utils/LoginHelper';
import { usersMutations } from '../../../../queryClient/users/users.mutations';
import { ExtendedAxiosError } from '../../../../types/interfaces';
import { extractErrorAndShowToast } from '../../../../utils/helpers/extractErrorAndShowToast';
import UserFormInfo from '../UserFormInfo/UserFormInfo';
import { routes } from '../../../../utils/constants/routes';

const ForgotPassword = () => {
  const history = useHistory();
  const { mutate } = usersMutations.useResetPassword();
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen.forgotPassScreen;
  const resetPassSchema = emailValidationSchema(t);
  const [showCheckEmail, checkShowCheckEmail] = useState(false);

  const onSubmit = ({ email }: { email: string }) => {
    mutate(email, {
      onSuccess: (response) => {
        if (response?.status === okNoContentStatus) {
          checkShowCheckEmail(true);
        }
      },
      onError: (e: ExtendedAxiosError) => {
        extractErrorAndShowToast(e);
      }
    });
  };

  if (showCheckEmail) {
    return (
      <UserFormInfo
        title={t(keyPrefix.checkEmail)}
        text={t(keyPrefix.clickToResetText)}
        showBackToLoginButton={false}
      />
    );
  }

  return (
    <S.FormWrapper>
      <S.ForgotPasswordTitle>{t(keyPrefix.forgotPassTitle)}</S.ForgotPasswordTitle>
      <S.ForgotPasswordDescription>{t(keyPrefix.forgotPassText1)}</S.ForgotPasswordDescription>
      <Formik initialValues={{ email: '' }} validationSchema={resetPassSchema} onSubmit={onSubmit}>
        {({ handleChange, handleBlur, dirty, isValid, errors }) => (
          <Form>
            <FormField label="Email" additionalText={t(keyPrefix.forgotPassText2)}>
              <Input
                autoComplete="new-email"
                name="email"
                type="email"
                id="textfield--email"
                data-testid="textinput--email-address"
                onChange={handleChange('email')}
                onBlur={handleBlur}
                error={errors.email as string}
              />
            </FormField>
            <S.FormButtonContainer>
              <Button
                data-testid="button--cancel"
                size="large"
                onClick={() => history.push(routes.login)}
                variant="secondary-ghost"
              >
                {t(keyPrefix.cancelBtn)}
              </Button>
              <Button
                data-testid="button--submit"
                type="submit"
                size="large"
                disabled={!dirty || !isValid}
              >
                {t(keyPrefix.resetPassBtn)}
              </Button>
            </S.FormButtonContainer>
          </Form>
        )}
      </Formik>
    </S.FormWrapper>
  );
};

export default ForgotPassword;
