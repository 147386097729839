import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { PRIVACY_LINK, TERMS_LINK } from 'utils/constants/docLinks';
import { useNewCustomerLoginStore } from 'screens/NewCustomer/store/useNewCustomerLoginStore';
import { useSelector } from 'react-redux';
import useSamlRedirectNotification from '../../hooks/useShowSamlNotifications';
import * as S from './SignInForm.style';
import SignInWithEmailForm from './components/SignInWithEmail/SignInWithEmailForm';
import DontHaveAnAccount from './components/DontHaveAnAccount/DontHaveAnAccout';
import OrSeparator from '../OrSeperator/OrSeparator';
import { loginStatusSelector, mfaPayloadSelector } from '../../../../store/auth/auth.selectors';
import MfaLoginModal from '../MfaLogin/MfaLoginModal';
import SocialSignIn from '../SocialLogin/SocialLogin';
import { getQueryParam } from '../../../../utils/queryParam/getQueryParam';
import useAwsMarketPlaceQueryParamsErrorMessage from '../../hooks/useAwsMarketPlaceQueryParamsErrorMessage';
import useAwsMarketPlaceQueryParamsBoundCorrectlyMessage from '../../hooks/useAwsMarketPlaceQueryParamsBoundCorrectlyMessage';

const SignInForm = () => {
  const isResolved = useSelector(loginStatusSelector) === 'resolved';
  useAwsMarketPlaceQueryParamsBoundCorrectlyMessage(isResolved);
  useAwsMarketPlaceQueryParamsErrorMessage();
  useSamlRedirectNotification();
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen;
  const { setIsFirstLogin } = useNewCustomerLoginStore();

  const mfaParams = useSelector(mfaPayloadSelector);

  const marketPlace = getQueryParam('marketplace');
  window.localStorage.setItem('rcPlan', getQueryParam('r'));

  useEffect(() => {
    setIsFirstLogin(false);
  }, []);

  return (
    <S.SignInFormContainer>
      {mfaParams && <MfaLoginModal />}
      <S.SignInTitle>{t(keyPrefix.signIn)}</S.SignInTitle>
      <SocialSignIn allowSSo />
      <OrSeparator />
      <SignInWithEmailForm />
      <S.SignupContainer>
        <DontHaveAnAccount marketPlace={marketPlace} />
        <Trans
          i18nKey={keyPrefix.legalContent}
          components={{
            a1: <a href={TERMS_LINK}>text</a>,
            a2: <a href={PRIVACY_LINK}>text</a>
          }}
        />
      </S.SignupContainer>
    </S.SignInFormContainer>
  );
};
export default SignInForm;
