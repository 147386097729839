import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../../components/Toast/showToast';
import { i18translation } from '../../../locale/translations/i18NsPaths';

const useSamlRedirectNotification = () => {
  const { t } = useTranslation();
  const samlRedirectAndNotification = window.sessionStorage.getItem('samlRedirectAndNotification');

  useEffect(() => {
    if (samlRedirectAndNotification) {
      window.sessionStorage.removeItem('samlRedirectAndNotification');
      window.sessionStorage.setItem('redirectToSamlConfig', 'true');

      if (samlRedirectAndNotification === 'success') {
        showToast(
          {
            message: t(
              i18translation.accessManagement.tabs.singleSignOn.notifications.successTitle
            ),
            description: t(
              i18translation.accessManagement.tabs.singleSignOn.notifications.testConnectionSuccess
            )
          },
          'success'
        );
      }

      if (samlRedirectAndNotification === 'failed') {
        showToast({
          message: t(i18translation.accessManagement.tabs.singleSignOn.notifications.failedTitle),
          description: t(
            i18translation.accessManagement.tabs.singleSignOn.notifications.testConnectionFailed
          )
        });
      }
    }
  }, [samlRedirectAndNotification]);
};

export default useSamlRedirectNotification;
