import { CouponsState, CreditsActionTypes, CreditsTypesEnum } from './credits.types';

const initialState = {
  coupons: {
    status: 'idle',
    data: [],
    error: ''
  } as CouponsState,
  couponsBalance: 0,
  couponsTotal: 0,
  couponsUsedAmount: 0
};

const creditsReducer = (state = initialState, action: CreditsActionTypes): typeof initialState => {
  switch (action.type) {
    case CreditsTypesEnum.GET_COUPONS_REQUEST:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          status: 'pending'
        }
      };

    case CreditsTypesEnum.GET_COUPONS_SUCCESS:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          data: action.payload.coupons,
          status: 'resolved'
        },
        couponsBalance: action.payload.couponsBalance,
        couponsTotal: action.payload.couponsTotal,
        couponsUsedAmount: action.payload.couponsUsedAmount
      };

    case CreditsTypesEnum.GET_COUPONS_FAILURE:
      return {
        ...state,
        coupons: {
          ...state.coupons,
          status: 'rejected',
          error: action.error
        }
      };

    default:
      return state;
  }
};

export default creditsReducer;
