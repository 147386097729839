import { set, cloneDeep } from 'lodash';
import enJson from '../en.json';

type EnJson = typeof enJson;

export const transformObjectToLeafsWithPaths = <T extends object>(object: T) => {
  const getLeafNodesPaths = (target: T) => {
    const result: string[][] = [];
    // eslint-disable-next-line consistent-return
    const iterate = (o: object, p: string[]) => {
      if (typeof o === 'object') {
        const objKeys = Object.keys(o);

        return objKeys.forEach((k) => {
          iterate(o[k], p.concat(k));
        });
      }
      result.push(p);
    };

    iterate(target, []);

    return result;
  };

  const modifiedObject = cloneDeep(object);
  const objectLeafPaths = getLeafNodesPaths(modifiedObject);
  objectLeafPaths.forEach((leafPathArray) => {
    const translationLeafPath = leafPathArray.join('.');

    set(modifiedObject, translationLeafPath, translationLeafPath);
  });

  return modifiedObject;
};

const createNsPathsObject = (ns: keyof EnJson) => {
  return transformObjectToLeafsWithPaths(enJson[ns]);
};

export default createNsPathsObject;
