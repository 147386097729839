import { useLayoutEffect, useState } from 'react';
import { MOBILE_BANNER_KEY, MOBILE_MAX_WIDTH } from '../utils/constants';

const useMobileBanner = () => {
  const [isBannerDismissed, setIsBannerDismissed] = useState<boolean>(
    !!sessionStorage.getItem(MOBILE_BANNER_KEY)
  );
  const [isScreenSmall, setIsScreenSmall] = useState<boolean>(false);

  useLayoutEffect(() => {
    setIsScreenSmall(window.innerWidth < MOBILE_MAX_WIDTH);
  }, []);

  const dismissBanner = () => {
    setIsBannerDismissed(true);
    sessionStorage.setItem(MOBILE_BANNER_KEY, 'true');
  };

  return { showBanner: !isBannerDismissed && isScreenSmall, dismissBanner };
};

export default useMobileBanner;
