import { isNull } from 'lodash';
import { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { parametersQueries } from '../queryClient/parameters/parameters.queries';
import { routes } from '../utils/constants/routes';

export const RedirectToSignUp: FC = ({ children }) => {
  const { systemParams } = parametersQueries.useSystemParameters();
  const isSignUpRoute = !isNull(useRouteMatch(routes.signUp));

  if (isSignUpRoute && systemParams?.signup_main_page) {
    window.location.href = systemParams.signup_main_page;

    return null;
  }

  return <>{children}</>;
};
