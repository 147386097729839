import { useEffect } from 'react';
import { AuthBroadcastEventData } from './AuthBroadcast.types';
import store from '../../../store/index';
import { authIdSelector, isLoggedInSelector } from '../../../store/auth/auth.selectors';
import { handleUnsupportedClient } from './utils/handleUnsupportedClient';

let authBc: BroadcastChannel | null = null;
const AUTH_CHANNEL_NAME = 'broadcast-channel-authentication';

export function handleMessage(this: BroadcastChannel, ev: MessageEvent<AuthBroadcastEventData>) {
  switch (ev.data.type) {
    case 'login': {
      const currentId = authIdSelector(store.getState());
      if (currentId !== ev.data.payload.id) {
        window.location.replace('/');
      }
      break;
    }

    case 'logout': {
      if (isLoggedInSelector(store.getState())) {
        window.location.replace('/');
      }
      break;
    }
  }
}

export const initChannel = () => {
  try {
    if (!authBc) {
      authBc = new BroadcastChannel(AUTH_CHANNEL_NAME);
    }
  } catch (error) {
    handleUnsupportedClient(error);
  }
};

initChannel();
const useChannel = () => {
  useEffect(() => {
    authBc?.addEventListener('message', handleMessage);

    return () => authBc?.removeEventListener('message', handleMessage);
  }, []);
};

const postMessage = (action: AuthBroadcastEventData) => {
  authBc?.postMessage(action);
};

const AuthBroadcast = {
  useChannel,
  postMessage
};

export default AuthBroadcast;
