import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import { ExtendedAxiosError, ExtendedAxiosResponse, ErrorInfo } from '../../../types/interfaces';
import sentryTags from '../constants/sentryTags.constants';
import sentryContexts from '../constants/sentryContexts.constant';
import { extractErrorCode } from '../../../utils/helpers/extractError';
import shouldFilterHttpError from './shouldFilterHttpError';

const logHttpResponseError = (error: ExtendedAxiosError) => {
  const { headers } = error.response;

  const errorCode = extractErrorCode(error.response.data);
  const isGeneralErrorMessage = errorCode === 'general-error';
  if (!shouldFilterHttpError(error, isGeneralErrorMessage)) {
    Sentry.withScope((scope) => {
      scope.setFingerprint(getErrorFingerPrint(error.response));
      Sentry.setContext(
        sentryContexts.displayedErrorInUi,
        getMappedErrorMessageContext(error.response)
      );
      Sentry.setContext(sentryContexts.displayHeadersFromResponse, getScrubbedHeaders(headers));
      Sentry.setTag(sentryTags.httpRequestError, 'true');
      Sentry.setTag(sentryTags.httpMethod, error.response.config.method);
      Sentry.setTag(sentryTags.httpStatus, error.response.status);
      Sentry.setTag(sentryTags.httpUrl, error.response.config.url);
      Sentry.setTag(sentryTags.httpErrorCode, extractErrorCode(error.response.data));

      if (isGeneralErrorMessage) {
        Sentry.setTag(sentryTags.generalErrorMessage, 'true');
      }

      Sentry.captureException(error);
    });
  }
};

const getErrorFingerPrint = (axiosResponse: ExtendedAxiosResponse) => [
  axiosResponse.config.method,
  getUrlFingerPrint(axiosResponse.config.url),
  axiosResponse.status.toString(),
  extractErrorCode(axiosResponse.data)
];

const getUrlFingerPrint = (url: string) => {
  const [urlWithoutQueryParams] = url.split('?');
  const urlWithoutRouterParams = urlWithoutQueryParams.replace(/\d+/g, '*');

  return urlWithoutRouterParams;
};

const getDisplayedMessage = (errorInfo: ErrorInfo | ErrorInfo[]) => {
  if (Array.isArray(errorInfo)) {
    return i18n.t(errorInfo[0].message);
  }

  return i18n.t(errorInfo.message);
};

const getScrubbedHeaders = (headers) => {
  const scrubbedHeaders = JSON.parse(JSON.stringify(headers));

  // remove sensitive headers here
  return { response_headers: scrubbedHeaders };
};

const getMappedErrorMessageContext = (axiosResponse: ExtendedAxiosResponse) => {
  return {
    mapped_error_message: getDisplayedMessage(axiosResponse.errorInfo)
  };
};

export default logHttpResponseError;
