import { useMutation } from '@tanstack/react-query';
import { samlKeys } from './saml.keys';
import { samlApi } from '../../services/api/resources/saml/saml.resource';

const useGetIdpMutation = () => {
  const getIdp = async (email: string) => {
    const { data } = await samlApi.getIdp(email);

    return data;
  };

  return useMutation({
    mutationKey: samlKeys.getIdp,
    mutationFn: getIdp
  });
};

export const samlMutations = {
  useGetIdp: useGetIdpMutation
};
