import {
  MetadataActionTypes,
  ResetPageAction,
  ResetStoreAction,
  RootReducers
} from './store.types';

export const resetPage = (payload: RootReducers): ResetPageAction => ({
  type: MetadataActionTypes.RESET_PAGE,
  payload
});

export const resetStoreData = (): ResetStoreAction => ({
  type: MetadataActionTypes.RESET_STORE_DATA
});
