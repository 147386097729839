export enum PlanActionTypes {
  GET_PLANS_REQUEST = 'GET_PLANS_REQUEST',
  GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS',
  GET_PLANS_FAILURE = 'GET_PLANS_FAILURE'
}

export interface GetPlansRequestAction {
  type: PlanActionTypes.GET_PLANS_REQUEST;
}

export interface GetPlansSuccessAction {
  type: PlanActionTypes.GET_PLANS_SUCCESS;
  payload: PlanById;
}

export interface GetPlansFailureAction {
  type: PlanActionTypes.GET_PLANS_FAILURE;
  payload: string;
}

export type PlanById = Record<string, Plan>;
export type PlanTypes = GetPlansRequestAction | GetPlansSuccessAction | GetPlansFailureAction;

export interface PlanState extends BaseState {
  data: PlanById;
}
