import SignUpFormWrapper from './components/SignUpForm/SignUpFormWrapper';
import MobileBanner from '../MobileBanner/MobileBanner';

const SignUp = () => {
  return (
    <>
      <SignUpFormWrapper />
      <MobileBanner />
    </>
  );
};

export default SignUp;
