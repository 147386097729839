/* eslint-disable @typescript-eslint/dot-notation */
import { MFE_LIST } from 'mfesConfig';
import { EnvValueType, OriginsType } from './config.types';
import { ServiceOrigins } from './serviceOrigins';

const PROD_HOSTS = ['app.redislabs.com', 'cloud.redis.io'];

const EXTERNAL_HOSTS = ['test.redislabs.com', 'sm.ui.dev.redislabs.com'];

const IS_PRODUCTION = PROD_HOSTS.includes(window.location.hostname);
const IS_EXTERNAL_HOSTS = EXTERNAL_HOSTS.includes(window.location.hostname);
const RC_URL = 'https://sm.ui.dev.redislabs.com/rc';
const dynamicHost = /^([\w\-.]+).(redislabs.com|redis.io)$/.exec(window.location.hostname);
let search;
try {
  search = decodeURIComponent(window.location.search);
} catch {
  search = window.location.search;
}
const searchParams = new URLSearchParams(search);
let API_ENV: EnvValueType;

if (IS_PRODUCTION) {
  API_ENV = 'app';
} else if (process.env.VITE_APP_API_ENV) {
  API_ENV = process.env.VITE_APP_API_ENV as EnvValueType;
} else if (window.location.href.startsWith(RC_URL)) {
  API_ENV = 'app-qa.qa';
} else if (searchParams.get('api_env')) {
  API_ENV = searchParams.get('api_env') as EnvValueType;
  window.sessionStorage.setItem('api_env', API_ENV);
} else if (window.sessionStorage.getItem('api_env') !== null) {
  API_ENV = window.sessionStorage.getItem('api_env') as EnvValueType;
} else if (dynamicHost?.[1]) {
  API_ENV = dynamicHost[1] as EnvValueType;
} else {
  API_ENV = 'app-qa.qa';
}

ServiceOrigins.DEFAULT = {
  TAGGING: `https://${API_ENV}.redislabs.com/tagging`,
  SM: `https://${API_ENV}.redislabs.com/api/v1`,
  MW: `https://${API_ENV}.redislabs.com/api/v1/maintenance-window`,
  KYC: `https://${API_ENV}.redislabs.com/kyc/api`,
  BILLING: `https://${API_ENV}.redislabs.com/billing`,
  TGW: `https://${API_ENV}.redislabs.com/api/v1/cloud-transit-gateway`
};

ServiceOrigins.RELATIVE = {
  TAGGING: `/tagging`,
  SM: `/api/v1`,
  MW: `/api/v1/maintenance-window`,
  KYC: `/kyc/api`,
  BILLING: `/billing`,
  TGW: `/api/v1/cloud-transit-gateway`
};

let CURRENT_ORIGINS: OriginsType;
if (IS_PRODUCTION) {
  CURRENT_ORIGINS = ServiceOrigins.RELATIVE;
} else if (process.env.NODE_ENV === 'test') {
  API_ENV = 'TEST';
  CURRENT_ORIGINS = ServiceOrigins.TEST;
} else if (process.env.VITE_APP_SM_URL) {
  CURRENT_ORIGINS = ServiceOrigins.LOCAL_SM;
} else if (IS_EXTERNAL_HOSTS || searchParams.has('api_env')) {
  CURRENT_ORIGINS = ServiceOrigins.DEFAULT;
} else {
  CURRENT_ORIGINS = ServiceOrigins.RELATIVE;
}

const IS_RELATIVE_API = CURRENT_ORIGINS === ServiceOrigins.RELATIVE;

// Allow overriding the default origins with environment variables
CURRENT_ORIGINS = {
  TAGGING: process.env.VITE_APP_TAGGING_URL || CURRENT_ORIGINS.TAGGING,
  SM: process.env.VITE_APP_SM_URL || CURRENT_ORIGINS.SM,
  MW: process.env.VITE_APP_MW_URL || CURRENT_ORIGINS.MW,
  KYC: process.env.VITE_APP_KYC_URL || CURRENT_ORIGINS.KYC,
  BILLING: process.env.VITE_APP_BILLING_URL || CURRENT_ORIGINS.BILLING,
  TGW: process.env.VITE_APP_TGW_URL || CURRENT_ORIGINS.TGW
};

const LAUNCH_DARKLY_CLIENT_ID_MAP: Record<EnvValueType, string> = {
  TEST: null,
  app: '64febc01e87179120cab3b9c',
  'app-staging.qa': '654a13d271ebcb11e9e2a83d',
  'app-sm.k8s-auto.sm-qa.qa': '666559be51ae021009e52781',
  'app-sm.k8s-aa.sm-qa.qa': '655c8ea0c3a8bb12577caa8d',
  'app-sm.k8s-billing-dev.sm-qa.qa': '654a1258a77d3011d7beeeaf',
  'app-sm.k8s-billing.sm-qa.qa': '654a109ef38dc41338523a3d',
  'app-sm.k8s-cloudapi.sm-qa.qa': '654a128b71ebcb11e9e2a61c',
  'app-qa.qa': '655c8fa6ea291f125eb7fe40',
  'app-sm.k8s-dev.sm-qa.qa': '654a1580a77d3011d7bef3b0',
  'app-gcp.qa': '654a15a320c646126344aead',
  'app-sm.k8s-gh.sm-qa.qa': '652bd44fc07b0d12aaf4296e',
  'app-sm.k8s-idm.sm-qa.qa': '65647b03fd742213146a5742',
  'app-sm.k8s-integration.sm-qa.qa': '655cafb3ea291f125eb8381e',
  'app-sm.k8s-mw.sm-qa.qa': '65647af99ab18512d02a29ec',
  'app-sm.k8s-psc.sm-qa.qa': '654a1325bd4a5511f35f7339',
  'app-sm.k8s-rcp.sm-qa.qa': '654a134f5f2163134a67c9b8'
};

const REDIS_INSIGHT_CLOUD_BASE_URL =
  process.env.VITE_REDIS_INSIGHT_CLOUD_BASE_URL ||
  `https://ri.${API_ENV.replace('app-sm.', '')}.redislabs.com`;

const getMfeHostUrls = () => {
  return MFE_LIST.reduce((acc, mfe) => {
    let URL = `/cloud-ui-modules/${mfe.name}/entry.js`;
    const REMOTE_MFE_URL_PARAM_KEY = `${mfe.name}_mfe_remote_url`;
    // We have to make sure that we do not allow remote MFE loading in production
    if (!IS_PRODUCTION) {
      if (process.env[`VITE_APP_LOCAL_${mfe.name.toUpperCase()}_MFE`] === 'true') {
        URL = `https://test.redislabs.com:${mfe.port}/assets/entry.js`;
      } else if (searchParams.has(REMOTE_MFE_URL_PARAM_KEY)) {
        const mfeRemoteUrl = searchParams.get(REMOTE_MFE_URL_PARAM_KEY);
        window.sessionStorage.setItem(REMOTE_MFE_URL_PARAM_KEY, mfeRemoteUrl);
        URL = mfeRemoteUrl;
      } else if (window.sessionStorage.getItem(REMOTE_MFE_URL_PARAM_KEY)) {
        URL = window.sessionStorage.getItem(REMOTE_MFE_URL_PARAM_KEY);
      } else if (IS_EXTERNAL_HOSTS) {
        URL = `https://${API_ENV}.redislabs.com/cloud-ui-modules/${mfe.name}/entry.js`;
      }
    }

    acc[mfe.name] = URL;

    return acc;
  }, {});
};

const mfeHostUrls = getMfeHostUrls();
window['MFE_MODULES'] = mfeHostUrls;

export const Config = {
  API_ENV,
  CURRENT_ORIGINS,
  RELEASE_NUMBER: process.env.VITE_APP_RELEASE_NUMBER || 'local',
  LAUNCH_DARKLY_CLIENT_ID: IS_PRODUCTION
    ? LAUNCH_DARKLY_CLIENT_ID_MAP['app']
    : LAUNCH_DARKLY_CLIENT_ID_MAP[API_ENV] || '',
  MFE_MODULES_URLS: mfeHostUrls,
  REDIS_INSIGHT_CLOUD_BASE_URL,
  IS_RELATIVE_API
};
