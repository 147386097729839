import {
  GetPlansFailureAction,
  GetPlansRequestAction,
  GetPlansSuccessAction,
  PlanActionTypes,
  PlanById
} from './plans.types';

export const getPlans = (): GetPlansRequestAction => ({
  type: PlanActionTypes.GET_PLANS_REQUEST
});

export const getPlansSuccess = (data: PlanById): GetPlansSuccessAction => ({
  type: PlanActionTypes.GET_PLANS_SUCCESS,
  payload: data
});

export const getPlansFailure = (error: string): GetPlansFailureAction => ({
  type: PlanActionTypes.GET_PLANS_FAILURE,
  payload: error
});
