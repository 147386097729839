import RedisApiAxiosInstance from 'services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { Config } from '../../config';

const initDatabasesMFEApiService = async () => {
  try {
    // @ts-ignore
    const apiService = (await import('databases/initApiService')).default;
    apiService(RedisApiAxiosInstance, !Config.IS_RELATIVE_API && Config.CURRENT_ORIGINS.SM);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('cannot load initDatabasesMFEApiService', e);
  }
};

export default initDatabasesMFEApiService;
