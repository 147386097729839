export enum MetadataActionTypes {
  RESET_PAGE = 'RESET_PAGE',
  RESET_STORE_DATA = 'RESET_STORE_DATA'
}

export interface ResetPageAction {
  type: MetadataActionTypes.RESET_PAGE;
  payload: RootReducers;
}

export interface ResetStoreAction {
  type: MetadataActionTypes.RESET_STORE_DATA;
}

export type RootReducers = keyof RootState;
