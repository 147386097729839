import { ApiPaths } from 'services/api/apiPaths';
import RedisApiAxiosInstance from '../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { AaChangeRequestPayload, ExecuteAaSubsCallParams } from './pro.types';

export const aaChangeRequest = (body: { aaChangeRequest: AaChangeRequestPayload }) =>
  RedisApiAxiosInstance.post<{ aaChangeRequest: AaChangeRequestPayload }>(
    ApiPaths.AA_CHANGE_REQUESTS,
    body
  );

export const getAAChangeRequestStatus = (aaChangeRequestId: number) =>
  RedisApiAxiosInstance.get<{ aaChangeRequest: AaChangeRequestPayload }>(
    `${ApiPaths.AA_CHANGE_REQUESTS}/${aaChangeRequestId}`
  );

export const executeAaChangeRequest = ({
  aaChangeRequestId,
  subscriptionName,
  paymentId,
  isMarketplace
}: ExecuteAaSubsCallParams) =>
  RedisApiAxiosInstance.post(`${ApiPaths.AA_CHANGE_REQUESTS}/${aaChangeRequestId}/execute`, {
    aaChangeRequestExecution: {
      payment_info_id: paymentId,
      subscription_name: subscriptionName,
      is_marketplace: isMarketplace
    }
  });
