export enum AllowListTypesEnum {
  GET_ALLOW_LIST_REQUEST = 'GET_ALLOW_LIST_REQUEST',
  GET_ALLOW_LIST_SUCCESS = 'GET_ALLOW_LIST_SUCCESS',
  GET_ALLOW_LIST_FAILURE = 'GET_ALLOW_LIST_FAILURE',
  CLEAR_ALLOW_LIST = 'CLEAR_ALLOW_LIST',
  APPLY_ALL_CHANGES_REQUEST = 'APPLY_ALL_CHANGES_REQUEST',
  APPLY_ALL_CHANGES_SUCCESS = 'APPLY_ALL_CHANGES_SUCCESS',
  APPLY_ALL_CHANGES_FAILURE = 'APPLY_ALL_CHANGES_FAILURE'
}

export type AllowListActionTypes =
  | GetAllowListAction
  | GetAllowListSuccessAction
  | GetAllowListFailureAction
  | ClearAllowListAction
  | ApplyAllChangesRequestAction
  | ApplyAllChangesSuccessAction
  | ApplyAllChangesFailureAction;

export type ApplyAllChangesPayload = {
  rcpWhitelist: RcpWhitelist;
  rcpId: number;
};

export interface RcpWhitelist {
  cidr_ips: string[];
  security_group_ids: string[];
  errors: string[];
}
export interface IAllowListState extends BaseState {
  data: RcpWhitelist;
}

export interface ClearAllowListAction {
  type: AllowListTypesEnum.CLEAR_ALLOW_LIST;
}
export interface GetAllowListAction {
  type: AllowListTypesEnum.GET_ALLOW_LIST_REQUEST;
  payload: number;
}

export interface GetAllowListSuccessAction {
  type: typeof AllowListTypesEnum.GET_ALLOW_LIST_SUCCESS;
  payload: RcpWhitelist;
}

export interface GetAllowListFailureAction {
  type: typeof AllowListTypesEnum.GET_ALLOW_LIST_FAILURE;
  payload: string;
}

export interface ApplyAllChangesRequestAction {
  type: AllowListTypesEnum.APPLY_ALL_CHANGES_REQUEST;
  payload: ApplyAllChangesPayload;
}
export interface ApplyAllChangesSuccessAction {
  type: AllowListTypesEnum.APPLY_ALL_CHANGES_SUCCESS;
  payload: RcpWhitelist;
}
export interface ApplyAllChangesFailureAction {
  type: AllowListTypesEnum.APPLY_ALL_CHANGES_FAILURE;
  payload: string;
}
