import { merge, omit } from 'lodash';
import { combineReducers } from 'redux';
import { objectToNormalized } from '../../utils/objectToNormalized';
import { DatabasesActionTypes } from '../databases/databases.types';
import plansReducer from './plans/plans.reducer';
import { SubscriptionsActionTypes, SubscriptionTypes } from './subscriptions.types';

const initialState = {
  subs: { status: 'idle', data: {}, error: '' } as SubscriptionsState,
  updateSubscriptionState: { status: 'idle', error: '' } as BaseState
};

type SubscriptionState = typeof initialState;

const subscriptionReducer = (
  state = initialState,
  action: SubscriptionTypes
): SubscriptionState => {
  switch (action.type) {
    case SubscriptionsActionTypes.START_SUBSCRIPTIONS_POLLING:
    case SubscriptionsActionTypes.GET_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        subs: {
          ...state.subs,
          status: state.subs.status === 'resolved' ? 'resolved' : 'pending'
        }
      };

    case SubscriptionsActionTypes.ADD_NEW_SUBSCRIPTION:
      return {
        ...state,
        subs: {
          ...state.subs,
          data: merge({}, state.subs.data, objectToNormalized(action.payload))
        }
      };

    case SubscriptionsActionTypes.SET_SUBSCRIPTIONS_STATUS:
      return {
        ...state,
        subs: {
          ...state.subs,
          status: action.payload
        }
      };

    case SubscriptionsActionTypes.GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subs: { ...state.subs, data: action.payload, status: 'resolved' }
      };

    case SubscriptionsActionTypes.GET_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        subs: {
          ...state.subs,
          status: 'rejected',
          error: action.payload
        }
      };

    case SubscriptionsActionTypes.DELETE_SUBSCRIPTION_SUCCESS: {
      const deletedSub = state.subs.data[action.payload];
      deletedSub.status = 'pending';
      if (deletedSub.rcp || deletedSub.aa_rcp) {
        if (deletedSub.rcp) {
          deletedSub.rcp.status = 'destroy_pending';
        } else {
          deletedSub.aa_rcp.status = 'delete_pending';
        }

        return {
          ...state,
          subs: {
            ...state.subs,
            data: {
              ...state.subs.data,
              [action.payload]: deletedSub
            }
          }
        };
      }
      // If it is fixed subscription, remove it from the map
      const { [action.payload]: deletedSubscription, ...filteredSubs } = state.subs.data;

      return {
        ...state,
        subs: {
          ...state.subs,
          data: filteredSubs
        }
      };
    }

    case SubscriptionsActionTypes.UPDATE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        updateSubscriptionState: {
          status: 'pending',
          error: ''
        }
      };

    case SubscriptionsActionTypes.UPDATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subs: {
          ...state.subs,
          data: merge({}, state.subs.data, objectToNormalized(action.payload))
        },
        updateSubscriptionState: {
          status: 'resolved',
          error: ''
        }
      };
    }

    case SubscriptionsActionTypes.UPDATE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        updateSubscriptionState: {
          status: 'rejected',
          error: action.payload
        }
      };

    case SubscriptionsActionTypes.RESET_UPDATE_SUBSCRIPTION_STATE:
      return {
        ...state,
        updateSubscriptionState: initialState.updateSubscriptionState
      };

    case DatabasesActionTypes.CREATE_DB_SUCCESS: {
      const updatedSub = state.subs.data[action.payload.subscription];
      updatedSub.bdb_statuses[action.payload.id] = action.payload.status;

      return {
        ...state,
        subs: {
          ...state.subs,
          data: merge({}, state.subs.data, objectToNormalized(updatedSub))
        }
      };
    }

    case DatabasesActionTypes.DELETE_DB_SUCCESS: {
      const updatedSub = state.subs.data[action.payload.subscription];
      updatedSub.bdb_statuses = omit(updatedSub.bdb_statuses, action.payload.id);

      return {
        ...state,
        subs: {
          ...state.subs,
          data: merge({}, state.subs.data, objectToNormalized(updatedSub))
        }
      };
    }

    default:
      return state;
  }
};

export default combineReducers({ subscriptions: subscriptionReducer, plans: plansReducer });
