import { differenceBy, filter, isEqual, omit } from 'lodash';
import { sortById } from '../../utils/helpers/misc';
import { BdbById } from './databases.types';

export const getBdbsToUpdateBasedOnSubBdbs = (
  currentBdbs: BdbById,
  subBdbs: Bdb[],
  subId: string
) => {
  const prevSubBdbs = filter(currentBdbs, (bdb) => bdb.subscription === parseInt(subId, 10));

  let updatedBdbs = { ...currentBdbs };

  let isChanged = false;

  const removedBdbs = differenceBy(prevSubBdbs, subBdbs, 'id');

  if (removedBdbs.length) {
    // When bdbs were deleted
    isChanged = true;
    for (const removedBdb of removedBdbs) {
      updatedBdbs = omit(updatedBdbs, removedBdb.id);
    }
  }

  for (const fetchedSubBdb of subBdbs) {
    // When bdbs are modified or added
    if (!updatedBdbs[fetchedSubBdb.id] || !isEqual(updatedBdbs[fetchedSubBdb.id], fetchedSubBdb)) {
      isChanged = true;
      updatedBdbs[fetchedSubBdb.id] = fetchedSubBdb;
    }
  }

  return isChanged ? updatedBdbs : null;
};

export const processFirstDatabaseActiveTooltip = (bdbs: Bdb[]) => {
  if (bdbs.length === 0) {
    window.localStorage.removeItem('firstDatabaseActiveFound');
  } else {
    const id = sortById(bdbs, true).filter((b) => b.status === 'active')?.[0]?.id ?? 0;
    if (id) {
      window.localStorage.setItem('firstDatabaseActiveFound', String(id));
    }
  }
};

export const processFirstDatabase = (bdbs: Bdb[]) => {
  const firstDbFlag = window.localStorage.getItem('firstDatabaseFlag');
  if (bdbs.length && firstDbFlag === null) {
    window.localStorage.setItem('firstDatabaseFlag', JSON.stringify(true));
  }
};

export const getBdbPort = (bdb: Partial<Bdb>) => {
  return bdb?.dns_master_public?.split(':')[1];
};
