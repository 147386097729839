import { useMutation, useQueryClient, UseMutationOptions } from '@tanstack/react-query';
import usersKeys from './users.keys';
import { usersApi } from '../../services/api/resources/users/users.resource';
import { extractErrorAndShowToast } from '../../utils/helpers/extractErrorAndShowToast';
import { ExtendedAxiosError } from '../../types/interfaces';

const useDeleteUserMutation = (userId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: usersKeys.deleteUser(userId),
    mutationFn: () => usersApi.remove(userId),
    onError: (e: ExtendedAxiosError) => {
      extractErrorAndShowToast(e);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(usersKeys.getAll);
    }
  });
};

const useUpdateUserMutation = (
  userId: number,
  options: UseMutationOptions<User, ExtendedAxiosError, Partial<User>> = {}
) => {
  const updateUser = async (user: Partial<User>) => {
    const { data } = await usersApi.update(user);

    return data.user;
  };

  return useMutation(usersKeys.updateUser(userId), updateUser, {
    onError: (e) => {
      extractErrorAndShowToast(e);
    },
    ...options
  });
};

const useCreateUserMutation = (
  options: UseMutationOptions<User, ExtendedAxiosError, Partial<User>> = {}
) => {
  const createUser = async (user: Partial<User>) => {
    const { data } = await usersApi.create(user);

    return data.user;
  };

  return useMutation(usersKeys.createUser, createUser, {
    onError: (e) => {
      extractErrorAndShowToast(e);
    },
    ...options
  });
};

const useResetPasswordMutation = () => {
  const resetPassword = async (email: string) => {
    const response = await usersApi.resetPassword(email);

    return response;
  };

  return useMutation({ mutationKey: usersKeys.resetPassword, mutationFn: resetPassword });
};

const useSetNewPasswordMutation = () => {
  const setNewPassword = async ({ id, password }: { id: string; password: string }) => {
    const response = await usersApi.setNewPassword(id, password);

    return response;
  };

  return useMutation({ mutationKey: usersKeys.setNewPassword, mutationFn: setNewPassword });
};

const useActivateUserInvitationMutation = () => {
  const activateUserInvitation = async ({
    key,
    id,
    password
  }: {
    key: string;
    id: string;
    password: string;
  }) => {
    const response = await usersApi.activateUserInvitation(key, id, password);

    return response;
  };

  return useMutation({
    mutationKey: usersKeys.activateUserInvitation,
    mutationFn: activateUserInvitation
  });
};

const useResendUserActivationLinkMutation = () => {
  const resendActivationLink = async (search: string) => {
    const response = await usersApi.resendActivationLink(search);

    return response;
  };

  return useMutation({
    mutationKey: usersKeys.resendActivationLink,
    mutationFn: resendActivationLink
  });
};

export const usersMutations = {
  useDeleteUser: useDeleteUserMutation,
  useUpdateUser: useUpdateUserMutation,
  useCreateUser: useCreateUserMutation,
  useResetPassword: useResetPasswordMutation,
  useSetNewPassword: useSetNewPasswordMutation,
  useActivateUserInvitation: useActivateUserInvitationMutation,
  useResendUserActivationLink: useResendUserActivationLinkMutation
};
