import { productTypesForPolling } from './auth.utils';

export const authReducerSelector = (state: RootState) => state.auth;

export const productTypeSelector = (state: RootState) => authReducerSelector(state).product_type;

export const productTypeForPollingSelector = (state: RootState) =>
  productTypesForPolling[productTypeSelector(state)];

export const isProductTypeUnifiedRcSelector = (state: RootState) =>
  authReducerSelector(state).product_type === 'unifiedrc';

export const isLoggedInSelector = (state: RootState) => authReducerSelector(state).loggedIn;
export const callCsrfFlagSelector = (state: RootState) => authReducerSelector(state).callCsrfFlag;
export const callCsrfPayloadSelector = (state: RootState) =>
  authReducerSelector(state).callCsrfPayload;

export const permissionsSelector = (state: RootState) => authReducerSelector(state).permissions;

export const loginStatusSelector = (state: RootState) => authReducerSelector(state).loginStatus;

export const accountIdSelector = (state: RootState) =>
  authReducerSelector(state).current_account_id;

export const authIdSelector = (state: RootState) => authReducerSelector(state).id;

export const userRoleSelector = (state: RootState) => authReducerSelector(state).role;

export const isSuperAgentSelector = (state: RootState) =>
  authReducerSelector(state)?.agent_type === 'super_agent';

export const isSupportAgentSelector = (state: RootState) =>
  authReducerSelector(state)?.agent_type === 'support_agent';

export const isAgentTypeSupportOrSuper = (state: RootState) =>
  isSuperAgentSelector(state) || isSupportAgentSelector(state);

export const isProductionSelector = (state: RootState) => authReducerSelector(state).production;

export const shouldNotifyProductionForAgentSelector = (state: RootState) =>
  isAgentTypeSupportOrSuper(state) && isProductionSelector(state);

export const userEmailSelector = (state: RootState) => authReducerSelector(state).email;

export const loginErrorCodeSelector = (state: RootState) =>
  authReducerSelector(state).loginErrorCode;

export const mfaPayloadSelector = (state: RootState) => authReducerSelector(state).mfaPayload;
