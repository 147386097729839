import { StripeActionTypes, StripeActionTypesEnum, StripeState } from './stripe.types';

const initialState = {
  billingCustomerId: null
} as StripeState;

const stripeReducer = (state = initialState, action: StripeActionTypes): typeof initialState => {
  switch (action.type) {
    case StripeActionTypesEnum.SET_BILLING_CUSTOMER:
      return {
        ...state,
        billingCustomerId: action.payload
      };
    default:
      return state;
  }
};

export default stripeReducer;
