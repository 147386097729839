import useLogOut from '../../../hooks/useLogOut';
import { closeOktaSession } from '../../../utils/oktaInstance';
import { samlTestConnectionEnd } from '../../AccessManagement/SingleSignOn/utils/saml.calls';

interface FlagSamlTestConnectionEndProps {
  testStatus: string;
}

const useSamlTestingConnection = () => {
  const { performLogout } = useLogOut();

  const flagSamlTestConnectionEnd = async ({ testStatus }: FlagSamlTestConnectionEndProps) => {
    const samlTestStep = window.sessionStorage.getItem('samlTestStep');
    try {
      const { samlTestId, samlId } = JSON.parse(samlTestStep ?? JSON.stringify({}));

      samlTestId &&
        (await samlTestConnectionEnd(samlId, {
          testId: samlTestId,
          status: testStatus
        }));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error on test saml connection end', e);
    } finally {
      await closeOktaSession();
    }

    window.sessionStorage.setItem('samlRedirectAndNotification', testStatus);
    window.sessionStorage.removeItem('samlTestStep');
    window.sessionStorage.removeItem('samlLogoutUrl');
    await performLogout();
  };

  return { flagSamlTestConnectionEnd };
};

export default useSamlTestingConnection;
