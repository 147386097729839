import { useSelector } from 'react-redux';
import { permissionsSelector } from '../../../store/auth/auth.selectors';
import { PermissionKey } from '../protectedComponents.types';
import userHasPermission from '../utils/userHasPermission';

const usePermissionValidation = (permissionKey: PermissionKey, partialPermissions?: boolean) => {
  const userPermissions = useSelector(permissionsSelector);

  return userHasPermission(permissionKey, userPermissions, partialPermissions);
};

export default usePermissionValidation;
