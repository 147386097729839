import { ApiPaths } from 'services/api/apiPaths';
import { createResourceApi } from '../../createResourceApi';

export const plansApi = createResourceApi(ApiPaths.PLANS, (rest) => ({
  getAll: (params?: {
    db_type?: string;
    sub_id?: number;
    only_customer_plans?: boolean;
    is_bdb_package?: boolean;
  }) => rest.get<{ plans: Plan[] }>({ config: { params } }),

  getAllCloudRegions: (type: 'all' | 'rcp' = 'all') =>
    rest.get<CloudRegion[]>({ path: 'cloud_regions', config: { params: { type } } }),

  getAllSelectable: (params?: { db_type?: string; subscription?: number }) =>
    rest.get<number[]>({ path: 'selectable', config: { params } }),

  ultimateRequest: () => rest.post({ path: 'ultimate-request' })
}));
