export const MAIN_PAGE_PADDING = '4rem';

const screenSizes = {
  small: 1260,
  medium: 1920,
  large: 2560
};

export const remSizes = {
  small: 1,
  medium: 1.1,
  large: 1.2
};

export const smallScreenQuery = `screen and (min-width: ${screenSizes.small}px)`;
export const mediumScreenQuery = `screen and (min-width: ${screenSizes.medium}px)`;
export const largeScreenQuery = `screen and (min-width: ${screenSizes.large}px)`;
