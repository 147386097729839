import {
  SubscriptionPostParams,
  SubscriptionRequiredPostPayload
} from '../createSubscription.types';
import { subscriptionPostBodyDefaults } from './essentials.constants';

export const createSubscriptionPostParams = (
  payload: SubscriptionRequiredPostPayload,
  account: string
): SubscriptionPostParams => ({
  subscription: {
    account,
    ...subscriptionPostBodyDefaults,
    ...payload
  }
});
