import { keys, size } from 'lodash';

const rootSubscriptionSelector = (state: RootState) => state.subscriptionAndPlans.subscriptions;

export const subscriptionStateSelector = (state: RootState) => rootSubscriptionSelector(state).subs;

export const subscriptionsSelector = (state: RootState) => subscriptionStateSelector(state).data;

export const subscriptionStatusSelector = (state: RootState) =>
  subscriptionStateSelector(state).status;

export const subscriptionErrorSelector = (state: RootState) =>
  subscriptionStateSelector(state).error;

export const subscriptionByIdSelector = (subscriptionId: string) => (state: RootState) =>
  subscriptionStateSelector(state).data[subscriptionId];

export const subscriptionDbsIdsSelector = (subscriptionId: string) => (state: RootState) => {
  const selectedSub = subscriptionByIdSelector(subscriptionId)(state);
  if (selectedSub && size(selectedSub.bdb_statuses)) {
    return keys(selectedSub.bdb_statuses);
  }

  return [];
};

export const isSubReservedSelector = (subscriptionId: string) => (state: RootState) =>
  subscriptionByIdSelector(subscriptionId)(state)?.rcp?.rcp_type === 'reserved';
