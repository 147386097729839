import { SocialLoginButtonProps } from './SocialLoginButton.types';
import * as S from './SocialLoginButton.style';

const SocialLoginButton = ({ name, onClick, icon, dataTestId }: SocialLoginButtonProps) => {
  return (
    <S.SocialLoginButtonsContainer data-testid={dataTestId} onClick={onClick}>
      <S.SocialLoginImage>{icon}</S.SocialLoginImage>
      <S.SocialLoginName>{name}</S.SocialLoginName>
    </S.SocialLoginButtonsContainer>
  );
};

export default SocialLoginButton;
