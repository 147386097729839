import { ApiPaths } from 'services/api/apiPaths';
import RedisApiAxiosInstance from '../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';

export const getCoupons = () => RedisApiAxiosInstance.get(ApiPaths.COUPONS);

export const applyCoupon = (couponCode: string) =>
  RedisApiAxiosInstance.post(ApiPaths.COUPONS, couponCode, {
    headers: {
      'Content-Type': 'application/text'
    }
  });
