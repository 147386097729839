import styled from 'styled-components/macro';
import { Banner, Modal, TextButton, Typography } from '@redislabsdev/redis-ui-components';

export const InputHint = styled(Typography.Body)`
  margin-bottom: 1.2rem;
  margin-top: 3rem;
`;

export const DidNotReceiveTheCodeContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  gap: 0.5rem;
`;

export const ModalContentFooterCompose = styled(Modal.Content.Footer.Compose)`
  margin-top: 10rem;
`;

export const Container = styled.div`
  padding: 0 6rem;
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;
export const ErrorBanner = styled(Banner)`
  margin-top: 2.4rem;
`;

export const ModalTextButton = styled(TextButton)`
  text-decoration: underline;
`;
