import { EditedCrdb, EditedCrdbInstance } from '../CrdbConfiguration.types';
import { CrdbDefaultConfig } from '../../../../../../../store/databases/databases.types';

export const getCrdbFieldPath = (field: keyof EditedCrdb) => {
  return `crdb.${field}`;
};

export const getGlobalFieldPath = (field: keyof CrdbDefaultConfig) => {
  return `${getCrdbFieldPath('default_db_config')}.${field}`;
};

export const getGlobalAccessControlFieldPath = (field: keyof AccessControl) => {
  return `${getGlobalFieldPath('access_control')}.${field}`;
};

export const getInstanceFieldGetters = (selectedInstanceIndex: number) => {
  const getInstancePath = () => `${getCrdbFieldPath('crdb_instances')}[${selectedInstanceIndex}]`;

  const getInstanceFieldPath = (field: keyof EditedCrdbInstance) => {
    return `${getInstancePath()}.${field}`;
  };

  const getInstanceAccessControlFieldPath = (field: keyof AccessControl) => {
    return `${getInstanceFieldPath('access_control')}.${field}`;
  };

  return {
    getInstancePath,
    getInstanceFieldPath,
    getInstanceAccessControlFieldPath
  };
};
