import {
  AllowListTypesEnum,
  GetAllowListAction,
  GetAllowListSuccessAction,
  GetAllowListFailureAction,
  RcpWhitelist,
  ClearAllowListAction,
  ApplyAllChangesRequestAction,
  ApplyAllChangesFailureAction,
  ApplyAllChangesSuccessAction,
  ApplyAllChangesPayload
} from './allowList.types';

export const getAllowListRequest = (rcpId: number): GetAllowListAction => {
  return {
    type: AllowListTypesEnum.GET_ALLOW_LIST_REQUEST,
    payload: rcpId
  };
};

export const getAllowListSuccess = (payload: RcpWhitelist): GetAllowListSuccessAction => ({
  type: AllowListTypesEnum.GET_ALLOW_LIST_SUCCESS,
  payload
});

export const getAllowListFailure = (error: string): GetAllowListFailureAction => ({
  type: AllowListTypesEnum.GET_ALLOW_LIST_FAILURE,
  payload: error
});

export const clearAllowList = (): ClearAllowListAction => ({
  type: AllowListTypesEnum.CLEAR_ALLOW_LIST
});

export const applyAllChangesRequest = (
  payload: ApplyAllChangesPayload
): ApplyAllChangesRequestAction => ({
  type: AllowListTypesEnum.APPLY_ALL_CHANGES_REQUEST,
  payload
});

export const applyAllChangesSuccess = (payload: RcpWhitelist): ApplyAllChangesSuccessAction => ({
  type: AllowListTypesEnum.APPLY_ALL_CHANGES_SUCCESS,
  payload
});

export const applyAllChangesFailure = (error: string): ApplyAllChangesFailureAction => ({
  type: AllowListTypesEnum.APPLY_ALL_CHANGES_FAILURE,
  payload: error
});
