import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { Modal } from '@redislabsdev/redis-ui-components';
import RedisBackground from '../RedisBackground/RedisBackground';
import { routes } from '../../../utils/constants/routes';

const MissingEmailSocialLogin = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const keyPrefix = i18translation.MissingEmailSocialLogin;

  const handleCancel = async () => {
    history.push(routes.logout);
  };

  return (
    <RedisBackground>
      <Modal
        defaultOpen
        title={t(keyPrefix.modal.title)}
        content={t(keyPrefix.modal.body)}
        primaryButtonText={t(keyPrefix.modal.buttons.close)}
        onPrimaryButtonClick={handleCancel}
        onCancel={handleCancel}
      />
    </RedisBackground>
  );
};

export default MissingEmailSocialLogin;
