export const getPlanType = (plan: Plan) => {
  const isAa = plan?.plan_type === 'aarcp';
  const isPro = plan?.plan_type === 'rcp';
  const isFreePlan = plan?.plan_type === 'free';
  const isPayAsYouGo = plan?.is_payg;

  return {
    isAa,
    isPro,
    isEssential: !isAa && !isPro,
    isRof: plan?.is_rof,
    isFreePlan,
    isPayAsYouGo
  };
};

export const getAllowedPlanBackups = (plan: Plan) => {
  const isBackupRedislabsAllowed =
    plan?.backup_path_type === 'redislabs' || plan?.backup_path_type === 'redislabs_custom';

  const isBackupCustomAllowed =
    plan?.backup_path_type === 'custom' || plan?.backup_path_type === 'redislabs_custom';

  const backupAllowCustomAndDefault = isBackupRedislabsAllowed && isBackupCustomAllowed;

  return { isBackupRedislabsAllowed, isBackupCustomAllowed, backupAllowCustomAndDefault };
};
