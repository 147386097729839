import { takeLatest, put, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import * as calls from './allowList.calls';
import * as actions from './allowList.action';
import {
  AllowListTypesEnum,
  ApplyAllChangesRequestAction,
  GetAllowListAction,
  RcpWhitelist
} from './allowList.types';
import { extractErrorAndShowToast } from '../../../utils/helpers/extractErrorAndShowToast';

function* getAllowList(action: GetAllowListAction) {
  try {
    const { payload } = action;
    const {
      data: { rcpWhitelist }
    }: AxiosResponse<{ rcpWhitelist: RcpWhitelist }> = yield call(calls.getAllowList, payload);
    yield put(actions.getAllowListSuccess(rcpWhitelist));
  } catch (e) {
    if (e.response) {
      yield put(actions.getAllowListFailure(e?.response?.data?.message));
      extractErrorAndShowToast(e);
    }
  }
}

function* applyAllChanges(action: ApplyAllChangesRequestAction) {
  try {
    const { payload } = action;
    const {
      data: { rcpWhitelist }
    }: AxiosResponse<{ rcpWhitelist: RcpWhitelist }> = yield call(calls.applyAllChanges, payload);
    yield put(actions.applyAllChangesSuccess(rcpWhitelist));
  } catch (e) {
    if (e.response) {
      yield put(actions.applyAllChangesFailure(e?.response?.data?.message));
      extractErrorAndShowToast(e);
    }
  }
}

function* AllowListSaga() {
  yield takeLatest(AllowListTypesEnum.GET_ALLOW_LIST_REQUEST, getAllowList);
  yield takeLatest(AllowListTypesEnum.APPLY_ALL_CHANGES_REQUEST, applyAllChanges);
}

export default AllowListSaga;
