export enum SystemLogsTypesEnum {
  GET_SYSTEM_LOGS_REQUEST = 'GET_SYSTEM_LOGS_REQUEST',
  GET_SYSTEM_LOGS_SUCCESS = 'GET_SYSTEM_LOGS_SUCCESS',
  GET_SYSTEM_LOGS_FAILURE = 'GET_SYSTEM_LOGS_FAILURE'
}

export type SystemLogsActionTypes =
  | GetSystemLogsRequestAction
  | GetSystemLogsSuccessAction
  | GetSystemLogsFailureAction;

export interface GetSystemLogsRequestAction {
  type: SystemLogsTypesEnum.GET_SYSTEM_LOGS_REQUEST;
  payload: GetSystemLogsPayload;
}

export interface GetSystemLogsSuccessAction {
  type: typeof SystemLogsTypesEnum.GET_SYSTEM_LOGS_SUCCESS;
  payload: GetSystemLogsResponse;
}

export interface GetSystemLogsFailureAction {
  type: typeof SystemLogsTypesEnum.GET_SYSTEM_LOGS_FAILURE;
}

export interface SystemLogsState extends BaseState {
  data: SystemLog[];
  totalPages: number;
  pageNumber: number;
  pageSize: number;
}

export interface GetSystemLogsPayload {
  textSearch: string;
  pageSize: number;
  page: number;
  sortAscending: boolean;
  sortedColumn: keyof SystemLog;
}

export interface GetSystemLogsResponse {
  meta: {
    page_number: number;
    total_pages: number;
  };
  systemLogs: SystemLog[];
}

export interface SystemLog {
  id: number;
  date: string;
  user_name: string;
  email: string;
  source: string;
  type: string;
  description: string;
  apiKeyName: string;
}
