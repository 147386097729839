export const SET_OLD_PRICING_PRICE_DETAILS = 'SET_OLD_PRICING_PRICE_DETAILS';

export interface OldPricingPriceDetails {
  additionalShardsRequired: number;
  additionalShardsPrice: number;
  additionalNodes: RcpNode[];
  totalAdditionalPrice: number;
  diskPrice: number;
}

export interface SetOldPricingPriceDetailsAction {
  type: typeof SET_OLD_PRICING_PRICE_DETAILS;
  payload: OldPricingPriceDetails;
}

export interface OldPricingTestSetupProps {
  openOldPricingDetailsDialog?: boolean;
}
