import { takeLatest, put, call } from 'redux-saga/effects';
import {
  SystemLogsTypesEnum,
  GetSystemLogsResponse,
  GetSystemLogsRequestAction
} from './systemLogs.types';
import { getSystemLogs } from './systemLogs.calls';
import { getSystemLogsSuccess, getSystemLogsFailure } from './systemLogs.actions';
import { extractErrorAndShowToast } from '../../utils/helpers/extractErrorAndShowToast';

function* getSystemLogsSaga({ payload }: GetSystemLogsRequestAction) {
  try {
    const { data } = yield call(getSystemLogs, payload);
    yield put(getSystemLogsSuccess(data as GetSystemLogsResponse));
  } catch (e) {
    yield put(getSystemLogsFailure());
    extractErrorAndShowToast(e);
  }
}

function* serviceLogsSaga() {
  yield takeLatest(SystemLogsTypesEnum.GET_SYSTEM_LOGS_REQUEST, getSystemLogsSaga);
}

export default serviceLogsSaga;
