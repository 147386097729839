export const INTERNAL_OPTION = { cloud_name: 'Redis Resources', id: 0 } as CloudAccount;
export const DEFAULT_AWS_AA_SELECTED_REGIONS = ['us-east-1', 'eu-west-1'];
export const DEFAULT_GCP_AA_SELECTED_REGIONS = ['us-central1', 'europe-west1'];
export const DEFAULT_READS_THROUGHPUT = 1000;
export const DEFAULT_WRITES_THROUGHPUT = 1000;
export const DEFAULT_SHARDS = 1;
export const US_CENTRAL_1 = 'us-central1';
export const INVALID_CIDR = '10.256.0.0';
export const INVALID_SHORT_CIDR = '192.168.0';
export const INVALID_LONG_CIDR = '192.168.0.0';
export const VALID_PUBLIC_CIDR = '193.168.0.0';
export const VALID_PUBLIC_CIDR2 = '172.0.0.0';
export const VALID_PUBLIC_CIDR3 = '1.0.0.0';
export const VALID_PRIVATE_CIDR = '192.168.0.0';
export const VALID_PRIVATE_CIDR2 = '192.168.2.0';
export const VALID_PRIVATE_CIDR3 = '192.168.3.0';
