import {
  CreditsTypesEnum,
  GetCouponsRequestAction,
  GetCouponsSuccessAction,
  GetCouponsFailureAction,
  CouponsSuccessAnswer,
  ApplyCouponRequestAction,
  ApplyRetentionCouponRequestAction
} from './credits.types';

export const getCouponsRequest = (): GetCouponsRequestAction => ({
  type: CreditsTypesEnum.GET_COUPONS_REQUEST
});

export const getCouponsSuccess = (payload: CouponsSuccessAnswer): GetCouponsSuccessAction => ({
  type: CreditsTypesEnum.GET_COUPONS_SUCCESS,
  payload
});

export const getCouponsFailure = (error: string): GetCouponsFailureAction => ({
  type: CreditsTypesEnum.GET_COUPONS_FAILURE,
  error
});

export const applyCouponRequest = (couponCode: string): ApplyCouponRequestAction => ({
  type: CreditsTypesEnum.APPLY_COUPON_REQUEST,
  payload: couponCode
});

export const applyRetentionCouponRequest = (
  couponCode: string
): ApplyRetentionCouponRequestAction => ({
  type: CreditsTypesEnum.APPLY_RETENTION_COUPON_REQUEST,
  payload: couponCode
});
