import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../../utils/constants/routes';
import * as S from './UserFormInfo.style';
import { i18translation } from '../../../../locale/translations/i18NsPaths';
import { FormSuccessCallbackProps } from './UserFormInfo.types';

const UserFormInfo = ({
  title,
  text,
  subTitle,
  showBackToLoginButton = true,
  children
}: FormSuccessCallbackProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen;

  return (
    <S.UserFormInfoContainer>
      <S.UserFormInfoTitle>{title}</S.UserFormInfoTitle>
      <S.UserFormInfoSubTitle>{subTitle}</S.UserFormInfoSubTitle>
      <S.UserFormInfoText>{text}</S.UserFormInfoText>
      {showBackToLoginButton && (
        <S.ButtonContainer
          data-testid="btn-submit-login"
          size="large"
          onClick={() => history.push(routes.login)}
        >
          {t(keyPrefix.backToLoginBtn)}
        </S.ButtonContainer>
      )}
      {children}
    </S.UserFormInfoContainer>
  );
};

export default UserFormInfo;
