import { UseQueryOptions } from '@tanstack/react-query';
import { Account } from 'store/account/account.types';
import useSmQuery from '../useSmQuery';
import { accountsApi } from '../../services/api/resources/accounts/accounts.resource';
import accountKeys from './accounts.keys';

interface UseAccountsQueryProps {
  params?: GetAccountsParams;
  options?: UseQueryOptions<Account[]>;
}

const useAccountsQuery = ({ params, options }: UseAccountsQueryProps = {}) => {
  const getAccounts = async () => {
    const { data } = await accountsApi.getAll(params);

    return data.accounts;
  };

  return useSmQuery({
    permissionKey: 'account_get_accounts',
    queryKey: accountKeys.getAll(params),
    queryFn: getAccounts,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};

const useZendeskLinkQuery = (options: UseQueryOptions<{ url: string }> = {}) => {
  const queryFn = async () => {
    const { data } = await accountsApi.getZendeskLink();

    return { url: data?.url };
  };

  return useSmQuery({
    permissionKey: false,
    queryKey: accountKeys.getZendeskLink,
    queryFn,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...options
  });
};

export const accountsQueries = {
  useAccounts: useAccountsQuery,
  useZendeskLink: useZendeskLinkQuery
};
