import {
  ClusterRolesTypesEnum,
  ClusterRolesState,
  ClusterRolesActions
} from './clusterRoles.types';

const initialState = { status: 'idle', data: [], error: '' } as ClusterRolesState;

const clusterRolesReducer = (
  state = initialState,
  action: ClusterRolesActions
): typeof initialState => {
  switch (action.type) {
    case ClusterRolesTypesEnum.CREATE_CLUSTER_ROLE_SUCCESS: {
      const updatedClusterRoles = [...state.data, action.payload];

      return {
        ...state,
        data: updatedClusterRoles
      };
    }

    case ClusterRolesTypesEnum.UPDATE_CLUSTER_ROLE_REQUEST: {
      return {
        ...state,
        data: state.data.map((role) => {
          if (role.id === action.payload.clusterRole.id) {
            return {
              ...role,
              ...action.payload,
              serverStatus: 'pending'
            };
          }

          return role;
        })
      };
    }
    case ClusterRolesTypesEnum.UPDATE_CLUSTER_ROLE_SUCCESS: {
      return {
        ...state,
        data: state.data.map((role) => {
          if (role.id === action.payload.id) {
            return {
              ...role,
              ...action.payload,
              serverStatus: 'resolved'
            };
          }

          return role;
        })
      };
    }

    default:
      return state;
  }
};

export default clusterRolesReducer;
