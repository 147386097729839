import {
  AccessPermissions,
  ApiAuthResponse,
  ApiAuthResponseNormalizedPermissions,
  AuthActionTypesEnum,
  FilterPermissions,
  LoginPayload,
  LoginPayloadWithCredentials,
  LoginRequestAction,
  LoginSuccessAction,
  LogoutActionRequest,
  LogoutSuccessAction,
  MakeCsrfCallAction,
  MfaLoginAction,
  MfaPayload,
  PermissionsDict,
  SessionExpiredAction,
  SetCurrentUserAction,
  SetLoginErrorCodeAction,
  UpdatePermissions,
  UpdateUserNameAction
} from './auth.types';

export const login = (data: LoginPayload): LoginRequestAction => ({
  type: AuthActionTypesEnum.LOGIN_REQUEST,
  payload: data
});

export const loginSuccess = (data: ApiAuthResponseNormalizedPermissions): LoginSuccessAction => ({
  type: AuthActionTypesEnum.LOGIN_SUCCESS,
  payload: data
});

export const setCurrentUser = (data: ApiAuthResponse): SetCurrentUserAction => ({
  type: AuthActionTypesEnum.SET_CURRENT_USER,
  payload: data
});

export const setLoginErrorCode = (errorCode: number): SetLoginErrorCodeAction => ({
  type: AuthActionTypesEnum.SET_LOGIN_ERROR_CODE,
  payload: errorCode
});

export const makeCsrfCall = (data: ApiAuthResponseNormalizedPermissions): MakeCsrfCallAction => ({
  type: AuthActionTypesEnum.MAKE_CSRF_CALL,
  payload: data
});

export const logout = (data): LogoutActionRequest => ({
  type: AuthActionTypesEnum.LOGOUT_REQUEST,
  payload: data
});

export const logoutSuccess = (): LogoutSuccessAction => ({
  type: AuthActionTypesEnum.LOGOUT_SUCCESS
});

export const sessionExpired = (): SessionExpiredAction => ({
  type: AuthActionTypesEnum.SESSION_EXPIRED
});

export const updateUserName = (name: string): UpdateUserNameAction => ({
  type: AuthActionTypesEnum.UPDATE_USER_NAME,
  payload: name
});

export const filterPermissions = (
  filter: (permissions: PermissionsDict) => PermissionsDict
): FilterPermissions => ({
  type: AuthActionTypesEnum.FILTER_PERMISSIONS,
  payload: filter
});

export const updatePermissions = (permissions: AccessPermissions[]): UpdatePermissions => ({
  type: AuthActionTypesEnum.UPDATE_PERMISSIONS,
  payload: permissions
});

export const mfaLogin = (payload: MfaPayload & LoginPayloadWithCredentials): MfaLoginAction => ({
  type: AuthActionTypesEnum.MFA_LOGIN,
  payload
});
