import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import { Input, Button, Modal, TextButton } from '@redislabsdev/redis-ui-components';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import * as S from './AppAutFactorForm.style';
import { mfaPayloadSelector } from '../../../../../../store/auth/auth.selectors';
import useFactorChange from '../../hooks/useFactorChange';
import useMfaForm from '../../hooks/useMfaForm';
import useLogOut from '../../../../../../hooks/useLogOut';

interface Props {
  switchToSmsModal: () => void;
}

const AppAuthFactorForm = ({ switchToSmsModal }: Props) => {
  const { t } = useTranslation();
  const { smsFactorAvailable } = useSelector(mfaPayloadSelector);
  const status = useFactorChange('Totp');
  const keyPrefix = i18translation.LoginScreen.mfaLogin.authAppModal;
  const { handleAuthFactorSubmit } = useMfaForm();
  const handleSubmit = ({ authCode }: { authCode: string }) => {
    handleAuthFactorSubmit('Totp', authCode);
  };
  const { performLogout } = useLogOut();

  return (
    <S.Container>
      <Modal.Content.Header title={t(keyPrefix.title)} />
      <Modal.Content.Body.Compose width="40rem">
        <Formik initialValues={{ authCode: '' }} onSubmit={handleSubmit}>
          {({ handleChange, dirty }) => (
            <Form>
              <S.InputHint>{t(keyPrefix.codeIsRequired)}</S.InputHint>
              <Input id="auth-app-code" name="authCode" onChange={handleChange('authCode')} />
              <S.ModalContentFooterCompose>
                {smsFactorAvailable && (
                  <TextButton data-testid="button--text-me-code" onClick={switchToSmsModal}>
                    {t(keyPrefix.textMeCode)}
                  </TextButton>
                )}
                <S.ActionButtons>
                  <Button variant="secondary-ghost" onClick={() => performLogout()}>
                    {t('LoginScreen.mfaLogin.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    disabled={!dirty || (smsFactorAvailable && status !== 'success')}
                  >
                    {t('LoginScreen.mfaLogin.verify')}
                  </Button>
                </S.ActionButtons>
              </S.ModalContentFooterCompose>
            </Form>
          )}
        </Formik>
      </Modal.Content.Body.Compose>
    </S.Container>
  );
};

export default AppAuthFactorForm;
