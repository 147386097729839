import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { i18translation } from '../../../locale/translations/i18NsPaths';
import { showToast } from '../../../components/Toast/showToast';
import { getQueryParam } from '../../../utils/queryParam/getQueryParam';
import { deleteQueryParam } from '../../../utils/queryParam/deleteQueryParam';

const useAwsMarketPlaceQueryParamsBoundCorrectlyMessage = (isResolved: boolean) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.awsMarketPlace.messages;
  const infoParam = getQueryParam('info');
  const accountParam = getQueryParam('account');
  const accountsParam = getQueryParam('accounts');

  useEffect(() => {
    if (!infoParam || !isResolved) {
      return;
    }
    if (infoParam === 'marketplace-account-bound-correctly' && (accountParam || accountsParam)) {
      if (accountParam) {
        showToast(
          t(keyPrefix.accountBoundCorrectly, {
            account: accountParam
          }),
          'success'
        );
      } else {
        showToast(
          t(keyPrefix.accountsBoundCorrectly, {
            accounts: accountsParam
          }),
          'success'
        );
      }
      deleteQueryParam('info');
    }
  }, [isResolved]);
};

export default useAwsMarketPlaceQueryParamsBoundCorrectlyMessage;
