const HOURS_IN_DAY = 24;
const DAYS_IN_MONTH = 30.416666667;

const convertMonthToHours = (monthlyPrice: number) => {
  if (monthlyPrice >= 0) {
    return parseFloat((monthlyPrice / DAYS_IN_MONTH / HOURS_IN_DAY).toFixed(3));
  }

  return 0;
};

export default convertMonthToHours;
