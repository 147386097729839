import { AxiosResponse } from 'axios';
import { okStatus } from 'utils/constants/api/statuses';

interface Response extends Pick<AxiosResponse, 'status' | 'headers'> {}

const shouldTriggerCaptcha = (response: Response) => {
  return response.headers['content-type'] === 'text/html' && response.status === okStatus;
};

export default shouldTriggerCaptcha;
