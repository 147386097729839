import styled from 'styled-components/macro';
import { Loader as LoaderComponent } from '@redislabsdev/redis-ui-components';

export const FormContainer = styled.div`
  background-color: ${({ theme }) => theme.semantic.color.background.neutral100};
  display: flex;
  justify-content: center;
  margin: 4rem 3.6rem 4rem 0;
  border-radius: 1.6rem;
  width: 68rem;
  min-height: 64rem;
  min-width: 42rem;
  box-shadow: 0px 0px 0px 1px #0000000f;
`;

export const Loader = styled(LoaderComponent)`
  height: 100vh;
`;

export const LoginLoaderWrapper = styled(Loader)`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 90%);
  display: flex;
  flex: 1;
  justify-content: center;
  position: absolute;
  z-index: 1;
  border-radius: 0.8rem;
`;
