export const IMPORTING_DB_TO_BDB_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/import-data/';

export const MIGRATE_DATA_TO_NEW_SUBSCRIPTION =
  'https://redis.io/docs/latest/operate/rc/databases/migrate-databases/';

export const ACL_DOCS_LINK =
  'https://redis.io/docs/latest/operate/rc/security/access-control/data-access-control/configure-acls/';

export const AUTO_TIERING_LINK = 'https://redis.io/docs/latest/operate/rs/databases/auto-tiering/';

export const ACTIVE_ACTIVE_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/create-database/create-active-active-database/';

export const ACTIVE_ACTIVE_SELECT_CAPABILITIES_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/create-database/create-active-active-database/#select-capabilities';

export const HIGH_AVAILABILITY_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/configuration/high-availability/';

export const MULTI_ZONE_AVAILABILITY_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/configuration/high-availability/';

export const CLUSTER_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/configuration/clustering#oss-cluster-api';

export const DATA_PERSISTENCE_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/configuration/data-persistence/';

export const DATA_EVICTION_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/configuration/data-eviction-policies/?s=data%20eviction';

export const ACTIVE_PASSIVE_REDIS_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/migrate-databases/#sync-using-active-passive';

export const DEFAULT_USER_LINK =
  'https://redis.io/docs/latest/operate/rc/security/access-control/data-access-control/default-user/';

export const ALLOW_LIST_LINK =
  'https://redis.io/docs/latest/operate/rc/security/database-security/network-security/';

export const TLS_LINK =
  'https://redis.io/docs/latest/operate/rc/security/database-security/tls-ssl/';

export const X_509_LINK =
  'https://redis.io/docs/latest/operate/rc/security/database-security/tls-ssl/#enable-tls';

export const DB_BACKUP_LINK = 'https://redis.io/docs/latest/operate/rc/databases/back-up-data/';

export const MODULES_LINK =
  'https://redis.io/docs/latest/operate/oss_and_stack/stack-with-enterprise/';

export const HASHING_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/configuration/clustering/#custom-hashing-policy';

export const ITEM_SIZE_LINK = 'https://redis.io/docs/latest/operate/rs/databases/auto-tiering/';

export const CLOUD_ACCOUNT_LINK =
  'https://redis.io/docs/latest/operate/rc/cloud-integrations/aws-cloud-accounts/';

export const SASL_AUTH_LINK = 'https://redis.io/docs/latest/operate/rs/security/';

export const ENFORCE_MFA =
  'https://redis.io/docs/latest/operate/rc/security/access-control/multi-factor-authentication/#enforcing';

export const API_KEYS_LINK = 'https://redis.io/docs/latest/operate/rc/api/';

export const DATABASE_CLUSTERING_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/configuration/clustering/';

export const REDISEARCH_SIZING_CALCULATOR_LINK =
  'https://redis.com/modules/redis-search/redisearch-sizing-calculator/';

export const MEMORY_LIMIT_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/configuration/clustering/#dataset-size';

export const NETWORK_USAGE_LINK =
  'https://redis.io/docs/latest/operate/rc/subscriptions/view-essentials-subscription/essentials-plan-details/';

export const CREATE_DB_MODULES_OPTIONS_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/configuration/advanced-capabilities/#pro';

export const SUPPORT_LINK = 'https://redis.com/company/support/';

export const CREATE_DATABASE_SCALABILITY_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/create-database/create-pro-database-existing/#scalability-section';

export const CLOUD_ACCOUNT_INFO_LINK =
  'https://redis.io/docs/latest/operate/rc/cloud-integrations/aws-cloud-accounts/';

export const GCP_CONSOLE_PSC_LINK = 'https://console.cloud.google.com/networking/networks/';

export const SAML_LINK =
  'https://redis.io/docs/latest/operate/rc/security/access-control/saml-sso/';

export const SAML_LINK_ACCOUNTS =
  'https://redis.io/docs/latest/operate/rc/security/access-control/saml-sso/#link-other-accounts';

export const SAML_LINK_SETUP_APP =
  'https://redis.io/docs/latest/operate/rc/security/access-control/saml-sso/#set-up-app';

export const REDIS_PRIVATE_SERVICE_CONNECT_LINK =
  'https://redis.io/docs/latest/operate/rc/security/private-service-connect/';

export const PRIVATE_SERVICE_CONNECT_GCLOUD_LINK = 'https://cloud.google.com/sdk/docs/install';

export const TERMS_LINK = 'https://redis.com/legal/cloud-tos/';
export const PRIVACY_LINK = 'https://redis.com/legal/privacy-policy/';

export const VPC_PEERING_LINK = 'https://redis.io/docs/latest/operate/rc/security/vpc-peering/';

export const REDIS_CLI_LINK = 'https://redis.io/docs/latest/operate/oss_and_stack/install/';

export const REDIS_INSIGHT_LINK = 'https://redis.com/redis-enterprise/redis-insight/';

export const REDIS_7_PREVIEW_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/create-database/create-essentials-database/';

export const REDIS_SEARCH_VERTICAL_SCALING_LINK =
  'https://redis.io/docs/latest/operate/rc/changelog/july-2024';

export const REDIS_72_PREVIEW_LINK = 'https://redis.io/docs/latest/operate/rc/changelog/';

export const REDIS_7_ACL_RULES_LINK =
  'https://redis.io/docs/latest/operate/rc/security/access-control/data-access-control/configure-acls/#pubsub-acl-rules';

export const ACL_RULES_LINK =
  'https://redis.io/docs/latest/operate/oss_and_stack/management/security/acl/#acl-rules';

export const COMMUNITY_LINK = 'https://discord.com/invite/redis';
export const UNIVERSITY_LINK = 'https://university.redis.com/';

export const SETUP_COMPLETENESS_MONITOR_PERFORMANCE =
  'https://redis.io/docs/latest/integrate/prometheus-with-redis-cloud/';
export const SETUP_COMPLETENESS_SECURE_YOUR_DATABASE =
  'https://redis.io/docs/latest/operate/rc/security/database-security/';
export const SETUP_COMPLETENESS_UNLOCK_THE_POWER_OF_YOUR_DATA_WITH_SEARCH_QUERY =
  'https://redis.com/modules/redis-search/';
export const SETUP_COMPLETENESS_VSS_GUIDE =
  'https://redis.io/docs/latest/develop/get-started/vector-database/';
export const SETUP_COMPLETENESS_VSS_LIBRARY = 'https://github.com/RedisVentures/redisvl';
export const SETUP_COMPLETENESS_VSS_CHEATSHEET =
  'https://redis.com/wp-content/uploads/2023/04/vss-cheat-sheet.pdf';
export const SETUP_COMPLETENESS_VSS_WEBINAR =
  'https://redis.com/events-and-webinars/vector-tech-talk-for-7-2-release/';

export const MAINTENANCE_WINDOWS_LINK =
  'https://redis.io/docs/latest/operate/rc/subscriptions/maintenance/set-maintenance-windows/';

export const ESSENTIALS_MAINTENANCE_WINDOW_LINK =
  'https://redis.io/docs/latest/operate/rc/subscriptions/maintenance/';

export const ACL_SELECTORS_LINK =
  'https://redis.io/docs/latest/operate/oss_and_stack/management/security/acl/#selectors';
export const ACL_KEY_PERMISSIONS_LINK =
  'https://redis.io/docs/latest/operate/oss_and_stack/management/security/acl/#key-permissions';
export const WEBSITE_REGISTER_LINK = 'https://redis.com/try-free/';

export const AWS_RAM_LINK = 'https://aws.amazon.com/ram/';

export const AWS_CLOUD_ACCOUNT_PERMISSIONS_LINK =
  'https://docs.aws.amazon.com/IAM/latest/UserGuide/id_users_change-permissions.html';
export const REDIS_DOC_TGW_PREREQUISITES =
  'https://redis.io/docs/latest/operate/rc/security/aws-transit-gateway/#prerequisites';
export const TGW_DOC_LINK = 'https://redis.io/docs/latest/operate/rc/security/aws-transit-gateway';

export const RESP3_CHANGELOG_LINK =
  'https://redis.io/docs/latest/operate/rc/changelog/september-2023/';

export const REDIS_VERSION_DEFAULT_LINK =
  'https://redis.io/docs/latest/operate/rc/changelog/august-2023/';

export const REDIS_VERSION_PREVIEW_LINK =
  'https://redis.io/docs/latest/operate/rc/changelog/july-2024/';

export const SWAGGER_UI_LINK = 'https://api.redislabs.com/v1/swagger-ui/index.html#/';

export const TERRAFORM_LINK =
  'https://registry.terraform.io/providers/RedisLabs/rediscloud/latest/docs';

export const PULUMI_LINK = 'https://www.pulumi.com/registry/packages/rediscloud/';

export const DATABASES_THROUGHPUT_CONFIGURATION_LINK =
  'https://redis.io/docs/latest/operate/rc/databases/configuration/clustering#throughput';

export const REDIS_NODEJS_STARTER =
  'https://github.com/codespaces/new/redis-developer/redis-nodejs-starter';

export const CONTACT_SALES_URL = 'https://redis.io/meeting/';

export const redisVersionIntroDocLinks = {
  '7.2': 'https://redis.io/docs/latest/operate/rc/changelog/august-2023/',
  '7.4': 'https://redis.io/docs/latest/operate/rc/changelog/october-2024/'
};
