import styled from 'styled-components/macro';
import loginImage1 from 'assets/icons/loginBackground/Login1.png';
import loginImage2 from 'assets/icons/loginBackground/Login2.png';
import loginImage3 from 'assets/icons/loginBackground/Login3.png';
import loginImage4 from 'assets/icons/loginBackground/Login4.png';
import redisLogo from 'assets/icons/RedisLogo.svg';

export const BackgroundImageContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  scrollbar-gutter: stable;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;
  overflow: auto;
  animation: imageSwitching 32s infinite ease forwards;
  background-size: cover;
  background-position: 0% 100%;
  background-image: url(${loginImage1}), url(${loginImage2}), url(${loginImage3}),
    url(${loginImage4});

  @keyframes imageSwitching {
    0% {
      background-image: url(${loginImage1});
    }
    22% {
      background-image: url(${loginImage1});
    }
    25% {
      background-image: url(${loginImage2});
    }
    47% {
      background-image: url(${loginImage2});
    }
    50% {
      background-image: url(${loginImage3});
    }
    77% {
      background-image: url(${loginImage3});
    }
    80% {
      background-image: url(${loginImage4});
    }
    97% {
      background-image: url(${loginImage4});
    }
    100% {
      background-image: url(${loginImage1});
    }
  }
`;

export const RedisLogo = styled.div`
  width: 10.1rem;
  height: 4rem;
  margin: 3rem 0 0 3rem;
  background: url(${redisLogo}) no-repeat;
`;
