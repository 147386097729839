const usersKeys = {
  getAll: ['get-all-users'],
  getAllRoles: ['get-all-users-roles'],
  deleteUser: (userId: number) => ['delete-user', userId],
  updateUser: (userId: number) => ['update-user', userId],
  createUser: ['create-user'],
  getUser: (userId: number) => ['get-user', userId],
  getCurrentUser: ['get-current-user'],
  resetPasswordCallback: (search: string) => ['set-new-password-callback', search],
  resetPassword: ['reset-password'],
  setNewPassword: ['set-new-password'],
  activateUserInvitation: ['activate-user-invitation'],
  registrationCallback: (search: string) => ['registration-callback', search],
  resendActivationLink: ['resend-activation-link']
};

export default usersKeys;
