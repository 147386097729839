import { errorsMap } from '../constants/api/errorsMap';
import { ErrorInfo } from '../../types/interfaces';

export const extractCbError = (data): ErrorInfo | ErrorInfo[] => {
  let code;
  if (data.errors && data.errors.code) {
    code = data.errors.code;
  } else if (data.errors) {
    const errors = typeof data.errors === 'string' ? JSON.parse(data.errors) : data.errors;
    if (Array.isArray(errors) && errors[0]?.error_code) {
      code = errors.map((error) => errorsMap[error.error_code]);
    } else {
      code = 'general-error';
    }
  } else {
    code = 'general-error';
  }

  if (Array.isArray(code) && !code?.includes(undefined)) {
    return code;
  }

  return errorsMap[code] ? errorsMap[code] : errorsMap['general-error'];
};
