import { Crdb } from '../store/databases/databases.types';
import { parseFloatOneDecimal, KILOBYTE } from './constants/global';
import formatBytes from './formatBytes';
import { ExtendedDatabasesToCreate } from '../screens/CreateSubscription/SubscriptionTypes/Pro/Pro.types';

interface ExtractMemoryLimits {
  isRof: boolean;
  systemParams: SystemParams;
  databaseName: string;
}

interface ExtractUnusedMemoryLimitsProps extends ExtractMemoryLimits {
  bdbs: Bdb[];
}

interface ExtractUnusedAAMemoryLimitsProps extends ExtractMemoryLimits {
  crdbs: Crdb[];
}
interface ExtractUnusedSizingMemoryLimitsProps extends ExtractMemoryLimits {
  dbsToCreate: ExtendedDatabasesToCreate[];
  quantity: number;
}

export const getShardTypeName = (shardTypeRegionId: number, shardTypes: ShardType[]) =>
  shardTypes.find((shardType) => shardType.id === shardTypeRegionId)?.name;

export const getShardTypeMemorySize = (shardTypeRegionId: number, shardTypes: ShardType[]) =>
  shardTypes.find((shardType) => shardType.id === shardTypeRegionId)?.memory_size_gb;

export const getBdbFirstRegionFromPriceList = (
  shardTypePricingList: ShardTypePricing[],
  dbName: string
) => {
  const pricingShard = shardTypePricingList.find((shardT) => shardT.name === dbName);
  if (pricingShard?.regions?.length) {
    return pricingShard.regions[0];
  }

  return null;
};

const getRcpMemoryLimit = (isRof: boolean, systemParams: SystemParams, usedMemory: number) => {
  const minMemoryLimit = parseFloatOneDecimal(systemParams.min_bdb_memory_limit_mb / KILOBYTE);

  const maxMemoryLimit = isRof
    ? systemParams.rcp_max_total_memory_size_gb_rof - usedMemory
    : systemParams.rcp_max_total_memory_size_gb - usedMemory;

  return {
    minMemoryLimit,
    maxMemoryLimit
  };
};

export const extractRcpUnusedMemoryLimits = ({
  isRof,
  systemParams,
  bdbs = [],
  databaseName
}: ExtractUnusedMemoryLimitsProps) => {
  const usedMemorySpace = bdbs.reduce((acc, cur) => {
    if (databaseName && cur.name === databaseName) {
      return acc;
    }

    return cur.size + acc;
  }, 0);

  const usedMemorySpaceInGb = Number(
    formatBytes({
      bytes: usedMemorySpace,
      decimals: 1,
      withoutSuffix: true
    })
  );

  return getRcpMemoryLimit(isRof, systemParams, usedMemorySpaceInGb);
};

export const extractUnusedAAMemoryLimits = ({
  isRof,
  systemParams,
  crdbs = [],
  databaseName
}: ExtractUnusedAAMemoryLimitsProps) => {
  const usedMemorySpace = crdbs.reduce((acc, cur) => {
    if (databaseName && cur.name === databaseName) {
      return acc;
    }

    return cur.memory_size_in_mb / 1024 + acc;
  }, 0);

  return getRcpMemoryLimit(isRof, systemParams, usedMemorySpace);
};

export const extractUnusedSizingMemoryLimits = ({
  isRof,
  systemParams,
  dbsToCreate = [],
  databaseName,
  quantity
}: ExtractUnusedSizingMemoryLimitsProps) => {
  const usedMemorySpace = dbsToCreate.reduce((acc, cur) => {
    if (databaseName && cur.name === databaseName) {
      return acc;
    }

    return cur.memory_size * cur.quantity + acc;
  }, 0);

  const usedMemorySpaceInGb = Number(
    formatBytes({
      bytes: usedMemorySpace,
      decimals: 1,
      withoutSuffix: true,
      unit: 'GB'
    })
  );

  const { minMemoryLimit, maxMemoryLimit } = getRcpMemoryLimit(
    isRof,
    systemParams,
    usedMemorySpaceInGb
  );

  return {
    minMemoryLimit,
    maxMemoryLimit:
      quantity > 1 ? Math.round((maxMemoryLimit / quantity) * 10) / 10 : maxMemoryLimit
  };
};
