import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getQueryParam } from '../../../utils/queryParam/getQueryParam';
import { extractError } from '../../../utils/helpers/extractError';
import { showToast } from '../../../components/Toast/showToast';

const useAwsMarketPlaceQueryParamsErrorMessage = () => {
  const { t } = useTranslation();
  const errorParam = getQueryParam('error');

  useEffect(() => {
    if (errorParam) {
      const error = extractError({ errors: { code: errorParam } });
      showToast(t(error.message));
    }
  }, []);
};

export default useAwsMarketPlaceQueryParamsErrorMessage;
