import { NewCustomerNewDatabaseMFE } from 'components/DatabasesMFE/NewCustomerNewDatabaseMFE';
import { useNewCustomerLoginStore } from './store/useNewCustomerLoginStore';

const NewCustomer = () => {
  const { setIsFirstLogin } = useNewCustomerLoginStore();

  const handleOnClickEssentialsSeeMorePlans = () => {
    setIsFirstLogin(false);
  };

  const handleOnClickEssentialsGetStarted = () => {
    setIsFirstLogin(false);
  };

  const handleOnClickProSeeMorePlans = () => {
    setIsFirstLogin(false);
  };

  const handleOnClickProStartWithCredit = () => {
    setIsFirstLogin(false);
  };

  return (
    <NewCustomerNewDatabaseMFE
      onClickEssentialsSeeMorePlans={handleOnClickEssentialsSeeMorePlans}
      onClickEssentialsGetStarted={handleOnClickEssentialsGetStarted}
      onClickProSeeMorePlans={handleOnClickProSeeMorePlans}
      onClickProStartWithCredit={handleOnClickProStartWithCredit}
    />
  );
};

export default NewCustomer;
