import { ConfirmationDialogContextValue } from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';

export const confirmationDialogContextValue: { values: ConfirmationDialogContextValue | null } = {
  values: null
};

export const setConfirmationDialogContextValue = (
  confDialogContextValue: ConfirmationDialogContextValue
) => {
  confirmationDialogContextValue.values = confDialogContextValue;
};
