import { ToastActionType } from '@redislabsdev/redis-ui-components';
import { ExtendedAxiosError } from '../../types/interfaces';
import { unauthorizedStatus } from '../constants/api/statuses';
import { handleErrorDisplay } from './extractError';

export const extractErrorAndShowToast = (
  e: ExtendedAxiosError,
  primaryAction?: ToastActionType
): string => {
  let errMessage = '';
  if (e?.response) {
    if (e.response.status !== unauthorizedStatus) {
      errMessage = handleErrorDisplay(e, primaryAction);
    }
  }

  return errMessage;
};
