import { UseQueryOptions } from '@tanstack/react-query';
import { csrfKeys } from './csrf.keys';
import useSmQuery from '../useSmQuery';
import { csrfApi } from '../../services/api/resources/csrf/csrf.resource';

const useCsrfQuery = (queryOptions: UseQueryOptions<CSRFTokenModel> = {}) => {
  const getCsrfToken = async () => {
    const { data } = await csrfApi.getAll();

    return {
      ...data.csrfToken
    };
  };

  return useSmQuery({
    permissionKey: false,
    queryKey: csrfKeys.getAll,
    queryFn: getCsrfToken,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...queryOptions
  });
};

export const csrfQueries = {
  useCsrf: useCsrfQuery
};
