import {
  GetSystemLogsSuccessAction,
  GetSystemLogsResponse,
  GetSystemLogsFailureAction,
  SystemLogsTypesEnum,
  GetSystemLogsRequestAction,
  GetSystemLogsPayload
} from './systemLogs.types';

export const getSystemLogsRequest = (
  payload: GetSystemLogsPayload
): GetSystemLogsRequestAction => ({
  type: SystemLogsTypesEnum.GET_SYSTEM_LOGS_REQUEST,
  payload
});

export const getSystemLogsSuccess = (
  payload: GetSystemLogsResponse
): GetSystemLogsSuccessAction => ({
  type: SystemLogsTypesEnum.GET_SYSTEM_LOGS_SUCCESS,
  payload
});

export const getSystemLogsFailure = (): GetSystemLogsFailureAction => ({
  type: SystemLogsTypesEnum.GET_SYSTEM_LOGS_FAILURE
});
