import styled from 'styled-components/macro';

export const CaptchaContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none;

  :has(iframe) {
    display: block;
  }
`;
