import styled from 'styled-components/macro';
import redisLogo from 'assets/icons/RedisLogo.svg';
import { ReactComponent as Maintenance } from 'assets/icons/Maintenance.svg';

export const Container = styled.div`
  height: 100vh;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const RedisLogo = styled.div`
  position: absolute;
  width: 10.1rem;
  height: 4rem;
  margin: 3rem 0 0 3rem;
  background: url(${redisLogo}) no-repeat;
`;

export const MaintenanceIcon = styled(Maintenance)`
  margin: 3rem 0;
`;
