import { getNewRequiredCloudResources } from '../../../screens/SubscriptionsAndDatabases/components/ViewDB/Configuration/components/NewSubsPriceDialog/components/NewSubsPriceDialogContent/utils/getNewRequiredCloudResources';

const getAdditionalResources = (planStateResponse: RcpPlanStatusResponse, currentRcp: SubsRcp) => {
  const currentClusterNodes = currentRcp.nodes;
  const updatedClusterNodes = planStateResponse.nodes;

  const additionalNodes = getNewRequiredCloudResources(updatedClusterNodes, currentClusterNodes);
  const diskPrice = planStateResponse.pricing.storage_price - currentRcp.monthly_storage_price;

  const additionalNodesPrice = additionalNodes.reduce((acc, curr) => acc + curr.usd_per_month, 0);

  return { additionalNodes, diskPrice, additionalNodesPrice };
};

const getAdditionalPrice = ({
  planStateResponse,
  updatedClusterShardsCount,
  currentClusterShardsCount
}) => {
  const additionalShardsRequired = updatedClusterShardsCount - currentClusterShardsCount;
  const pricePerShard = planStateResponse.pricing.databases_price / updatedClusterShardsCount;
  const additionalShardsPrice = pricePerShard * additionalShardsRequired;

  return { additionalShardsRequired, additionalShardsPrice };
};

const getUpdatedRcpPriceDetails = (
  planStateResponse: RcpPlanStatusResponse,
  currentRcp: SubsRcp
) => {
  const isReserved = currentRcp.rcp_type === 'reserved';
  const updatedClusterShardsCount = planStateResponse.databases.reduce(
    (acc, curr) => acc + curr.shards_count,
    0
  );

  let currentClusterShardsCount = currentRcp.cluster.shards_count;

  const { additionalNodes, diskPrice, additionalNodesPrice } = getAdditionalResources(
    planStateResponse,
    currentRcp
  );

  if (isReserved) {
    currentClusterShardsCount = Math.max(
      planStateResponse.max_shards_count,
      currentClusterShardsCount
    );

    const isShardsAdded = Math.max(0, updatedClusterShardsCount - currentClusterShardsCount) > 0;

    if (!isShardsAdded) {
      return {
        additionalNodes,
        diskPrice,
        additionalShardsRequired: 0,
        additionalShardsPrice: 0,
        totalAdditionalPrice: 0
      };
    }
  }

  const { additionalShardsRequired, additionalShardsPrice } = getAdditionalPrice({
    currentClusterShardsCount,
    planStateResponse,
    updatedClusterShardsCount
  });

  const totalAdditionalPrice =
    currentRcp.cloud_account.ownership === 'external'
      ? planStateResponse.pricing.databases_price - currentRcp.monthly_databases_price
      : additionalShardsPrice + additionalNodesPrice + diskPrice;

  return {
    additionalShardsRequired,
    additionalShardsPrice,
    additionalNodes,
    diskPrice,
    totalAdditionalPrice
  };
};

export default getUpdatedRcpPriceDetails;
