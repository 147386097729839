import { useTranslation } from 'react-i18next';
import * as S from './OrSeperator.style';
import { i18translation } from '../../../../locale/translations/i18NsPaths';

const OrSeparator = () => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen;

  return (
    <S.OrText>
      <hr />
      {t(keyPrefix.or)}
      <hr />
    </S.OrText>
  );
};
export default OrSeparator;
