import { ApiPaths } from 'services/api/apiPaths';
import RedisApiAxiosInstance from '../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';

export const getCloudAccounts = () => RedisApiAxiosInstance.get(ApiPaths.CLOUD_ACCOUNTS);

export const validateCloudAccount = (payload: CloudAccount) =>
  RedisApiAxiosInstance.post(`${ApiPaths.CLOUD_ACCOUNTS}/actions/validate_credentials/`, {
    cloudAccount: payload
  });

export const getCloudAccount = (id: number) =>
  RedisApiAxiosInstance.get(`${ApiPaths.CLOUD_ACCOUNTS}/${id}`);

export const postCloudAccount = (payload: CloudAccount) =>
  RedisApiAxiosInstance.post(ApiPaths.CLOUD_ACCOUNTS, { cloudAccount: payload });

export const updateCloudAccount = (payload: CloudAccount) => {
  return RedisApiAxiosInstance.put(`${ApiPaths.CLOUD_ACCOUNTS}/${payload.id}`, {
    cloudAccount: payload
  });
};

export const deleteCloudAccount = (id: number) =>
  RedisApiAxiosInstance.delete(`${ApiPaths.CLOUD_ACCOUNTS}/${id}`);
