import { Crdb } from '../../store/databases/databases.types';

const getIsAaRcpSubscriptionCreationPending = (crdb: Crdb): boolean => {
  return (
    crdb?.status === 'pending' &&
    crdb?.crdb_instances?.some((instance) => instance?.cluster?.id === null)
  );
};

export default getIsAaRcpSubscriptionCreationPending;
