import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TOptions } from 'i18next';

const usePrefixedTranslation = (customPrefix: string) => {
  const { t, i18n } = useTranslation();
  const prefixedT = useCallback(
    (path: string, options?: TOptions): string => {
      return t(`${customPrefix}.${path}`, options);
    },
    [t, customPrefix]
  );

  return { t, prefixedT, i18n };
};

export default usePrefixedTranslation;
