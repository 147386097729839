import { isEmpty } from 'lodash';
import { Action, Middleware } from 'redux';
import { PROTECTED_SAGAS } from '../../components/PermissionsProtectors/utils/permissions.constants';
import { permissionsSelector } from '../auth/auth.selectors';

export const rbacMiddleware: Middleware<{}, RootState> = ({ getState }) => {
  return (next) => (action: Action) => {
    const requiredPermission = PROTECTED_SAGAS[action?.type];

    if (requiredPermission) {
      const permissions = permissionsSelector(getState());
      if (!isEmpty(permissions) && !permissions[requiredPermission.permission]) {
        const returnValue = next(requiredPermission.action);

        return returnValue;
      }
    }

    const returnValue = next(action);

    return returnValue;
  };
};
