import isString from 'lodash/isString';
import { LOADING_CHUNK_MESSAGES } from '../constants/sentryErrorMessages.constants';

const isChunkErrorMessage = (errorMessage: string) => {
  if (isString(errorMessage)) {
    for (let i = 0; i < LOADING_CHUNK_MESSAGES.length; i++) {
      const chunkMessage = LOADING_CHUNK_MESSAGES[i];
      if (errorMessage.toLowerCase().includes(chunkMessage.toLowerCase())) {
        return true;
      }
    }

    return false;
  }

  return false;
};

export default isChunkErrorMessage;
