import { getAccountStateSelector } from '../account/account.selectors';
import { userRoleSelector } from '../auth/auth.selectors';

const mfaReducerSelector = (state: RootState) => state.mfa;
export const mfaSelector = (state: RootState) => mfaReducerSelector(state);

export const isEnforcedMfaRoleSelector = (state: RootState) =>
  userRoleSelector(state) === 'mfa_enforced';
export const hasEnforcedMfaSelector = (state: RootState) =>
  getAccountStateSelector(state).data?.has_mfa_enforcement;
