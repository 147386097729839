import {
  MfaActionTypesEnum,
  MfaData,
  MfaDisableAction,
  MfaEnableAction,
  MfaDisableFailureAction,
  MfaDisablePayload,
  MfaEnablePayload,
  MfaEnableFailureAction,
  MfaGetInfoAction,
  MfaGetInfoFailureAction,
  MfaGetInfoSuccessAction,
  MfaSetError,
  MfaSmsSetupAction,
  MfaSmsSetupPayload,
  MfaSmsVerifyAction,
  MfaSMSVerifyPayload,
  MfaSmsVerifySuccessAction,
  MfaSmsDisableAction,
  MfaSmsDisablePayload,
  MfaSmsDisableSuccessAction,
  MfaTotpDisableAction,
  MfaTotpDisablePayload,
  MfaTotpDisableSuccessAction,
  MfaTotpVerifyAction,
  MfaTOTPVerifyPayload,
  MfaTotpVerifySuccessAction
} from './mfa.types';

export const mfaGetInfo = (): MfaGetInfoAction => ({
  type: MfaActionTypesEnum.MFA_INFO_REQUEST
});

export const mfaGetInfoSuccess = (data: MfaData): MfaGetInfoSuccessAction => ({
  type: MfaActionTypesEnum.MFA_INFO_SUCCESS,
  payload: data
});

export const mfaGetInfoFailure = (error: string): MfaGetInfoFailureAction => ({
  type: MfaActionTypesEnum.MFA_INFO_FAILURE,
  payload: error
});

export const mfaEnable = (data: MfaEnablePayload): MfaEnableAction => ({
  type: MfaActionTypesEnum.MFA_ENABLE_REQUEST,
  payload: data
});

export const mfaEnableFailure = (error: string): MfaEnableFailureAction => ({
  type: MfaActionTypesEnum.MFA_ENABLE_FAILURE,
  payload: error
});

export const mfaDisable = (data: MfaDisablePayload): MfaDisableAction => ({
  type: MfaActionTypesEnum.MFA_DISABLE_REQUEST,
  payload: data
});

export const mfaDisableFailure = (error: string): MfaDisableFailureAction => ({
  type: MfaActionTypesEnum.MFA_DISABLE_FAILURE,
  payload: error
});

export const mfaSmsSetup = (payload: MfaSmsSetupPayload): MfaSmsSetupAction => ({
  type: MfaActionTypesEnum.MFA_SMS_SETUP_REQUEST,
  payload
});

export const mfaSmsVerify = (data: MfaSMSVerifyPayload): MfaSmsVerifyAction => ({
  type: MfaActionTypesEnum.MFA_SMS_VERIFY_REQUEST,
  payload: data
});

export const mfaSmsVerifySuccess = (phoneNumber: string): MfaSmsVerifySuccessAction => ({
  type: MfaActionTypesEnum.MFA_SMS_VERIFY_SUCCESS,
  payload: phoneNumber
});

export const mfaSmsDisable = (payload: MfaSmsDisablePayload): MfaSmsDisableAction => ({
  type: MfaActionTypesEnum.MFA_SMS_DISABLE_REQUEST,
  payload
});

export const mfaSmsDisableSuccess = (): MfaSmsDisableSuccessAction => ({
  type: MfaActionTypesEnum.MFA_SMS_DISABLE_SUCCESS
});
export const mfaTotpVerify = (data: MfaTOTPVerifyPayload): MfaTotpVerifyAction => ({
  type: MfaActionTypesEnum.MFA_TOTP_VERIFY_REQUEST,
  payload: data
});

export const mfaTotpVerifySuccess = (): MfaTotpVerifySuccessAction => ({
  type: MfaActionTypesEnum.MFA_TOTP_VERIFY_SUCCESS
});

export const mfaTotpDisable = (payload: MfaTotpDisablePayload): MfaTotpDisableAction => ({
  type: MfaActionTypesEnum.MFA_TOTP_DISABLE_REQUEST,
  payload
});

export const mfaTotpDisableSuccess = (): MfaTotpDisableSuccessAction => ({
  type: MfaActionTypesEnum.MFA_TOTP_DISABLE_SUCCESS
});

export const mfaSetError = (error: string = ''): MfaSetError => ({
  type: MfaActionTypesEnum.MFA_SET_ERROR,
  payload: error
});
