import {
  CloudAccountActionTypes,
  GetCloudAccountsRequestAction,
  GetCloudAccountsSuccessAction,
  GetCloudAccountsFailureAction,
  UpdateCloudAccountRequestAction,
  UpdateCloudAccountSuccessAction,
  UpdateCloudAccountFailureAction,
  DeleteCloudAccountRequestAction,
  DeleteCloudAccountSuccessAction,
  DeleteCloudAccountFailureAction,
  PostCloudAccountRequestAction,
  PostCloudAccountSuccessAction,
  PostCloudAccountFailureAction,
  UpdateCloudAccountRequestPayload
} from './cloudAccounts.types';

export const getCloudAccountsRequest = (): GetCloudAccountsRequestAction => ({
  type: CloudAccountActionTypes.GET_CLOUD_ACCOUNTS_REQUEST
});

export const getCloudAccountsSuccess = (
  payload: CloudAccount[]
): GetCloudAccountsSuccessAction => ({
  type: CloudAccountActionTypes.GET_CLOUD_ACCOUNTS_SUCCESS,
  payload
});

export const getCloudAccountsFailure = (error: string): GetCloudAccountsFailureAction => ({
  type: CloudAccountActionTypes.GET_CLOUD_ACCOUNTS_FAILURE,
  payload: error
});

export const postCloudAccountRequest = ({
  cloudAccount,
  successCb,
  errorCb
}: UpdateCloudAccountRequestPayload): PostCloudAccountRequestAction => ({
  type: CloudAccountActionTypes.POST_CLOUD_ACCOUNT_REQUEST,
  payload: {
    cloudAccount,
    successCb,
    errorCb
  }
});

export const postCloudAccountSuccess = (payload: CloudAccount): PostCloudAccountSuccessAction => ({
  type: CloudAccountActionTypes.POST_CLOUD_ACCOUNT_SUCCESS,
  payload
});

export const postCloudAccountFailure = (error: string): PostCloudAccountFailureAction => ({
  type: CloudAccountActionTypes.POST_CLOUD_ACCOUNT_FAILURE,
  payload: error
});

export const updateCloudAccountRequest = ({
  cloudAccount,
  successCb,
  errorCb
}: UpdateCloudAccountRequestPayload): UpdateCloudAccountRequestAction => ({
  type: CloudAccountActionTypes.UPDATE_CLOUD_ACCOUNT_REQUEST,
  payload: {
    cloudAccount,
    successCb,
    errorCb
  }
});

export const updateCloudAccountSuccess = (
  payload: CloudAccount
): UpdateCloudAccountSuccessAction => ({
  type: CloudAccountActionTypes.UPDATE_CLOUD_ACCOUNT_SUCCESS,
  payload
});

export const updateCloudAccountFailure = (error: string): UpdateCloudAccountFailureAction => ({
  type: CloudAccountActionTypes.UPDATE_CLOUD_ACCOUNT_FAILURE,
  payload: error
});

export const deleteCloudAccountRequest = (payload: number): DeleteCloudAccountRequestAction => ({
  type: CloudAccountActionTypes.DELETE_CLOUD_ACCOUNT_REQUEST,
  payload
});

export const deleteCloudAccountSuccess = (): DeleteCloudAccountSuccessAction => ({
  type: CloudAccountActionTypes.DELETE_CLOUD_ACCOUNT_SUCCESS
});

export const deleteCloudAccountFailure = (error: string): DeleteCloudAccountFailureAction => ({
  type: CloudAccountActionTypes.DELETE_CLOUD_ACCOUNT_FAILURE,
  payload: error
});
