import { useSelector, useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { useEffect, useRef, useReducer, useCallback } from 'react';
import { csrfQueries } from 'queryClient/csrf/csrf.queries';
import { setCSRFHeader } from 'services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { unauthorizedStatus } from 'utils/constants/api/statuses';
import {
  isLoggedInSelector,
  loginErrorCodeSelector,
  loginStatusSelector,
  callCsrfFlagSelector,
  callCsrfPayloadSelector
} from 'store/auth/auth.selectors';
import { parametersQueries } from 'queryClient/parameters/parameters.queries';
import { loginSuccess } from '../../../store/auth/auth.action';

type ActionType = 'CHANGE' | null;

interface ActionPayload {
  enable?: boolean;
  waitForLogin?: boolean;
}

interface Action {
  type: ActionType;
  payload: ActionPayload;
}

const initialState: ActionPayload = {
  enable: false,
  waitForLogin: false
};

const reducer = (state: ActionPayload, action: Action) => {
  switch (action.type) {
    case 'CHANGE':
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export const useSetCsrfToken = () => {
  const paramsQuery = parametersQueries.useSystemParameters();
  const csrfProtectionEnabled = paramsQuery?.systemParams?.csrf_protection_enabled ?? false;
  const dispatch = useDispatch();
  const [csrfState, dispatchReducer] = useReducer(reducer, initialState);
  const callCsrfFlag = useSelector(callCsrfFlagSelector);
  const callCsrfPayload = useSelector(callCsrfPayloadSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const loginErrorCode = useSelector(loginErrorCodeSelector);
  const loginStatus = useSelector(loginStatusSelector);
  const isMounted = useRef(false);
  const csrResolve = useRef(null);
  const csrfResolvePromise = useCallback(() => {
    if (csrResolve.current) {
      csrResolve.current();
    }
  }, []);

  const { data, isInitialLoading, error } = csrfQueries.useCsrf({
    enabled: csrfState.enable,
    retry: 0
  });

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      // note: we always set 'waitForLogin' flag so we always have the "default" state once all is done

      // we are logged in as a direct user, the login call was made successfully but the feature flag is se to false
      // therefore, we will just resolve and move on
      if (!csrfProtectionEnabled && callCsrfFlag && !csrfState.enable) {
        dispatchReducer({
          type: 'CHANGE',
          payload: {
            enable: false,
            waitForLogin: false
          }
        });

        if (callCsrfFlag) {
          dispatch(loginSuccess(callCsrfPayload));
        }
        csrfResolvePromise();
      }

      // we are logged in as a direct user, the login call was made successfully now we will make the csrf call
      if (csrfProtectionEnabled && callCsrfFlag && !csrfState.enable) {
        dispatchReducer({
          type: 'CHANGE',
          payload: {
            enable: true,
            waitForLogin: false
          }
        });
      }

      // we have a login err but we are waiting for it to be successful (login call)
      if (
        csrfProtectionEnabled &&
        !callCsrfFlag &&
        !csrfState.enable &&
        csrfState.waitForLogin &&
        !error &&
        loginErrorCode !== null
      ) {
        csrfResolvePromise();
      }

      // we waited for the login call to be successful, now it is, therefore we can continue with making the csrf call
      if (
        csrfProtectionEnabled &&
        !callCsrfFlag &&
        !csrfState.enable &&
        csrfState.waitForLogin &&
        isLoggedIn &&
        !error
      ) {
        dispatchReducer({
          type: 'CHANGE',
          payload: {
            enable: true,
            waitForLogin: false
          }
        });
      }

      // csrf err handling
      if (
        csrfProtectionEnabled &&
        csrfState.enable &&
        (error as AxiosError)?.response?.status === unauthorizedStatus
      ) {
        dispatchReducer({
          type: 'CHANGE',
          payload: {
            enable: false,
            waitForLogin: false
          }
        });

        csrfResolvePromise();
      }

      // all worked, setting the csrf header
      if (csrfProtectionEnabled && csrfState.enable && !error && !isInitialLoading && data) {
        if (data.csrf_enabled) {
          setCSRFHeader(data.csrf_token);
        }

        dispatchReducer({
          type: 'CHANGE',
          payload: {
            enable: false,
            waitForLogin: false
          }
        });

        if (callCsrfFlag) {
          dispatch(loginSuccess(callCsrfPayload));
        }
        csrfResolvePromise();
      }
    }
  }, [
    data,
    isInitialLoading,
    error,
    isLoggedIn,
    csrfState.enable,
    csrfState.waitForLogin,
    loginErrorCode,
    csrfResolvePromise,
    callCsrfFlag,
    callCsrfPayload,
    dispatch,
    csrfProtectionEnabled
  ]);

  const setCsrToken = (waitForLoggedInFlag = false) => {
    // loginStatus is 'pending' only when LOGIN_REQUEST || LOGOUT_REQUEST action is dispatched
    if (csrfProtectionEnabled && isMounted.current && loginStatus !== 'pending') {
      if (!waitForLoggedInFlag) {
        dispatchReducer({
          type: 'CHANGE',
          payload: {
            enable: true
          }
        });
      } else {
        dispatchReducer({
          type: 'CHANGE',
          payload: {
            waitForLogin: true
          }
        });
      }
    } else {
      // comp is not mounted or we are in a login || logout request state OR
      // feature flag is set to false
      return Promise.resolve();
    }

    return new Promise<void>((resolve, _reject) => {
      csrResolve.current = resolve;
    });
  };

  return { setCsrToken };
};
