import {
  OldPricingPriceDetails,
  SetOldPricingPriceDetailsAction,
  SET_OLD_PRICING_PRICE_DETAILS
} from './oldPricing.types';

export const setOldPricingPriceDetails = (
  payload: OldPricingPriceDetails
): SetOldPricingPriceDetailsAction => ({
  type: SET_OLD_PRICING_PRICE_DETAILS,
  payload
});
