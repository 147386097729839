import styled from 'styled-components/macro';
import { Form } from 'formik';

export const SignInWithEmailFormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 36rem;
  gap: 2.4rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
`;
