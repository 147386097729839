export enum CreditsTypesEnum {
  GET_COUPONS_REQUEST = 'GET_COUPONS_REQUEST',
  GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS',
  GET_COUPONS_FAILURE = 'GET_COUPONS_FAILURE',
  APPLY_COUPON_REQUEST = 'APPLY_COUPON_REQUEST',
  APPLY_RETENTION_COUPON_REQUEST = 'APPLY_RETENTION_COUPON_REQUEST'
}
export enum CouponStatusesEnum {
  available = 'available',
  used = 'used',
  close_to_expiration = 'close_to_expiration',
  expired = 'expired'
}

export enum TextColorType {
  red = 'red',
  gray = 'gray'
}

export interface Coupon {
  coupon_id: number;
  coupon_code: string;
  added_time: string;
  credit_expiry_date: string;
  coupon_credit_status?: keyof typeof CouponStatusesEnum | undefined;
  amount: number;
  errors: unknown[];
}

export interface CouponsState extends BaseState {
  data: Coupon[];
}

export interface CouponsSuccessAnswer {
  coupons: Coupon[];
  couponsBalance: number;
  couponsTotal: number;
  couponsUsedAmount: number;
}

export interface GetCouponsRequestAction {
  type: CreditsTypesEnum.GET_COUPONS_REQUEST;
}

export interface ApplyCouponRequestAction {
  type: CreditsTypesEnum.APPLY_COUPON_REQUEST;
  payload: string;
}

export interface ApplyRetentionCouponRequestAction {
  type: CreditsTypesEnum.APPLY_RETENTION_COUPON_REQUEST;
  payload: string;
}

export interface GetCouponsSuccessAction {
  type: CreditsTypesEnum.GET_COUPONS_SUCCESS;
  payload: CouponsSuccessAnswer;
}

export interface GetCouponsFailureAction {
  type: CreditsTypesEnum.GET_COUPONS_FAILURE;
  error: string;
}

export type CreditsActionTypes =
  | GetCouponsRequestAction
  | GetCouponsSuccessAction
  | GetCouponsFailureAction
  | ApplyCouponRequestAction
  | ApplyRetentionCouponRequestAction;
