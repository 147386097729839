import { useState, useLayoutEffect } from 'react';

interface Dimensions {
  menuWidth: number;
  menuHeight: number;
}

const useMenuDimensions = (ref: React.MutableRefObject<HTMLElement>): Dimensions => {
  const [menuWidth, setMenuWidth] = useState(0);
  const [menuHeight, setMenuHeight] = useState(0);
  useLayoutEffect(() => {
    if (ref.current) {
      const { width, height } = ref.current && ref.current.getBoundingClientRect();
      setMenuWidth(width);
      setMenuHeight(height);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { menuHeight, menuWidth };
};

export default useMenuDimensions;
