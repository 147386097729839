import { ErrorInfo } from '../../types/interfaces';

export enum CloudAccountActionTypes {
  GET_CLOUD_ACCOUNTS_REQUEST = 'GET_CLOUD_ACCOUNTS_REQUEST',
  GET_CLOUD_ACCOUNTS_SUCCESS = 'GET_CLOUD_ACCOUNTS_SUCCESS',
  GET_CLOUD_ACCOUNTS_FAILURE = 'GET_CLOUD_ACCOUNTS_FAILURE',

  POST_CLOUD_ACCOUNT_REQUEST = 'POST_CLOUD_ACCOUNT_REQUEST',
  POST_CLOUD_ACCOUNT_SUCCESS = 'POST_CLOUD_ACCOUNT_SUCCESS',
  POST_CLOUD_ACCOUNT_FAILURE = 'POST_CLOUD_ACCOUNT_FAILURE',

  UPDATE_CLOUD_ACCOUNT_REQUEST = 'UPDATE_CLOUD_ACCOUNT_REQUEST',
  UPDATE_CLOUD_ACCOUNT_SUCCESS = 'UPDATE_CLOUD_ACCOUNT_SUCCESS',
  UPDATE_CLOUD_ACCOUNT_FAILURE = 'UPDATE_CLOUD_ACCOUNT_FAILURE',

  DELETE_CLOUD_ACCOUNT_REQUEST = 'DELETE_CLOUD_ACCOUNT_REQUEST',
  DELETE_CLOUD_ACCOUNT_SUCCESS = 'DELETE_CLOUD_ACCOUNT_SUCCESS',
  DELETE_CLOUD_ACCOUNT_FAILURE = 'DELETE_CLOUD_ACCOUNT_FAILURE'
}

export interface UpdateCloudAccountRequestPayload {
  cloudAccount: CloudAccount;
  successCb: (cloudAccount?: CloudAccount) => void;
  errorCb(errorInfo?: ErrorInfo | ErrorInfo[]): void;
}

export interface GetCloudAccountsRequestAction {
  type: CloudAccountActionTypes.GET_CLOUD_ACCOUNTS_REQUEST;
}

export interface GetCloudAccountsSuccessAction {
  type: CloudAccountActionTypes.GET_CLOUD_ACCOUNTS_SUCCESS;
  payload: CloudAccount[];
}

export interface GetCloudAccountsFailureAction {
  type: CloudAccountActionTypes.GET_CLOUD_ACCOUNTS_FAILURE;
  payload: string;
}

export interface PostCloudAccountRequestAction {
  type: CloudAccountActionTypes.POST_CLOUD_ACCOUNT_REQUEST;
  payload: UpdateCloudAccountRequestPayload;
}

export interface PostCloudAccountSuccessAction {
  type: CloudAccountActionTypes.POST_CLOUD_ACCOUNT_SUCCESS;
  payload: CloudAccount;
}

export interface PostCloudAccountFailureAction {
  type: CloudAccountActionTypes.POST_CLOUD_ACCOUNT_FAILURE;
  payload: string;
}

export interface UpdateCloudAccountRequestAction {
  type: CloudAccountActionTypes.UPDATE_CLOUD_ACCOUNT_REQUEST;
  payload: UpdateCloudAccountRequestPayload;
}

export interface UpdateCloudAccountSuccessAction {
  type: CloudAccountActionTypes.UPDATE_CLOUD_ACCOUNT_SUCCESS;
  payload: CloudAccount;
}

export interface UpdateCloudAccountFailureAction {
  type: CloudAccountActionTypes.UPDATE_CLOUD_ACCOUNT_FAILURE;
  payload: string;
}

export interface DeleteCloudAccountRequestAction {
  type: CloudAccountActionTypes.DELETE_CLOUD_ACCOUNT_REQUEST;
  payload: number;
}

export interface DeleteCloudAccountSuccessAction {
  type: CloudAccountActionTypes.DELETE_CLOUD_ACCOUNT_SUCCESS;
}

export interface DeleteCloudAccountFailureAction {
  type: CloudAccountActionTypes.DELETE_CLOUD_ACCOUNT_FAILURE;
  payload: string;
}

export type CloudAccountActions =
  | GetCloudAccountsRequestAction
  | GetCloudAccountsSuccessAction
  | GetCloudAccountsFailureAction
  | PostCloudAccountRequestAction
  | PostCloudAccountSuccessAction
  | PostCloudAccountFailureAction
  | UpdateCloudAccountRequestAction
  | UpdateCloudAccountSuccessAction
  | UpdateCloudAccountFailureAction
  | DeleteCloudAccountRequestAction
  | DeleteCloudAccountSuccessAction
  | DeleteCloudAccountFailureAction;

export interface CloudAccountsState extends BaseState {
  data: CloudAccount[];
}

export interface CloudAccountState extends BaseState {
  data: CloudAccount;
}

export interface CloudAccountsResponse {
  cloudAccounts: CloudAccount[];
}

export interface CloudAccountResponse {
  cloudAccount: CloudAccount;
}
