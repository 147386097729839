export enum ClusterRolesTypesEnum {
  CREATE_CLUSTER_ROLE_REQUEST = 'CREATE_CLUSTER_ROLE_REQUEST',
  CREATE_CLUSTER_ROLE_SUCCESS = 'CREATE_CLUSTER_ROLE_SUCCESS',

  UPDATE_CLUSTER_ROLE_REQUEST = 'UPDATE_CLUSTER_ROLE_REQUEST',
  UPDATE_CLUSTER_ROLE_SUCCESS = 'UPDATE_CLUSTER_ROLE_SUCCESS'
}

export interface CreateClusterRoleRequestAction {
  type: ClusterRolesTypesEnum.CREATE_CLUSTER_ROLE_REQUEST;
  payload: CreateUpdateClusterRolePayload;
}

export interface CreateClusterRoleSuccessAction {
  type: ClusterRolesTypesEnum.CREATE_CLUSTER_ROLE_SUCCESS;
  payload: ClusterRole;
}

export interface UpdateClusterRoleRequestAction {
  type: ClusterRolesTypesEnum.UPDATE_CLUSTER_ROLE_REQUEST;
  payload: CreateUpdateClusterRolePayload;
}

export interface UpdateClusterRoleSuccessAction {
  type: ClusterRolesTypesEnum.UPDATE_CLUSTER_ROLE_SUCCESS;
  payload: ClusterRole;
}

export type ClusterRolesActions =
  | CreateClusterRoleRequestAction
  | CreateClusterRoleSuccessAction
  | UpdateClusterRoleRequestAction
  | UpdateClusterRoleSuccessAction;

export type CreateUpdateClusterRolePayload = {
  clusterRole: ClusterRole;
  handleResponse: (
    isSuccessful: boolean,
    errorMessage?: string,
    errorField?: string | string[]
  ) => void;
};

export interface ClusterRolesState extends BaseState {
  data: ClusterRole[];
}
