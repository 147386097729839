import { SubscriptionRequiredPostPayload } from '../createSubscription.types';
import {
  EssentialsActionTypes,
  PostEssentialPlanRequestAction,
  PostEssentialPlanSuccessAction,
  PostEssentialPlanFailureAction
} from './essentials.types';

export const postEssentialPlanRequest = (
  payload: SubscriptionRequiredPostPayload
): PostEssentialPlanRequestAction => ({
  type: EssentialsActionTypes.POST_ESSENTIAL_PLAN_REQUEST,
  payload
});

export const postEssentialPlanSuccess = (): PostEssentialPlanSuccessAction => ({
  type: EssentialsActionTypes.POST_ESSENTIAL_PLAN_SUCCESS
});

export const postEssentialPlanFailure = (payload: string): PostEssentialPlanFailureAction => ({
  type: EssentialsActionTypes.POST_ESSENTIAL_PLAN_FAILURE,
  payload
});

export const incrementPlanSelectCount = () => ({
  type: EssentialsActionTypes.INCREMENT_PLAN_SELECT_COUNT
});

export const resetPlanSelectCount = () => ({
  type: EssentialsActionTypes.RESET_PLAN_SELECT_COUNT
});
