import { OriginsType } from './config.types';

export const ServiceOrigins: { [key: string]: OriginsType } = {
  LOCAL_SM: {
    TAGGING: 'http://localhost:9090/tagging',
    SM: 'http://localhost:9090/api/v1',
    MW: 'http://localhost:9090/maintenance-window',
    KYC: 'http://localhost:9090/kyc/api',
    BILLING: 'http://localhost:9090/billing',
    TGW: 'http://localhost:9090/cloud-transit-gateway'
  },
  LOCAL: {
    TAGGING: 'http://localhost:9090/tagging',
    SM: 'http://localhost:9090/api/v1',
    MW: 'https://test.redislabs.com:3000/local_mw/maintenance-window',
    KYC: 'https://test.redislabs.com:3000/local_kyc/kyc/api',
    BILLING: 'https://test.redislabs.com:3000/billing',
    TGW: 'https://test.redislabs.com:3000/cloud-transit-gateway'
  },
  TEST: {
    TAGGING: 'http://localhost',
    SM: 'http://localhost',
    MW: 'http://localhost',
    KYC: 'http://localhost',
    BILLING: 'http://localhost',
    TGW: 'http://localhost'
  }
} as const;
