export enum StripeActionTypesEnum {
  SET_BILLING_CUSTOMER = 'SET_BILLING_CUSTOMER'
}

export type StripeActionTypes = SetBillingCustomerAction;

export interface StripeState {
  billingCustomerId: string;
}

export interface SetBillingCustomerAction {
  type: StripeActionTypesEnum.SET_BILLING_CUSTOMER;
  payload: string;
}
