import { PermissionsDict } from '../../../store/auth/auth.types';
import { PermissionKey } from '../protectedComponents.types';

const userHasPermission = (
  permissionKey: PermissionKey,
  userPermissions: PermissionsDict,
  partialPermissions?: boolean
) => {
  if (Array.isArray(permissionKey)) {
    if (partialPermissions) {
      return permissionKey.some((permission) => userPermissions[permission]);
    }

    return permissionKey.every((permission) => userPermissions[permission]);
  }

  return permissionKey in userPermissions;
};

export default userHasPermission;
