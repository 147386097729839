import { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { ApiPathsType } from 'services/api/apiPaths';
import RedisApiAxiosInstance from './RedisApiAxiosInstance/RedisApiAxiosInstance';

type ApiCall = (...props: unknown[]) => Promise<AxiosResponse<unknown>>;

type REST = Partial<{
  getAll: ApiCall;
  getOne: ApiCall;
  create: ApiCall;
  update: ApiCall;
  remove: ApiCall;
  [key: string]: ApiCall;
}>;

type ConfigWithData = {
  path?: string;
  config?: AxiosRequestConfig;
  data?: unknown;
};

type ConfigWithoutData = {
  path?: string;
  config?: AxiosRequestConfig;
};

const createApiPath = (baseResourcePath: string, path?: string) =>
  path ? `${baseResourcePath}/${path}` : baseResourcePath;

export const createResourceApi = <T extends REST>(
  baseResourcePath: ApiPathsType,
  resource: (
    props: {
      get: <R>(props?: ConfigWithoutData) => Promise<AxiosResponse<R>>;
      post: <R>(props?: ConfigWithData) => Promise<AxiosResponse<R>>;
      put: <R>(props?: ConfigWithData) => Promise<AxiosResponse<R>>;
      delete: <R>(props?: ConfigWithoutData) => Promise<AxiosResponse<R>>;
    },
    instance: AxiosInstance
  ) => T
) =>
  resource(
    {
      get: (props) =>
        RedisApiAxiosInstance.get(createApiPath(baseResourcePath, props?.path), props?.config),
      post: (props) =>
        RedisApiAxiosInstance.post(
          createApiPath(baseResourcePath, props?.path),
          props?.data,
          props?.config
        ),
      put: (props) =>
        RedisApiAxiosInstance.put(
          createApiPath(baseResourcePath, props?.path),
          props?.data,
          props?.config
        ),
      delete: (props) =>
        RedisApiAxiosInstance.delete(createApiPath(baseResourcePath, props?.path), props?.config)
    },
    RedisApiAxiosInstance
  );
