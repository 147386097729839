import styled from 'styled-components/macro';
import { Typography } from '@redislabsdev/redis-ui-components';

export const FormWrapper = styled.div`
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 36rem;
`;
export const FormButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
  & > button:first-of-type {
    margin-right: 0.8rem;
  }
`;
export const ForgotPasswordTitle = styled(Typography.Heading)`
  color: ${({ theme }) => theme.semantic.color.text.neutral800};
  margin-bottom: 2.4rem;
`;
export const ForgotPasswordDescription = styled(Typography.Body)`
  color: ${({ theme }) => theme.semantic.color.text.neutral700};
  margin-bottom: 2.4rem;
`;
