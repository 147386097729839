import { ComponentType, Suspense } from 'react';
import SentryErrorBoundary from 'services/sentry/components/SentryErrorBoundary';
import { SentryErrorAreaTagValues } from 'services/sentry/sentry.types';

export interface MFEWrapperProps<T> {
  Component: ComponentType<T>;
  errorArea: SentryErrorAreaTagValues;
  SuspenseFallbackComponent?: ComponentType<T>;
  FallbackComponent?: ComponentType<T>;
  redirectOnError?: boolean;
}

export function createMFEWrapper<T>({
  Component,
  SuspenseFallbackComponent = () => null,
  FallbackComponent,
  errorArea,
  redirectOnError
}: MFEWrapperProps<T>) {
  return (props: T): JSX.Element => (
    <SentryErrorBoundary
      onError={() => {
        if (redirectOnError) {
          window.location.hash = '#/';
        }
      }}
      errorArea={errorArea}
      componentToFallbackTo={FallbackComponent && <FallbackComponent {...props} />}
    >
      <Suspense fallback={SuspenseFallbackComponent && <SuspenseFallbackComponent {...props} />}>
        <Component {...props} />
      </Suspense>
    </SentryErrorBoundary>
  );
}
