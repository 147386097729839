import { Button } from '@redislabsdev/redis-ui-components';
import { FallbackRender } from '@sentry/react';
import JsErrorIllustration from 'assets/illustrations/JsErrorIllustration.svg';
import { i18translation } from 'locale/translations/i18NsPaths';
import { useTranslation } from 'react-i18next';
import { ContactSupportLink } from './components/ContactSupportLink/ContactSupportLink';
import * as S from './GeneralFallback.style';

type FallbackProps = Parameters<FallbackRender>[0];

interface GeneralFallbackProps extends FallbackProps {
  isContactSupportDisplayed: boolean;
}

const GeneralFallback = ({
  error,
  resetError,
  isContactSupportDisplayed
}: GeneralFallbackProps) => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.generalFallback;

  process.env.NODE_ENV !== 'production' && console.log(error); // eslint-disable-line

  const fallback = (
    <S.Container role="alert">
      <img src={JsErrorIllustration} alt="clouds and lightning" />
      <S.TextAndButtonContainer>
        <S.Message>
          <S.Text>{t(keyPrefix.somethingWentWrong)}</S.Text>
          <S.Text>{t(keyPrefix.refreshText)}</S.Text>
        </S.Message>
        <Button onClick={resetError}>{t(keyPrefix.refreshButton)}</Button>
      </S.TextAndButtonContainer>
      {isContactSupportDisplayed && <ContactSupportLink />}
    </S.Container>
  );

  return !isContactSupportDisplayed ? <S.RootContainer>{fallback}</S.RootContainer> : fallback;
};

export default GeneralFallback;
