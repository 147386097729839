import {
  AccountActionTypes,
  GetAccountRequestAction,
  GetAccountSuccessAction,
  GetAccountFailureAction,
  Account,
  UpdateAccountRequestAction,
  UpdateAccountSuccessAction,
  UpdateAccountFailureAction,
  GetAccountsRequestAction,
  GetAccountsSuccessAction,
  GetAccountsFailureAction,
  SwitchAccountAction,
  CreateCloudApiRequestAction,
  CreateCloudApiSuccessAction,
  AssignMarketplaceAccountIdRequestAction,
  SetProductTypeSuccessAction,
  SetProductTypeFailureAction
} from './account.types';

export const getAccountRequest = (): GetAccountRequestAction => ({
  type: AccountActionTypes.GET_ACCOUNT_REQUEST
});

export const getAccountSuccess = (payload: Account): GetAccountSuccessAction => ({
  type: AccountActionTypes.GET_ACCOUNT_SUCCESS,
  payload
});

export const getAccountFailure = (error: string, status: number): GetAccountFailureAction => ({
  type: AccountActionTypes.GET_ACCOUNT_FAILURE,
  payload: { error, status }
});

export const updateAccountRequest = (payload: {
  formPayload: Account;
  finallyCallback: () => void;
}): UpdateAccountRequestAction => ({
  type: AccountActionTypes.UPDATE_ACCOUNT_REQUEST,
  payload
});

export const updateAccountSuccess = (payload: Account): UpdateAccountSuccessAction => ({
  type: AccountActionTypes.UPDATE_ACCOUNT_SUCCESS,
  payload
});

export const updateAccountFailure = (payload: string): UpdateAccountFailureAction => ({
  type: AccountActionTypes.UPDATE_ACCOUNT_FAILURE,
  payload
});

export const getAccountsRequest = (): GetAccountsRequestAction => ({
  type: AccountActionTypes.GET_ACCOUNTS_REQUEST
});

export const getAccountsSuccess = (payload: Account[]): GetAccountsSuccessAction => ({
  type: AccountActionTypes.GET_ACCOUNTS_SUCCESS,
  payload
});

export const getAccountsFailure = (error: string): GetAccountsFailureAction => ({
  type: AccountActionTypes.GET_ACCOUNTS_FAILURE,
  payload: error
});

export const switchAccount = (accountId: number): SwitchAccountAction => ({
  type: AccountActionTypes.SWITCH_ACCOUNT,
  payload: accountId
});

export const createCloudApiRequest = (): CreateCloudApiRequestAction => ({
  type: AccountActionTypes.CREATE_CLOUD_API_REQUEST
});

export const createCloudApiSuccess = (payload: string): CreateCloudApiSuccessAction => ({
  type: AccountActionTypes.CREATE_CLOUD_API_SUCCESS,
  payload
});

export const assignMarketplaceAccountIdRequest = (
  payload: AssignMarketplaceAccountIdRequestAction['payload']
): AssignMarketplaceAccountIdRequestAction => ({
  type: AccountActionTypes.POST_MARKETPLACE_ACCOUNT_ID_REQUEST,
  payload
});

export const assignMarketplaceAccountIdFailure = (error: string) => ({
  type: AccountActionTypes.POST_MARKETPLACE_ACCOUNT_ID_FAILURE,
  payload: error
});

export const setProductTypeSuccessAction = (
  productType: ProductType
): SetProductTypeSuccessAction => ({
  type: AccountActionTypes.SET_PRODUCT_TYPE_SUCCESS,
  payload: productType
});

export const setProductTypeFailureAction = (): SetProductTypeFailureAction => ({
  type: AccountActionTypes.SET_PRODUCT_TYPE_FAILURE
});
