import styled from 'styled-components/macro';
import { Button } from '@redislabsdev/redis-ui-components';
import { Form } from 'formik';

export const SetNewPasswordFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 36rem;
`;

export const SetNewPasswordForm = styled(Form)`
  gap: 2.4rem;
  display: flex;
  flex-direction: column;
`;

export const SubmitButton = styled(Button)`
  align-self: flex-end;
  margin-top: 4rem;
`;
