import { ChangeEvent } from 'react';
import { FieldHelperProps, FieldInputProps, FieldMetaProps, useField } from 'formik';

type UseFormikFieldProps = Parameters<typeof useField>[0];

/**
 * This hook added, because few components have custom onChange event, which receives new value instead of event object
 * This hook replaces onChange handler with one, that detects value as parameter and applies it directly.
 * This hook should work with both, standard onChange and "value" version of it
 */
export const useFormikField = <T = string>(
  propsOrFieldName: UseFormikFieldProps
): [
  FieldInputProps<T> & { onChange: (e: T | ChangeEvent) => void },
  FieldMetaProps<T>,
  FieldHelperProps<T>
] => {
  const [field, meta, helper] = useField<T>(propsOrFieldName);

  const isEvent = (eventOrValue: T | ChangeEvent): eventOrValue is ChangeEvent =>
    !!(eventOrValue as ChangeEvent)?.target;

  const onChange = (eventOrValue: T | ChangeEvent) =>
    isEvent(eventOrValue) ? field.onChange(eventOrValue) : helper.setValue(eventOrValue);

  return [{ ...field, onChange }, meta, helper];
};
