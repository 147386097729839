import { Event } from '@sentry/react';
import isArray from 'lodash/isArray';
import isChunkErrorMessage from './isChunkErrorMessage';

const shouldFilterEvent = (event: Event) => {
  if (isArray(event.exception.values)) {
    const isLoadingChunkError = event.exception.values.some(({ value }) =>
      isChunkErrorMessage(value)
    );

    return isLoadingChunkError;
  }

  return false;
};

export default shouldFilterEvent;
