import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';

const defaultOktaConfig: Partial<OktaAuthOptions> = {
  clientId: 'okta_client_id',
  issuer: 'https://redislabs.com',
  redirectUri: window.location.origin,
  scopes: ['openid', 'email', 'profile'],
  pkce: true,
  postLogoutRedirectUri: `${window.location.origin}/#/logout`
};

export class SmOktaAuth {
  static oktaAuth: OktaAuth;

  constructor(options: OktaAuthOptions) {
    SmOktaAuth.oktaAuth = new OktaAuth(options);
  }

  static getInstance(options?: Partial<OktaAuthOptions>) {
    if (!SmOktaAuth.oktaAuth) {
      if (!options) {
        throw new Error('Okta auth options are required for initializing okta');
      }

      const { clientId, issuer } = options;

      const overrides = {
        clientId: clientId || defaultOktaConfig.clientId,
        issuer: issuer || defaultOktaConfig.issuer
      };

      // eslint-disable-next-line no-new
      new SmOktaAuth({
        ...defaultOktaConfig,
        ...overrides
      });
    }

    return SmOktaAuth.oktaAuth;
  }
}

export const closeOktaSession = async () => {
  try {
    await SmOktaAuth.getInstance().closeSession();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error, 'closeOktaSession');
  }
};
