import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAccountStateSelector } from '../store/account/account.selectors';
import { getAccountRequest } from '../store/account/account.actions';
import { isLoggedInSelector } from '../store/auth/auth.selectors';
import { ReducerStateStatusEnum } from '../types/enums';
import { unauthorizedStatus } from '../utils/constants/api/statuses';

const useAccount = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { status, data, error, httpStatus } = useSelector(getAccountStateSelector);
  const httpStatusRef = useRef(null);

  useEffect(() => {
    if (
      (isLoggedIn && [ReducerStateStatusEnum.IDLE as ReducerStateStatus].includes(status)) ||
      // TODO: this is a hacky way to try and fetch current account again (race condition) when 401 code is received
      // to make sure this will not be a recursive call I try this only once
      // TEMPORARILY FIX for: https://redislabs.atlassian.net/browse/RED-85825
      (isLoggedIn &&
        httpStatus === unauthorizedStatus &&
        httpStatusRef.current !== unauthorizedStatus)
    ) {
      if (httpStatus === unauthorizedStatus) {
        httpStatusRef.current = unauthorizedStatus;
      }
      dispatch(getAccountRequest());
    }
  }, [dispatch, status, isLoggedIn, httpStatus]);

  return {
    account: data,
    isLoading: [
      ReducerStateStatusEnum.PENDING as ReducerStateStatus,
      ReducerStateStatusEnum.IDLE as ReducerStateStatus
    ].includes(status),
    isRejected: status === ReducerStateStatusEnum.REJECTED,
    error
  };
};

export default useAccount;
