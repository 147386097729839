import { EssentialsActionTypes, EssentialsActions } from './essentials.types';

const initialState = {
  postEssentialPlanState: { status: 'idle', error: '' } as BaseState,
  planSelectCount: 0
};

const essentialsReducer = (
  state = initialState,
  action: EssentialsActions
): typeof initialState => {
  switch (action.type) {
    case EssentialsActionTypes.POST_ESSENTIAL_PLAN_REQUEST:
      return {
        ...state,
        postEssentialPlanState: {
          ...state.postEssentialPlanState,
          error: '',
          status: 'pending'
        }
      };

    case EssentialsActionTypes.POST_ESSENTIAL_PLAN_SUCCESS:
      return {
        ...state,
        postEssentialPlanState: {
          ...state.postEssentialPlanState,
          status: 'idle'
        }
      };

    case EssentialsActionTypes.POST_ESSENTIAL_PLAN_FAILURE:
      return {
        ...state,
        postEssentialPlanState: {
          ...state.postEssentialPlanState,
          status: 'rejected',
          error: action.payload
        }
      };

    case EssentialsActionTypes.INCREMENT_PLAN_SELECT_COUNT:
      return {
        ...state,
        planSelectCount: state.planSelectCount + 1
      };

    case EssentialsActionTypes.RESET_PLAN_SELECT_COUNT:
      return {
        ...state,
        planSelectCount: 0
      };

    default:
      return state;
  }
};

export default essentialsReducer;
