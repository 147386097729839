export const getNewRequiredCloudResources = (newNodes: RcpNode[], oldNodes: RcpNode[]) => {
  const nodeDifferences = [...newNodes];

  oldNodes.forEach((oldNode) => {
    const nodeToDelete = nodeDifferences.findIndex(
      (node) => node.instance_type === oldNode.instance_type
    );

    if (nodeToDelete >= 0) {
      nodeDifferences.splice(nodeToDelete, 1);
    }
  });

  return nodeDifferences;
};
