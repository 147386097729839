import { SystemLogsTypesEnum, SystemLogsActionTypes, SystemLogsState } from './systemLogs.types';

export const systemLogsInitialState = {
  status: 'idle',
  error: '',
  data: [],
  totalPages: 0,
  pageNumber: 0,
  pageSize: 10
} as SystemLogsState;

const systemLogsReducer = (
  state = systemLogsInitialState,
  action: SystemLogsActionTypes
): typeof systemLogsInitialState => {
  switch (action.type) {
    case SystemLogsTypesEnum.GET_SYSTEM_LOGS_REQUEST:
      return {
        ...state,
        status: 'pending',
        pageSize: action.payload.pageSize
      };

    case SystemLogsTypesEnum.GET_SYSTEM_LOGS_SUCCESS:
      return {
        ...state,
        status: 'resolved',
        data: action.payload.systemLogs,
        totalPages: action.payload.meta.total_pages,
        pageNumber: action.payload.meta.page_number
      };

    case SystemLogsTypesEnum.GET_SYSTEM_LOGS_FAILURE:
      return {
        ...state,
        status: 'rejected'
      };

    default:
      return state;
  }
};

export default systemLogsReducer;
