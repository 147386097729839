import { useRef, useState, useEffect } from 'react';

interface WrapperProps {
  isVisible: boolean;
  toggleIsVisible: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wrapperRef: React.MutableRefObject<any>;
}

const useDetectClickOutside = (initialState = false): WrapperProps => {
  const wrapperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(initialState);
  const toggleIsVisible = () => setIsVisible(!isVisible);

  useEffect(() => {
    // Check that the current clicked event target is outside of the wrapper,
    // it is done by checking if the target is a child of the wrapper.
    // if not the click was outside of the wrapper, if it is, the click was inside.
    const onMouseUp = (event: MouseEvent) => {
      if (isVisible && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, [isVisible]);

  return { isVisible, toggleIsVisible, wrapperRef };
};

export default useDetectClickOutside;
