import { combineReducers } from 'redux';
import dataAccessControlReducer from './dataAccessControl/dataAccessControl.reducer';
import systemLogsReducer from './systemLogs/systemLogs.reducer';
import paymentMethodsReducer from './paymentMethods/paymentMethods.reducer';
import creditsReducer from './credits/credits.reducer';
import connectivityReducer from './connectivity/connectivity.reducer';
import createSubscriptionReducer from './createSubscription/createSubscription.reducer';
import subscriptionReducer from './subscriptions/subscriptions.reducer';
import databasesReducer from './databases/databases.reducer';
import sessionLogsReducer from './sessionLogs/sessionLogs.reducer';
import accountReducer from './account/account.reducer';
import { MetadataActionTypes, RootReducers } from './store.types';
import cloudAccountReducer from './cloudAccounts/cloudAccounts.reducer';
import mfaReducer from './mfa/mfa.reducer';
import authReducer from './auth/auth.reducer';
import connectionWizardReducer from './connectionWizard/connectionWizard.reducer';
import { AuthActionTypesEnum } from './auth/auth.types';
import { queryClient } from '../utils/initializeQueryClient';
import stripeReducer from './stripe/stripe.reducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  account: accountReducer,
  connectivity: connectivityReducer,
  createSubscription: createSubscriptionReducer,
  dataAccessControl: dataAccessControlReducer,
  databases: databasesReducer,
  paymentMethods: paymentMethodsReducer,
  credits: creditsReducer,
  subscriptionAndPlans: subscriptionReducer,
  systemLogs: systemLogsReducer,
  cloudAccounts: cloudAccountReducer,
  mfa: mfaReducer,
  sessionLogs: sessionLogsReducer,
  stripe: stripeReducer,
  connectionWizard: connectionWizardReducer
});

const handleResetStoreData = (state: RootState) => {
  const newState = { ...state };
  Object.keys(newState).forEach((reducer) => {
    newState[reducer] = undefined;
  });

  return newState;
};

const handlePageReset = (state: RootState, pageToReset: RootReducers) => {
  const newState = { ...state };
  newState[pageToReset] = undefined;

  return newState;
};

const reducerHandler = (state: RootState, action: { type: string; payload?: unknown }) => {
  if (action.type === AuthActionTypesEnum.LOGOUT_SUCCESS) {
    queryClient.clear();

    return rootReducer(undefined, action);
  }

  if (action.type === AuthActionTypesEnum.SESSION_EXPIRED) {
    queryClient.invalidateQueries({
      refetchType: 'none'
    });

    queryClient.resetQueries({
      type: 'inactive'
    });

    queryClient.removeQueries({
      type: 'inactive'
    });

    // queryClient.clear();

    return rootReducer(undefined, action);
  }

  if (action.type === MetadataActionTypes.RESET_STORE_DATA) {
    return rootReducer(handleResetStoreData(state), action);
  }

  if (action.type === MetadataActionTypes.RESET_PAGE) {
    return rootReducer(handlePageReset(state, action.payload as RootReducers), action);
  }

  return rootReducer(state, action);
};

export default reducerHandler;
