import { useState, useLayoutEffect } from 'react';
import useWindowDimensions from './useWindowDimensions';

export type MenuDirection = 'bottom' | 'top';

interface IProps {
  isOpen: boolean;
  itemHeight: number;
  ref: React.MutableRefObject<HTMLElement>;
}

const useMenuDirection = ({ isOpen, itemHeight, ref }: IProps) => {
  const [menuDirection, setMenuDirection] = useState<MenuDirection>('bottom');
  const { screenHeight } = useWindowDimensions();

  // using useLayoutEffect to ensure that menuDirection gets set before the browser renders
  useLayoutEffect(() => {
    if (ref.current) {
      const { y: containerYCoor } = ref.current.getBoundingClientRect();
      if (containerYCoor + itemHeight >= screenHeight) {
        setMenuDirection('top');
      } else {
        setMenuDirection('bottom');
      }
    }
  }, [screenHeight, isOpen, itemHeight, ref]);

  return menuDirection;
};

export default useMenuDirection;
