import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { i18translation } from 'locale/translations/i18NsPaths';
import * as S from './SignInFromUserInvitation.style';
import SocialLogin from '../SocialLogin/SocialLogin';
import OrSeparator from '../OrSeperator/OrSeparator';
import UserFormInfo from '../UserFormInfo/UserFormInfo';
import SetNewPasswordForm from '../SetNewPasswordForm/SetNewPasswordForm';
import { extractErrorAndShowToast } from '../../../../utils/helpers/extractErrorAndShowToast';
import { usersMutations } from '../../../../queryClient/users/users.mutations';
import { okNoContentStatus } from '../../../../utils/constants/api/statuses';
import { ExtendedAxiosError } from '../../../../types/interfaces';
import { ResetPasswordFormValues } from '../ResetPassword/ResetPassword.types';

const SignInFromUserInvitation = () => {
  const { t } = useTranslation();
  const keyPrefix = i18translation.LoginScreen.resetPasswordScreen;
  const [successUserActivation, setSuccessUserActivation] = useState(false);
  const { mutate: activateUserInvitation } = usersMutations.useActivateUserInvitation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const onSubmit = async (formValues: ResetPasswordFormValues) => {
    activateUserInvitation(
      { key: searchParams.get('key'), id: searchParams.get('id'), password: formValues.password },
      {
        onSuccess: (response) => {
          if (response?.status === okNoContentStatus) {
            setSuccessUserActivation(true);
          }
        },
        onError: (e: ExtendedAxiosError) => {
          extractErrorAndShowToast(e);
        }
      }
    );
  };

  return (
    <S.LoginFromInvitationContainer>
      {successUserActivation ? (
        <UserFormInfo title={t(keyPrefix.passSetSuccess)} />
      ) : (
        <S.SocialLoginContainer>
          <S.SignInTitle>{t(keyPrefix.signIn3rdParty)}</S.SignInTitle>
          <SocialLogin allowSSo={false} />
          <OrSeparator />
          <S.SetPasswordFormTitle>{t(keyPrefix.setPassword)}</S.SetPasswordFormTitle>
          <SetNewPasswordForm handleSubmit={onSubmit} />
        </S.SocialLoginContainer>
      )}
    </S.LoginFromInvitationContainer>
  );
};

export default SignInFromUserInvitation;
