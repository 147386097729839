import { UseQueryOptions } from '@tanstack/react-query';
import { pendoKeys } from './pendo.keys';
import useSmQuery from '../useSmQuery';
import { pendoApi } from '../../services/api/resources/pendo/pendo.resource';

const usePendoQuery = (queryOptions: UseQueryOptions<PendoResponseBody> = {}) => {
  const getPendoInfo = async () => {
    const { data } = await pendoApi.getAll();

    return data;
  };

  const { data, ...queryResult } = useSmQuery({
    permissionKey: false,
    queryKey: pendoKeys.getAll,
    queryFn: getPendoInfo,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...queryOptions
  });

  return { pendoInfo: data?.pendo_info, ...queryResult };
};

export const pendoQueries = {
  usePendo: usePendoQuery
};
