import { create } from 'zustand';

interface NewCustomerLoginStore {
  isFirstLogin: boolean;
  setIsFirstLogin: (isFirstLogin: boolean) => void;
}

const initialState = {
  isFirstLogin: false
};

export const useNewCustomerLoginStore = create<NewCustomerLoginStore>()((set) => ({
  ...initialState,
  setIsFirstLogin: (isFirstLogin) => set(() => ({ isFirstLogin }))
}));
