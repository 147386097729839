import {
  PaymentMethodsTypesEnum,
  GetPaymentsInfoAction,
  GetPaymentsInfoSuccessAction,
  GetPaymentsInfoFailureAction,
  IPaymentInfo,
  DeletePaymentMethodAction,
  DeletePaymentMethodSuccessAction,
  DeletePaymentMethodFailureAction,
  AddSubsToPaymentMethodAction,
  AddSubsToPaymentMethodSuccessAction,
  AddSubsToPaymentMethodFailureAction,
  SubscriptionsPaymentData
} from './paymentMethods.types';

export const getPaymentsInfoRequest = (): GetPaymentsInfoAction => ({
  type: PaymentMethodsTypesEnum.GET_PAYMENTS_INFO_REQUEST
});

export const getPaymentsInfoSuccess = (payload: IPaymentInfo[]): GetPaymentsInfoSuccessAction => ({
  type: PaymentMethodsTypesEnum.GET_PAYMENTS_INFO_SUCCESS,
  payload
});

export const getPaymentsInfoFailure = (error: string): GetPaymentsInfoFailureAction => ({
  type: PaymentMethodsTypesEnum.GET_PAYMENTS_INFO_FAILURE,
  error
});

export const deletePaymentMethodRequest = (payload: number): DeletePaymentMethodAction => ({
  type: PaymentMethodsTypesEnum.DELETE_PAYMENT_METHOD_REQUEST,
  payload
});

export const deletePaymentMethodSuccess = (payload: number): DeletePaymentMethodSuccessAction => ({
  type: PaymentMethodsTypesEnum.DELETE_PAYMENT_METHOD_SUCCESS,
  payload
});

export const deletePaymentMethodFailure = (error: string): DeletePaymentMethodFailureAction => ({
  type: PaymentMethodsTypesEnum.DELETE_PAYMENT_METHOD_FAILURE,
  error
});

export const addSubsToPaymentInfoRequest = (
  payload: SubscriptionsPaymentData
): AddSubsToPaymentMethodAction => ({
  type: PaymentMethodsTypesEnum.ADD_SUBS_TO_PAYMENT_METHOD_REQUEST,
  payload
});

export const addSubsToPaymentInfoSuccess = (
  payload: SubscriptionsPaymentData
): AddSubsToPaymentMethodSuccessAction => ({
  type: PaymentMethodsTypesEnum.ADD_SUBS_TO_PAYMENT_METHOD_SUCCESS,
  payload
});

export const addSubsToPaymentInfoFailure = (
  error: string
): AddSubsToPaymentMethodFailureAction => ({
  type: PaymentMethodsTypesEnum.ADD_SUBS_TO_PAYMENT_METHOD_FAILURE,
  error
});
