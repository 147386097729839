import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { showToast } from '../../../components/Toast/showToast';
import * as calls from './clusterRoles.calls';
import * as clusterRolesActions from './clusterRoles.actions';
import {
  ClusterRolesTypesEnum,
  CreateClusterRoleRequestAction,
  UpdateClusterRoleRequestAction
} from './clusterRoles.types';
import { extractError } from '../../../utils/helpers/extractError';
import i18n from '../../../locale/i18n';

function* createClusterRole(action: CreateClusterRoleRequestAction) {
  const { clusterRole, handleResponse } = action.payload;
  try {
    const { data }: AxiosResponse<{ clusterRole: ClusterRole }> = yield call(
      calls.createClusterRole,
      {
        clusterRole
      }
    );

    yield put(clusterRolesActions.createClusterRoleSuccess(data.clusterRole));
    handleResponse(true);
  } catch (e) {
    if (e.response) {
      const { data } = e.response;
      const errorObject = extractError(data);
      showToast(i18n.t(errorObject?.message));
      handleResponse(false, errorObject.message, errorObject.field);
    }
  }
}

function* updateClusterRole(action: UpdateClusterRoleRequestAction) {
  const { clusterRole, handleResponse } = action.payload;
  try {
    const { data }: AxiosResponse<{ clusterRole: ClusterRole }> = yield call(
      calls.updateClusterRole,
      {
        clusterRole
      }
    );
    yield put(clusterRolesActions.updateClusterRoleSuccess(data.clusterRole));
    handleResponse(true);
  } catch (e) {
    if (e.response) {
      const { data } = e.response;
      const errorObject = extractError(data);
      showToast(i18n.t(errorObject?.message));
      handleResponse(false, errorObject.message, errorObject.field);
    }
  }
}

function* clusterRolesSaga() {
  yield takeLatest(ClusterRolesTypesEnum.CREATE_CLUSTER_ROLE_REQUEST, createClusterRole);
  yield takeLatest(ClusterRolesTypesEnum.UPDATE_CLUSTER_ROLE_REQUEST, updateClusterRole);
}

export default clusterRolesSaga;
