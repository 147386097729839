import { notificationService } from '@redislabsdev/cloud-ui-mfe-events';
import { toast } from '@redislabsdev/redis-ui-components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { extractError } from '../../../../utils/helpers/extractError';

const useNotificationEvents = () => {
  const { t } = useTranslation();
  useEffect(() => {
    const unsubscribe = notificationService.subscribe((event) => {
      let message = '';
      switch (event.type) {
        case 'error':
          message = event.payload.message || t(extractError(event.payload.smBadException)?.message);
          if (message) {
            toast.danger(message);
          }
          break;
        case 'success':
          if (event.payload.message) {
            toast.success(event.payload.message);
          }
          break;
        case 'notice':
          if (event.payload.message) {
            toast.notice(event.payload.message);
          }
          break;
        case 'attention':
          if (event.payload.message) {
            toast.attention(event.payload.message);
          }
          break;
      }
    });

    return () => {
      unsubscribe();
    };
  }, [t]);
};

export default useNotificationEvents;
