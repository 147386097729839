import { AccountActions, AccountActionTypes, AccountState, AccountsState } from './account.types';

const initialState = {
  accountState: { status: 'idle', data: null, error: '', httpStatus: 0 } as AccountState,
  updateAccountState: { status: 'idle', error: '' } as BaseState,
  accountsState: { status: 'idle', data: [], error: '' } as AccountsState
};

const metadataReducer = (state = initialState, action: AccountActions): typeof initialState => {
  switch (action.type) {
    case AccountActionTypes.GET_ACCOUNT_REQUEST:
      return {
        ...state,
        accountState: {
          ...state.accountState,
          error: '',
          status: 'pending'
        }
      };

    case AccountActionTypes.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountState: {
          data: action.payload,
          error: '',
          status: 'resolved'
        }
      };

    case AccountActionTypes.GET_ACCOUNT_FAILURE:
      return {
        ...state,
        accountState: {
          ...state.accountState,
          error: action.payload.error,
          httpStatus: action.payload.status,
          status: 'rejected'
        }
      };

    case AccountActionTypes.UPDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        updateAccountState: {
          status: 'pending',
          error: ''
        }
      };

    case AccountActionTypes.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountState: {
          ...state.accountState,
          data: action.payload
        },
        updateAccountState: {
          ...state.updateAccountState,
          status: 'resolved'
        }
      };

    case AccountActionTypes.UPDATE_ACCOUNT_FAILURE:
      return {
        ...state,
        updateAccountState: {
          status: 'rejected',
          error: action.payload
        }
      };

    case AccountActionTypes.GET_ACCOUNTS_REQUEST:
      return {
        ...state,
        accountsState: {
          ...state.accountsState,
          status: 'pending',
          error: ''
        }
      };

    case AccountActionTypes.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accountsState: {
          ...state.accountsState,
          status: 'resolved',
          data: action.payload
        }
      };

    case AccountActionTypes.GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        accountsState: {
          ...state.accountsState,
          status: 'rejected',
          error: action.payload
        }
      };

    case AccountActionTypes.CREATE_CLOUD_API_SUCCESS:
      return {
        ...state,
        accountState: {
          ...state.accountState,
          data: {
            ...state.accountState.data,
            api_access_key: action.payload
          }
        }
      };

    default:
      return state;
  }
};

export default metadataReducer;
