import styled from 'styled-components/macro';

export const MainContainer = styled.div.attrs({ 'data-role': 'page' })`
  width: 100%;
  min-width: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.9rem 4rem 0 4rem;
`;
