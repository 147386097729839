// eslint-disable-next-line no-restricted-imports
import { QueryKey, UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { PermissionKey } from '../components/PermissionsProtectors/protectedComponents.types';
import usePermissionValidation from '../components/PermissionsProtectors/hooks/usePermissionValidation';

const ARBITRARY_PERMISSION = 'validate_permissions';

type UseSmQueryResult<TData, TError> = {
  hasPermission: boolean;
} & Omit<UseQueryResult<TData, TError>, 'isLoading'>;

type UseSmQueryOptions<TQueryFnData, TError, TData, TQueryKey extends QueryKey> = {
  permissionKey: PermissionKey | false;
} & UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>;

function useSmQuery<
  TQueryFnData = unknown,
  TError = AxiosError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  permissionKey,
  ...queryOptions
}: UseSmQueryOptions<TQueryFnData, TError, TData, TQueryKey>): UseSmQueryResult<TData, TError> {
  const hasPermission = usePermissionValidation(permissionKey || ARBITRARY_PERMISSION);

  const shouldEnableQuery = () => {
    if (permissionKey && !hasPermission) {
      return false;
    }
    if (queryOptions.enabled === undefined) {
      return true;
    }

    return queryOptions.enabled;
  };

  const query = useQuery({ ...queryOptions, enabled: shouldEnableQuery() });

  return { ...query, hasPermission: permissionKey ? hasPermission : true };
}

export default useSmQuery;
