import {
  ConnectionWizardState,
  ConnectionWizardActionTypes,
  ConnectionWizardTypesEnum
} from './connectionWizard.types';

const initialState = {
  drawerOpen: false,
  openedAccordionId: 'redisInsight',
  bdb: null,
  crdb: null
} as ConnectionWizardState;

const connectionWizardReducer = (
  state = initialState,
  action: ConnectionWizardActionTypes
): typeof initialState => {
  switch (action.type) {
    case ConnectionWizardTypesEnum.TOGGLE_CONNECTION_WIZARD_DRAWER:
      return {
        ...state,
        drawerOpen: action.payload,
        bdb: !action.payload ? null : state.bdb,
        crdb: !action.payload ? null : state.crdb
      };
    case ConnectionWizardTypesEnum.SET_OPENED_ACCORDION_ID:
      return {
        ...state,
        openedAccordionId: state.openedAccordionId === action.payload ? null : action.payload
      };
    case ConnectionWizardTypesEnum.SET_CONNECTION_WIZARD_BDB:
      return {
        ...state,
        bdb: action.payload
      };
    case ConnectionWizardTypesEnum.SET_CONNECTION_WIZARD_CRDB:
      return {
        ...state,
        crdb: action.payload
      };
    default:
      return state;
  }
};
export default connectionWizardReducer;
