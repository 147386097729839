import { emptyDate } from './sessionLogs.constants';
import {
  SessionLogsTypesEnum,
  SessionLogsActionTypes,
  SessionLogsState
} from './sessionLogs.types';

const sessionLogsInitialState: SessionLogsState = {
  search: null,
  date: emptyDate,
  type: null,
  accountId: null
};

const sessionLogsReducer = (
  state = sessionLogsInitialState,
  action: SessionLogsActionTypes
): SessionLogsState => {
  switch (action.type) {
    case SessionLogsTypesEnum.UPDATE_FILTERS:
      return {
        ...state,
        ...action.payload
      } as SessionLogsState;

    case SessionLogsTypesEnum.CLEAR_FILTERS:
      return {
        ...sessionLogsInitialState,
        date: {
          from: null,
          to: null
        }
      } as SessionLogsState;

    default:
      return state;
  }
};

export default sessionLogsReducer;
