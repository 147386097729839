import { difference, mapKeys } from 'lodash';
import { TEMP_PERMISSIONS } from '../../components/PermissionsProtectors/utils/permissions.constants';
import {
  ApiAuthResponseNormalizedPermissions,
  AccessPermissions,
  ApiAuthResponse,
  PermissionsDict,
  AgentType
} from './auth.types';

export const getUserData = (data: ApiAuthResponse): ApiAuthResponseNormalizedPermissions => {
  const permissions: AccessPermissions[] = [
    ...modifyUserPermissions(data.permissions, data.agent_type),
    ...TEMP_PERMISSIONS
  ];

  return { ...data, permissions: mapKeys(permissions) as PermissionsDict };
};

const modifyUserPermissions = (
  permissions: AccessPermissions[],
  agentType: AgentType
): AccessPermissions[] => {
  let newPermissions = permissions;

  if (!difference(requiredPermissionsForCloudAccountTab, newPermissions).length) {
    newPermissions = [...newPermissions, 'TEMP_can_access_cloud_accounts_tab'];
  }

  // support agent should be able to see SSO tab even though it has only saml_read permission
  if (
    !difference(requiredPermissionsForSamlSingleSignOnTab, newPermissions).length ||
    agentType === 'support_agent'
  ) {
    newPermissions = [...newPermissions, 'TEMP_can_access_saml_single_sign_on_tab'];
  }

  return newPermissions;
};

const requiredPermissionsForSamlSingleSignOnTab: AccessPermissions[] = [
  'saml_read',
  'saml_create',
  'saml_update'
];

const requiredPermissionsForCloudAccountTab: AccessPermissions[] = [
  'cloud_account_create',
  'cloud_account_delete',
  'cloud_account_update',
  'cloud_account_get_accounts'
];

export const productTypesForPolling: Record<ProductType, ProductType> = {
  unifiedrc: 'unifiedrc',
  memcached: 'memcached',
  redis: 'redis',
  rlec: 'unifiedrc',
  rlec_managed: 'unifiedrc',
  rcp: 'unifiedrc'
};
