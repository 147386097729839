import { ApiPaths } from 'services/api/apiPaths';
import { ApiAuthResponse } from '../../../../store/auth/auth.types';
import { createResourceApi } from '../../createResourceApi';

export const usersApi = createResourceApi(ApiPaths.USERS, (rest) => ({
  getAll: () => rest.get<{ users: User[] }>(),
  getOne: (id: number) => rest.get<{ user: User }>({ path: id.toString() }),
  create: (user: Partial<User>) => rest.post<{ user: User }>({ data: { user } }),
  remove: (id: number) => rest.delete({ path: id.toString() }),
  update: (user: Partial<User>) =>
    rest.put<{ user: User }>({ path: user.id.toString(), data: { user } }),
  getAllRoles: () => rest.get<{ roles: UserRole[] }>({ path: 'roles' }),
  getCurrentUser: () => rest.get<ApiAuthResponse>({ path: 'me' }),
  resetPasswordCallback: (search: string) =>
    rest.get({ path: `reset_password_callback/${search}` }),
  resetPassword: (email: string) => rest.post({ path: 'reset_password', data: email }),
  setNewPassword: (id: string, password: string) =>
    rest.post({ path: 'activate_password_reset', data: { id, password } }),
  activateUserInvitation: (key: string, id: string, password: string) =>
    rest.post({ path: 'activate_invitation', data: { id, password }, config: { params: { key } } }),
  registrationCallback: (search: string) => rest.get({ path: `registration_callback/${search}` }),
  resendActivationLink: (search: string) => rest.get({ path: `resend_activation_link/${search}` })
}));
