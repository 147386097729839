import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { usersQueries } from '../../queryClient/users/users.queries';
import { setCurrentUser } from '../../store/auth/auth.action';
import { loginStatusSelector } from '../../store/auth/auth.selectors';
import { routes } from '../../utils/constants/routes';
import { Loader } from '../Routes.style';

export const LoadCurrentUserAndPermissions: FC = ({ children }) => {
  const loginStatus = useSelector(loginStatusSelector);
  const { pathname } = useLocation();
  const disableQuery = [routes.logout, routes.missingEmailSocialLogin].includes(pathname);

  const { data, isInitialLoading } = usersQueries.useCurrentUser({
    enabled: loginStatus === 'idle' && !disableQuery,
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: true
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isInitialLoading && data) {
      dispatch(setCurrentUser(data));
    }
  }, [isInitialLoading, data, dispatch]);

  if (isInitialLoading) {
    return <Loader dataTestId="loader-load-current-user-and-permissions" />;
  }

  return <>{children}</>;
};
