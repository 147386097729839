import { SentryTagNames } from '../sentry.types';

const sentryTags: Record<string, SentryTagNames> = {
  errorArea: 'error-area',
  generalErrorMessage: 'general-error-message',
  httpErrorCode: 'http-error-code',
  runtimeError: 'runtime-error',
  httpRequestError: 'http-request-error',
  httpMethod: 'http-method',
  httpStatus: 'http-status',
  httpUrl: 'http-url',
  broadcastApiNotSupported: 'broadcast-api-not-supported',
  formErrorMissingElement: 'form-error-missing-element'
};

export default sentryTags;
