import { ApiPaths } from 'services/api/apiPaths';
import RedisApiAxiosInstance from '../../../../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { MfaTypes } from '../../../../../store/auth/auth.types';

export const chooseFactor = (username: string, mfaType: MfaTypes) => {
  return RedisApiAxiosInstance.post(`${ApiPaths.LOGIN}/choose_factor`, {
    username,
    mfa_type: mfaType
  });
};
