import styled from 'styled-components/macro';
import { Typography } from '@redislabsdev/redis-ui-components';

export const ResetPasswordContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-bottom: 10rem;
`;

export const ResetPasswordFormTitle = styled(Typography.Heading)`
  color: ${({ theme }) => theme.semantic.color.text.neutral800};
  margin-bottom: 2.4rem;
`;
