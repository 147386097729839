import { AxiosResponse } from 'axios';
import { takeLatest, call, put, delay } from 'redux-saga/effects';
import createApiErrorResponse from 'utils/createApiErrorResponse';
import {
  CloudAccountActionTypes,
  CloudAccountsResponse,
  CloudAccountResponse,
  PostCloudAccountRequestAction,
  DeleteCloudAccountRequestAction,
  UpdateCloudAccountRequestAction
} from './cloudAccounts.types';
import * as calls from './cloudAccounts.calls';
import {
  getCloudAccountsSuccess,
  getCloudAccountsFailure,
  postCloudAccountSuccess,
  postCloudAccountFailure,
  deleteCloudAccountSuccess,
  deleteCloudAccountFailure,
  updateCloudAccountSuccess,
  updateCloudAccountFailure
} from './cloudAccounts.actions';
import { ErrorInfo, ExtendedAxiosError } from '../../types/interfaces';
import { extractErrorAndShowToast } from '../../utils/helpers/extractErrorAndShowToast';

function* fetchCloudAccountUntilStatusActive(cloudAccountId: number) {
  let {
    data: { cloudAccount }
  }: AxiosResponse<CloudAccountResponse> = yield call(calls.getCloudAccount, cloudAccountId);

  while (cloudAccount.status !== 'active') {
    yield delay(2000);

    const { data }: AxiosResponse<CloudAccountResponse> = yield call(
      calls.getCloudAccount,
      cloudAccountId
    );

    cloudAccount = data.cloudAccount;
    if (cloudAccount.status === 'active-error') {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw createApiErrorResponse('general-error');
    }
  }

  return cloudAccount;
}

function* getCloudAccountsSaga() {
  try {
    const { data }: AxiosResponse<CloudAccountsResponse> = yield call(calls.getCloudAccounts);
    const cloudAccounts = data['cloud-accounts'];
    yield put(getCloudAccountsSuccess(cloudAccounts));
  } catch (e) {
    if (e.response) {
      yield put(getCloudAccountsFailure(e.response?.message));
      extractErrorAndShowToast(e);
    }
  }
}

function* handleCreateUpdateRequestError(
  e: ExtendedAxiosError,
  errorCb: (errorInfo?: ErrorInfo | ErrorInfo[]) => void
) {
  yield errorCb(e.response?.errorInfo || { message: e?.message });
}

function* postCloudAccountSaga({ payload }: PostCloudAccountRequestAction) {
  try {
    yield call(calls.validateCloudAccount, payload.cloudAccount);

    const { data }: AxiosResponse<CloudAccountResponse> = yield call(
      calls.postCloudAccount,
      payload.cloudAccount
    );

    let { cloudAccount } = data;
    if (cloudAccount.status !== 'active') {
      cloudAccount = yield fetchCloudAccountUntilStatusActive(data.cloudAccount.id);
    }

    yield put(postCloudAccountSuccess(cloudAccount));
    payload.successCb(cloudAccount);
  } catch (e) {
    yield put(postCloudAccountFailure(e.response?.data?.message));
    yield handleCreateUpdateRequestError(e, payload.errorCb);
  }
}

function* deleteCloudAccountSaga({ payload }: DeleteCloudAccountRequestAction) {
  try {
    yield call(calls.deleteCloudAccount, payload);
    yield put(deleteCloudAccountSuccess());
    yield getCloudAccountsSaga();
  } catch (e) {
    if (e.response) {
      yield put(deleteCloudAccountFailure(e.response?.data?.message));
      extractErrorAndShowToast(e);
    }
  }
}

function* updateCloudAccountSaga({ payload }: UpdateCloudAccountRequestAction) {
  try {
    yield call(calls.validateCloudAccount, payload.cloudAccount);

    const { data }: AxiosResponse<CloudAccountResponse> = yield call(
      calls.updateCloudAccount,
      payload.cloudAccount
    );

    let { cloudAccount } = data;
    if (cloudAccount.status !== 'active') {
      cloudAccount = yield fetchCloudAccountUntilStatusActive(data.cloudAccount.id);
    }

    yield put(updateCloudAccountSuccess(cloudAccount));
    yield getCloudAccountsSaga();
    payload.successCb(cloudAccount);
  } catch (e) {
    yield put(updateCloudAccountFailure(e.response?.message));
    yield handleCreateUpdateRequestError(e, payload.errorCb);
  }
}

export default function* cloudAccountSagaWatcher() {
  yield takeLatest(CloudAccountActionTypes.GET_CLOUD_ACCOUNTS_REQUEST, getCloudAccountsSaga);
  yield takeLatest(CloudAccountActionTypes.POST_CLOUD_ACCOUNT_REQUEST, postCloudAccountSaga);
  yield takeLatest(CloudAccountActionTypes.DELETE_CLOUD_ACCOUNT_REQUEST, deleteCloudAccountSaga);
  yield takeLatest(CloudAccountActionTypes.UPDATE_CLOUD_ACCOUNT_REQUEST, updateCloudAccountSaga);
}
