import styled from 'styled-components/macro';
import { Modal, Typography } from '@redislabsdev/redis-ui-components';

export const InputHint = styled(Typography.Body)`
  margin-bottom: 1.2rem;
  margin-top: 3rem;
`;

export const ModalContentFooterCompose = styled(Modal.Content.Footer.Compose)`
  margin-top: 10rem;
`;

export const Container = styled.div`
  padding: 0 6rem;
`;
export const ActionButtons = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;
