import parseString from '../parseString';
import convertCommaSeparatedParamToArray from './convertCommaSeparatedParamToArray';

const parametersUtils = {
  apiResponseToAppParams: (parametersResponse: SystemParamsResponse) =>
    parametersResponse.parameter.reduce((acc, current) => {
      acc[current.name || ''] = parseString(current.value);

      return acc;
    }, {} as SystemParams),
  convertCommaSeparatedParamToArray
};

export default parametersUtils;
