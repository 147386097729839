import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import usePendo from 'services/pendo/hooks/usePendo';
import * as S from './Routes.style';
import { routes } from '../utils/constants/routes';
import { isLoggedInSelector, loginStatusSelector } from '../store/auth/auth.selectors';
import SentryErrorBoundary from '../services/sentry/components/SentryErrorBoundary';
import MissingEmailSocialLogin from './LoginMigrations/MissingEmailSocialLogin/MissingEmailSocialLogin';
import Login from './Login/Login';
import ActivateInvitationCallback from './Login/components/UserInvitation/ActivateInvitationCallback';
import ResolvedLogin from './Login/components/ResolvedLogin/ResolvedLogin';
import RiCloud from './RiCloud/RiCloud';
import NewCustomer from './NewCustomer/NewCustomer';

const MainWrapper = lazy(() => import('./MainWrapper/MainWrapper'));

const SocialLoginMigration = lazy(
  () => import('./LoginMigrations/SocialLoginMigration/SocialLoginMigration')
);

const SamlLoginMigration = lazy(
  () => import('./LoginMigrations/SamlLoginMigration/SamlLoginMigration')
);
const FreeTrial = lazy(() => import('./Marketplace/FreeTrial/FreeTrial'));
const AccountMapping = lazy(() => import('./Marketplace/AccountMapping/AccountMapping'));

const Routes = () => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const isResolved = useSelector(loginStatusSelector) === 'resolved';

  usePendo();

  return (
    <Suspense fallback={<S.Loader dataTestId="loader-routes-suspense" />}>
      <Switch>
        <Route exact path={routes.missingEmailSocialLogin} component={MissingEmailSocialLogin} />
        <Route exact path={routes.socialConfirmMigration} component={SocialLoginMigration} />
        <Route exact path={routes.samlConfirmMigration} component={SamlLoginMigration} />
        <Route exact path={routes.activateInvitation} component={ActivateInvitationCallback} />
        {isLoggedIn && (
          <Route exact path={routes.marketplaceAccountMapping} component={AccountMapping} />
        )}
        {isLoggedIn && <Route exact path={routes.marketplaceFreeTrial} component={FreeTrial} />}
        {isLoggedIn && <Route path={routes.riCloud} component={RiCloud} />}
        {isLoggedIn && <Route path={routes.newCustomer.newDatabase} component={NewCustomer} />}

        {isLoggedIn ? (
          <MainWrapper />
        ) : (
          <SentryErrorBoundary errorArea="authentication">
            <Login />
          </SentryErrorBoundary>
        )}
      </Switch>
      {isResolved ? <ResolvedLogin /> : <div />}
    </Suspense>
  );
};

export default Routes;
