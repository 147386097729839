import { toast, Toaster } from '@redislabsdev/redis-ui-components';
import { ToasterProps } from '@redislabsdev/redis-ui-components/dist/Toast/core/core.types';

const DEFAULT_NOTIFICATION_OPTIONS: ToasterProps = {
  position: toast.Position.TopCenter,
  autoClose: 15000,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnHover: true
};

export const AppToaster = () => <Toaster {...DEFAULT_NOTIFICATION_OPTIONS} />;
