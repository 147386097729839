import { PlanState, PlanActionTypes, PlanTypes } from './plans.types';

const initialState = { status: 'idle', data: {}, error: '' } as PlanState;

const plansReducer = (state = initialState, action: PlanTypes): typeof initialState => {
  switch (action.type) {
    case PlanActionTypes.GET_PLANS_REQUEST: {
      return {
        ...state,
        status: state.status !== 'resolved' ? 'pending' : state.status
      };
    }
    case PlanActionTypes.GET_PLANS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        status: 'resolved'
      };
    }
    case PlanActionTypes.GET_PLANS_FAILURE: {
      return {
        ...state,
        status: 'rejected',
        error: action.payload
      };
    }

    default:
      return state;
  }
};

export default plansReducer;
