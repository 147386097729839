import { UseQueryOptions } from '@tanstack/react-query';
import { parametersKeys } from './parameters.keys';
import { parametersApi } from '../../services/api/resources/parameters/parameters.resource';
import parametersUtils from '../../utils/parameters/parameters.utils';
import useSmQuery from '../useSmQuery';

const useParametersQuery = (queryOptions?: UseQueryOptions<SystemParams>) => {
  const getSystemParams = async () => {
    const { data } = await parametersApi.getAll();

    return parametersUtils.apiResponseToAppParams(data);
  };

  const { data, ...queryResult } = useSmQuery({
    permissionKey: false,
    queryKey: parametersKeys.getAll,
    queryFn: getSystemParams,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    ...queryOptions
  });

  const emptyResponse = {} as SystemParams;

  return { systemParams: data ?? emptyResponse, ...queryResult };
};

export const parametersQueries = {
  useSystemParameters: useParametersQuery
};
