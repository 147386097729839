import {
  CloudAccountActions,
  CloudAccountActionTypes,
  CloudAccountsState
} from './cloudAccounts.types';

const initialState = {
  deleteCloudAccountState: { status: 'idle', error: '' } as BaseState,
  postCloudAccountState: { status: 'idle', error: '' } as BaseState,
  updateCloudAccountState: { status: 'idle', error: '' } as BaseState,
  cloudAccounts: { status: 'idle', data: [], error: '' } as CloudAccountsState
};

const cloudAccountReducer = (
  state = initialState,
  action: CloudAccountActions
): typeof initialState => {
  switch (action.type) {
    case CloudAccountActionTypes.GET_CLOUD_ACCOUNTS_REQUEST:
      return {
        ...state,
        cloudAccounts: {
          ...state.cloudAccounts,
          error: '',
          status: 'pending'
        }
      };

    case CloudAccountActionTypes.GET_CLOUD_ACCOUNTS_SUCCESS:
      return {
        ...state,
        cloudAccounts: {
          data: action.payload,
          error: '',
          status: 'resolved'
        }
      };

    case CloudAccountActionTypes.GET_CLOUD_ACCOUNTS_FAILURE:
      return {
        ...state,
        cloudAccounts: {
          ...state.cloudAccounts,
          error: action.payload,
          status: 'rejected'
        }
      };

    case CloudAccountActionTypes.POST_CLOUD_ACCOUNT_REQUEST:
      return {
        ...state,
        postCloudAccountState: {
          error: '',
          status: 'pending'
        }
      };

    case CloudAccountActionTypes.POST_CLOUD_ACCOUNT_SUCCESS:
      return {
        ...state,
        cloudAccounts: {
          ...state.cloudAccounts,
          data: [...state.cloudAccounts.data, action.payload]
        },
        postCloudAccountState: {
          error: '',
          status: 'resolved'
        }
      };

    case CloudAccountActionTypes.POST_CLOUD_ACCOUNT_FAILURE:
      return {
        ...state,
        postCloudAccountState: {
          error: action.payload,
          status: 'rejected'
        }
      };

    case CloudAccountActionTypes.DELETE_CLOUD_ACCOUNT_REQUEST:
      return {
        ...state,
        deleteCloudAccountState: {
          error: '',
          status: 'pending'
        }
      };
    case CloudAccountActionTypes.DELETE_CLOUD_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteCloudAccountState: {
          error: '',
          status: 'resolved'
        }
      };

    case CloudAccountActionTypes.DELETE_CLOUD_ACCOUNT_FAILURE:
      return {
        ...state,
        deleteCloudAccountState: {
          error: action.payload,
          status: 'rejected'
        }
      };

    case CloudAccountActionTypes.UPDATE_CLOUD_ACCOUNT_REQUEST:
      return {
        ...state,
        updateCloudAccountState: {
          error: '',
          status: 'pending'
        }
      };

    case CloudAccountActionTypes.UPDATE_CLOUD_ACCOUNT_SUCCESS:
      return {
        ...state,
        updateCloudAccountState: {
          error: '',
          status: 'resolved'
        }
      };

    case CloudAccountActionTypes.UPDATE_CLOUD_ACCOUNT_FAILURE:
      return {
        ...state,
        updateCloudAccountState: {
          error: action.payload,
          status: 'rejected'
        }
      };

    default:
      return state;
  }
};

export default cloudAccountReducer;
