import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@redislabsdev/redis-ui-components';
import * as S from './LoginRelatedComponents.style';
import emailPatternBg from '../../assets/icons/EmailPatternBg.svg';
import usePrefixedTranslation from '../../hooks/usePrefixedTranslation';
import { routes } from '../../utils/constants/routes';
import RedisLogo from './RedisLogo';

const AccountAlreadyActivated: FC = () => {
  const history = useHistory();
  const { prefixedT } = usePrefixedTranslation('LoginScreen');

  const handleClick = () => history.push(routes.login);

  return (
    <S.ThanksForSignUpContainer>
      <img src={emailPatternBg} alt="background with starts" />

      <RedisLogo />

      <S.WelcomeText marginBottom="3.57rem">{prefixedT('accountAlreadyActivated')}</S.WelcomeText>

      <Button
        data-testid="btn-submit-back-to-login"
        type="submit"
        size="large"
        onClick={handleClick}
      >
        {prefixedT('backToLoginBtn')}
      </Button>
    </S.ThanksForSignUpContainer>
  );
};

export default AccountAlreadyActivated;
