export const DATA_SCROLL_ANCHOR = 'data-scrollanchor';

export const setDataScrollAnchor = (fieldName: string) => ({
  [DATA_SCROLL_ANCHOR]: fieldName
});

/**
 * Scroll to element.
 * @param anchor - element to scroll to. Can be either:
 *        1. A simple field name like "certificates" or
 *        2. A more complex selector like "certificates[0].valid_until",
 *           in which case we extract the field name "certificates" and set it to rootElement.
 */
export const getElementByDataScroll = (anchor: string): HTMLElement => {
  const anchorFieldName = anchor.includes('[') ? anchor.split('[')[0] : anchor;
  const rootElement =
    document.getElementsByName(anchorFieldName)[0] ||
    document.querySelector(`[${DATA_SCROLL_ANCHOR}="${anchorFieldName}"]`);

  const element =
    document.getElementsByName(anchor)[0] ||
    document.querySelector(`[${DATA_SCROLL_ANCHOR}="${anchor}"]`);

  return rootElement || element;
};
