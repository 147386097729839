import { Action } from 'redux';
import { AccessPermissions } from '../../../store/auth/auth.types';
import { getCloudAccountsSuccess } from '../../../store/cloudAccounts/cloudAccounts.actions';
import { CloudAccountActionTypes } from '../../../store/cloudAccounts/cloudAccounts.types';
import { getPaymentsInfoSuccess } from '../../../store/paymentMethods/paymentMethods.action';
import { PaymentMethodsTypesEnum } from '../../../store/paymentMethods/paymentMethods.types';
import { routes } from '../../../utils/constants/routes';
import { ProtectedRoutes } from '../protectedComponents.types';

export const protectedRoutesPermissions: ProtectedRoutes = {
  [routes.subscriptions.root]: 'subscription_get_subscriptions',
  [routes.databases.root]: 'subscription_get_subscriptions',
  [routes.dataAccessControl.root]: 'view_acl',
  [routes.accountSettings.root]: ['account_get_account', 'TEMP_can_access_cloud_accounts_tab'],
  [routes.accessManagement.root]: [
    'ui_can_access_team_page',
    'cloud_api_permission',
    'TEMP_can_access_saml_single_sign_on_tab'
  ],
  [routes.serviceLogs.root]: 'system_log_get',
  [routes.serviceLogs.session]: 'session_log_get',

  [routes.usageReport.root]: 'usage_report',
  [routes.billingHistory.root]: ['ui_can_access_billing_section'],
  [routes.createSubscription.root]: ['subscription_create_essentials', 'subscription_create'],
  [routes.userProfile]: ['user_get_user'],
  [routes.downloadCenter]: 'account_cam_download_rlec',
  [routes.subscriptions.subscription.root]: 'subscription_get_subscription',
  [routes.subscriptions.subscription.db.root]: 'bdb_get_bdbs',
  [routes.subscriptions.subscription.db.createBdb]: ['bdb_create', 'subscription_get_cluster'],
  [routes.subscriptions.subscription.db.createCrdb]: [
    'bdb_create',
    'subscription_get_cluster',
    'change_request_create',
    'change_request_get',
    'create_change_execute'
  ],
  [routes.subscriptions.subscription.db.bdbView]: 'bdb_get_bdb',
  [routes.subscriptions.subscription.db.crdbView]: 'crdb_get_crdb',
  [routes.subscriptions.subscription.connectivity.root]: 'rcp_get_rcp',
  [routes.subscriptions.subscription.aa.connectivity.root]: 'rcp_get_rcp',
  [routes.subscriptions.subscription.connectivity.allowList]: 'rcp_get_rcp',
  [routes.subscriptions.subscription.connectivity.vpcPeering]: 'rcp_get_rcp',
  [routes.subscriptions.subscription.connectivity.psc.root]: 'rcp_get_rcp',
  [routes.subscriptions.subscription.connectivity.psc.addConnections]: 'rcp_update',
  [routes.subscriptions.subscription.connectivity.psc.endpointDetails]: 'rcp_update',
  [routes.subscriptions.subscription.connectivity.psc.updateEndpointDetails]: 'rcp_update',
  [routes.createSubscription.essential]: 'subscription_create_essentials',
  [routes.createSubscription.pro.root]: 'subscription_create',
  [routes.changeSubscriptionPlan.root]: 'subscription_update',

  [routes.accessManagement.teamsTab]: ['user_get_users', 'ui_can_access_team_page'],
  [routes.accessManagement.apiKeysTab]: 'cloud_api_permission',
  [routes.accessManagement.singleSignOn]: 'TEMP_can_access_saml_single_sign_on_tab',
  [routes.accountSettings.account]: 'account_get_account',
  [routes.accountSettings.cloudAccounts]: 'TEMP_can_access_cloud_accounts_tab',
  [routes.dataAccessControl.usersTab]: 'view_acl',
  [routes.dataAccessControl.rolesTab]: 'view_acl',
  [routes.dataAccessControl.redisAclsTab]: 'view_acl',
  [routes.billingHistory.billings]: ['ui_can_access_billing_section', 'billing_get_billings'],
  [routes.billingHistory.payments]: ['ui_can_access_billing_section', 'paymentinfo_get_all'],
  [routes.billingHistory.credits]: ['ui_can_access_billing_section', 'coupon_get_coupons']
};

export const PROTECTED_CLASS_NAME = 'protected-component';

export const TEMP_PERMISSIONS: AccessPermissions[] = [
  'TEMP_user_account_mfa',
  'TEMP_account_mfa_enforcement',
  'TEMP_account_address',
  'TEMP_account_time_zone'
];

export const MANAGE_PASSWORDS_PERMISSION: AccessPermissions = 'TEMP_manage_passwords';

export const GCP_USER_BLOCKED_PERMISSIONS: AccessPermissions[] = [
  'TEMP_account_mfa_enforcement',
  'TEMP_user_account_mfa'
];

export const POC_USER_BLOCKED_PERMISSIONS: AccessPermissions[] = [
  'billing_get_billing',
  'billing_get_billings',
  'billing_get_invoice',
  'paymentinfo_create',
  'paymentinfo_get_all',
  'paymentinfo_delete',
  'paymentinfo_update',
  'paymentinfo_get_countries',
  'paymentinfo_validate_vatid',
  'paymentinfo_get_payment',
  'coupon_get_coupons',
  'ui_can_access_billing_section',
  'subscription_create_essentials'
];

export const PAAS_USER_BLOCKED_PERMISSIONS: AccessPermissions[] = [
  'billing_get_billing',
  'billing_get_billings',
  'billing_get_invoice',
  'paymentinfo_create',
  'paymentinfo_get_all',
  'paymentinfo_delete',
  'paymentinfo_update',
  'paymentinfo_get_countries',
  'paymentinfo_validate_vatid',
  'paymentinfo_get_payment',
  'coupon_get_coupons',
  'ui_can_access_billing_section',
  'ui_can_access_team_page',
  'subscription_create_essentials',
  'subscription_create',
  'subscription_update',
  'TEMP_account_mfa_enforcement',
  'TEMP_user_account_mfa',
  'account_zone_mapping',
  'account_ssl_ca_get',
  'cloud_account_create',
  'cloud_account_delete',
  'cloud_account_get_accounts',
  'cloud_account_get_account',
  'TEMP_can_access_cloud_accounts_tab',
  'cloud_account_update',
  'TEMP_account_address',
  'TEMP_account_time_zone',
  'user_update'
];

export const SAML_USER_BLOCKED_PERMISSIONS: AccessPermissions[] = ['TEMP_user_account_mfa'];

export const PROTECTED_SAGAS: Record<
  string,
  {
    permission: AccessPermissions;
    action: Action;
  }
> = {
  [PaymentMethodsTypesEnum.GET_PAYMENTS_INFO_REQUEST]: {
    permission: 'paymentinfo_get_all',
    action: getPaymentsInfoSuccess([])
  },
  [CloudAccountActionTypes.GET_CLOUD_ACCOUNTS_REQUEST]: {
    permission: 'cloud_account_get_accounts',
    action: getCloudAccountsSuccess([])
  }
};
