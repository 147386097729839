import {
  PaymentMethodsTypesEnum,
  PaymentsActionTypes,
  IPaymentInfoState
} from './paymentMethods.types';

const initialState = {
  paymentsInfo: { status: 'idle', data: [], error: '' } as IPaymentInfoState
};

type PaymentInfoState = typeof initialState;

const paymentMethodsReducer = (
  state = initialState,
  action: PaymentsActionTypes
): PaymentInfoState => {
  switch (action.type) {
    case PaymentMethodsTypesEnum.GET_PAYMENTS_INFO_REQUEST:
      return {
        ...state,
        paymentsInfo: {
          ...state.paymentsInfo,
          status: 'pending',
          error: ''
        }
      };

    case PaymentMethodsTypesEnum.GET_PAYMENTS_INFO_SUCCESS:
      return {
        ...state,
        paymentsInfo: {
          ...state.paymentsInfo,
          data: action.payload,
          status: 'resolved'
        }
      };

    case PaymentMethodsTypesEnum.GET_PAYMENTS_INFO_FAILURE:
      return {
        ...state,
        paymentsInfo: {
          ...state.paymentsInfo,
          status: 'rejected',
          error: action.error
        }
      };

    case PaymentMethodsTypesEnum.DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentsInfo: {
          ...state.paymentsInfo,
          data: state.paymentsInfo.data.filter(
            (paymentMethod) => paymentMethod.id !== action.payload
          )
        }
      };

    case PaymentMethodsTypesEnum.ADD_SUBS_TO_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        paymentsInfo: {
          ...state.paymentsInfo
        }
      };

    default:
      return state;
  }
};

export default paymentMethodsReducer;
