import { ApiPaths } from 'services/api/apiPaths';
import { AxiosResponse } from 'axios';
import { createResourceApi } from '../../createResourceApi';

export const bdbsApi = createResourceApi(ApiPaths.BDBS, (rest) => ({
  getAll: (params: { productType: string; subscription?: string; optimized: boolean }) =>
    rest.get<{ bdbs: Bdb[] }>({ config: { params } }),

  getOne: (bdbId: number) => rest.get<{ bdb: Bdb }>({ path: `${bdbId}` }),

  create: (bdb: Partial<Bdb>) => rest.post<{ bdb: Bdb }>({ data: { bdb } }),

  update: (bdb: Partial<Bdb>) => rest.put<{ bdb: Bdb }>({ path: `${bdb.id}`, data: { bdb } }),

  remove: (id: string) => rest.delete({ path: `${id}` }),

  getSyncSources: (cloudProvider: string) =>
    rest.get<ReplicaItem[]>({ path: 'get-sync-sources', config: { params: { cloudProvider } } }),

  validateSyncSources: ({ source, params }: ValidateSyncSourceRequestData) =>
    rest.post({ path: 'actions/validate-sync-source', data: { source }, config: { params } }),
  validatePort: (data: ValidatePortRequestData) =>
    rest.post({ path: 'actions/validate-port', data }),
  validateBackupPath: ({
    backupPath,
    sid,
    bdbId
  }: {
    backupPath: string;
    sid: number;
    bdbId?: number;
  }) =>
    rest.post({
      path: 'actions/validate-backup-path',
      data: backupPath,
      config: {
        params: { bdbId, sid }
      }
    }),

  validateRegexRules: (
    data: {
      pattern: string;
    }[]
  ) => rest.post({ path: 'actions/validate-regex-rules', data }),

  validateSourceIps: ({
    subscriptionId,
    addr
  }: {
    subscriptionId: number | string;
    addr: string;
  }) => rest.post({ path: `actions/validate-source-ips?sid=${subscriptionId}`, data: [{ addr }] }),

  getCertificateInfo: (publicKey: string): Promise<AxiosResponse<Certificate>> =>
    rest.post({ path: `certificates/info`, data: { publicKey } }),

  backup: (bdbId: number) => rest.post({ path: `${bdbId}/actions/backup` }),

  import: ({ bdbId, importBdbPostBody }: ImportDbRequestPayload) =>
    rest.post({ path: `${bdbId}/actions/import`, data: importBdbPostBody }),

  resetImport: (databaseId: string) =>
    rest.post<void>({ path: `${databaseId}/actions/import-bdb-reset` }),

  testRegexRules: (data: {
    keys: string[];
    regexRules: {
      id?: string | number;
      pattern: string;
      order: number;
    }[];
  }) => rest.post({ path: 'actions/test-regex-keys', data }),

  backups: (bdbId: number) => rest.get<{ backups: Backup[] }>({ path: `${bdbId}/backups` }),

  alerts: (bdbId: number) => rest.get<{ alerts: DbAlert[] }>({ path: `${bdbId}/alerts` }),
  getPricing: (params: { subscription?: number }) =>
    rest.get<{ bdbsShardTypePricingBdbRegion: BdbsShardTypePricingBdbRegion }>({
      path: 'pricing',
      config: { params }
    }),
  getProgress: (params: { subscription?: number }) =>
    rest.get<{ bdbsProgress: BdbProgressResponse }>({ path: 'progress', config: { params } })
}));
