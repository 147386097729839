import RedisApiAxiosInstance from 'services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { Config } from '../../config';

const initRdiMFEApiService = async () => {
  try {
    // @ts-ignore
    const apiService = (await import('rdi/initApiService')).default;
    apiService(RedisApiAxiosInstance, !Config.IS_RELATIVE_API && Config.API_ENV);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('cannot load initRdiMFEApiService', e);
  }
};

export default initRdiMFEApiService;
