import React, { FC } from 'react';
import * as Sentry from '@sentry/react';
import GeneralFallback from './GeneralFallback/GeneralFallback';
import onRuntimeError from '../utils/onRuntimeError';
import sentryTags from '../constants/sentryTags.constants';
import { SentryErrorAreaTagValues } from '../sentry.types';

interface Props extends Omit<Sentry.ErrorBoundaryProps, 'beforeCapture' | 'showDialog'> {
  errorArea: SentryErrorAreaTagValues;
  componentToFallbackTo?: JSX.Element;
}

const SentryErrorBoundary: FC<Props> = ({
  children,
  fallback,
  componentToFallbackTo,
  errorArea,
  ...rest
}) => {
  const fallbackFun = (fallbackProps) => {
    if (componentToFallbackTo) {
      return componentToFallbackTo;
    }
    if (fallback) {
      const FallbackComponent = fallback as Sentry.FallbackRender;

      return React.createElement(FallbackComponent, fallbackProps, fallback);
    }

    return (
      <GeneralFallback
        {...fallbackProps}
        isContactSupportDisplayed={errorArea !== 'authentication' && errorArea !== 'main-app'}
      />
    );
  };

  return (
    <Sentry.ErrorBoundary
      fallback={fallbackFun}
      onError={onRuntimeError}
      beforeCapture={(scope) => {
        scope.setTag(sentryTags.errorArea, errorArea);
        scope.setTag(sentryTags.runtimeError, 'true');
      }}
      {...rest}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary;
