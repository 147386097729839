import { keyBy } from 'lodash';
import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './plans.actions';
import { PlanActionTypes } from './plans.types';
import { extractErrorAndShowToast } from '../../../utils/helpers/extractErrorAndShowToast';
import { plansApi } from '../../../services/api/resources/plans/plans.resource';

function* getPlans() {
  try {
    const { data }: AxiosResponse<{ plans: Plan[] }> = yield call(() =>
      plansApi.getAll({ only_customer_plans: true })
    );
    yield put(actions.getPlansSuccess(keyBy(data.plans, 'id')));
  } catch (e) {
    if (e.response) {
      const { data } = e.response;
      yield put(actions.getPlansFailure(data));
      extractErrorAndShowToast(e);
    }
  }
}

function* planSaga() {
  yield takeLatest(PlanActionTypes.GET_PLANS_REQUEST, getPlans);
}

export default planSaga;
