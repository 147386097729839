import { Crdb } from '../databases/databases.types';

export enum ConnectionWizardTypesEnum {
  TOGGLE_CONNECTION_WIZARD_DRAWER = 'TOGGLE_CONNECTION_WIZARD_DRAWER',
  SET_OPENED_ACCORDION_ID = 'SET_OPENED_ACCORDION_ID',
  SET_CONNECTION_WIZARD_BDB = 'SET_CONNECTION_WIZARD_BDB',
  SET_CONNECTION_WIZARD_CRDB = 'SET_CONNECTION_WIZARD_CRDB'
}

export type ConnectionWizardAccordionIds = 'redisCli' | 'redisClient' | 'redisInsight';

export interface ConnectionWizardState {
  drawerOpen: boolean;
  openedAccordionId: ConnectionWizardAccordionIds | null;
  bdb: Bdb | null;
  crdb: Crdb | null;
}

export interface ToggleConnectionWizardDrawerAction {
  type: ConnectionWizardTypesEnum.TOGGLE_CONNECTION_WIZARD_DRAWER;
  payload: boolean;
}

export interface SetOpenedAccordionIdAction {
  type: ConnectionWizardTypesEnum.SET_OPENED_ACCORDION_ID;
  payload: ConnectionWizardAccordionIds;
}

export interface SetConnectionWizardBdbAction {
  type: ConnectionWizardTypesEnum.SET_CONNECTION_WIZARD_BDB;
  payload: Bdb;
}

export interface SetConnectionWizardCrdbAction {
  type: ConnectionWizardTypesEnum.SET_CONNECTION_WIZARD_CRDB;
  payload: Crdb;
}

export type ConnectionWizardActionTypes =
  | ToggleConnectionWizardDrawerAction
  | SetOpenedAccordionIdAction
  | SetConnectionWizardBdbAction
  | SetConnectionWizardCrdbAction;
