import { AxiosResponse } from 'axios';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { EssentialsActionTypes, PostEssentialPlanRequestAction } from './essentials.types';
import { postEssentialPlanFailure, postEssentialPlanSuccess } from './essentials.actions';
import { accountIdSelector } from '../../auth/auth.selectors';
import { createSubscriptionPostParams } from './essentials.utils';
import { addNewSubscription } from '../../subscriptions/subscriptions.actions';
import { extractErrorAndShowToast } from '../../../utils/helpers/extractErrorAndShowToast';
import { essentialSubscriptionPayNowUrlTarget } from './essentials.constants';
import * as planActions from '../../subscriptions/plans/plans.actions';
import { subscriptionsApi } from '../../../services/api/resources/subscriptions/subscriptions.resource';

function* postEssentialPlanSaga({ payload }: PostEssentialPlanRequestAction) {
  const { successCb, errorCb, ...rest } = payload;
  const account: string = yield select(accountIdSelector);

  const essentialPlanBody = createSubscriptionPostParams(rest, account);

  try {
    const {
      data: { subscription }
    }: AxiosResponse<{ subscription: Subscription }> = yield call(
      subscriptionsApi.create,
      essentialPlanBody
    );

    yield put(postEssentialPlanSuccess());
    yield put(planActions.getPlans());
    yield put(addNewSubscription(subscription));

    successCb && successCb(subscription);

    if (subscription.pay_now_url) {
      window.open(
        subscription.pay_now_url,
        `${essentialSubscriptionPayNowUrlTarget} ${subscription.id}`
      );
    }
  } catch (e) {
    if (e.response) {
      yield put(postEssentialPlanFailure(e.response?.message));
      extractErrorAndShowToast(e);
    }
    errorCb && errorCb();
  }
}

export default function* essentialsSagaWatcher() {
  yield takeLatest(EssentialsActionTypes.POST_ESSENTIAL_PLAN_REQUEST, postEssentialPlanSaga);
}
