import { all, fork } from 'redux-saga/effects';
import authSaga from './auth/auth.saga';
import dataAccessControl from './dataAccessControl/dataAccessControl.saga';
import serviceLogsSaga from './systemLogs/systemLogs.saga';
import paymentMethodsSaga from './paymentMethods/paymentMethods.saga';
import creditsSaga from './credits/credits.saga';
import allowListSaga from './connectivity/allowList/allowList.saga';
import essentialsSaga from './createSubscription/essentials/essentials.saga';
import proSagaWatcher from './createSubscription/pro/pro.saga';
import subscriptionsSaga from './subscriptions/subscriptions.saga';
import databasesSaga from './databases/databases.saga';
import plansSaga from './subscriptions/plans/plans.saga';
import accountSagaWatcher from './account/account.saga';
import cloudAccountSagaWatcher from './cloudAccounts/cloudAccounts.saga';
import mfaSaga from './mfa/mfa.saga';

export default function* sagas() {
  yield all([
    fork(authSaga),
    fork(dataAccessControl),
    fork(serviceLogsSaga),
    fork(paymentMethodsSaga),
    fork(creditsSaga),
    fork(plansSaga),
    fork(allowListSaga),
    fork(essentialsSaga),
    fork(subscriptionsSaga),
    fork(databasesSaga),
    fork(proSagaWatcher),
    fork(accountSagaWatcher),
    fork(cloudAccountSagaWatcher),
    fork(mfaSaga)
  ]);
}
