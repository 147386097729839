import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { isHerokuAccountSelector } from '../../../../../store/account/account.selectors';
import { i18translation } from '../../../../../locale/translations/i18NsPaths';
import {
  MAX_NAME_FIELD_VALUE,
  MAX_PASSWORD_FIELD_VALUE,
  MIN_NAME_FIELD_VALUE,
  MIN_PASSWORD_FIELD_VALUE
} from '../../../utils/constants';
import {
  EmailRegex,
  herokuNameRegex,
  nameRegex as usualRegEx,
  passwordRegex
} from '../../../../../utils/validations/formRegexes';

const useBuildValidationSchema = (t: TFunction) => {
  const isHeroku = useSelector(isHerokuAccountSelector);
  const nameRegex = isHeroku ? herokuNameRegex : usualRegEx;
  const keyPrefix = i18translation.LoginScreen.signUpScreen;

  return Yup.object().shape({
    email: Yup.string()
      .required(t(keyPrefix.validations.required))
      .matches(EmailRegex, t(keyPrefix.validations.email)),
    first_name: Yup.string()
      .required(t(keyPrefix.validations.required))
      .min(
        MIN_NAME_FIELD_VALUE,
        t(keyPrefix.validations.firstNameMin, {
          minValue: MIN_NAME_FIELD_VALUE
        })
      )
      .max(
        MAX_NAME_FIELD_VALUE,
        t(keyPrefix.validations.firstNameMax, {
          maxValue: MAX_NAME_FIELD_VALUE
        })
      )
      .matches(
        nameRegex,
        t(isHeroku ? keyPrefix.validations.nameRegexHeroku : keyPrefix.validations.nameRegex)
      ),
    last_name: Yup.string()
      .required(t(keyPrefix.validations.required))
      .min(
        MIN_NAME_FIELD_VALUE,
        t(keyPrefix.validations.lastNameMin, { minValue: MIN_NAME_FIELD_VALUE })
      )
      .max(
        MAX_NAME_FIELD_VALUE,
        t(keyPrefix.validations.lastNameMax, { maxValue: MAX_NAME_FIELD_VALUE })
      )
      .matches(
        nameRegex,
        t(isHeroku ? keyPrefix.validations.nameRegexHeroku : keyPrefix.validations.nameRegex)
      ),
    password: Yup.string()
      .required(t(keyPrefix.validations.required))
      .min(
        MIN_PASSWORD_FIELD_VALUE,
        t(keyPrefix.validations.passwordMin, {
          minValue: MIN_PASSWORD_FIELD_VALUE
        })
      )
      .matches(passwordRegex, t(keyPrefix.validations.passwordRegex))
      .max(
        MAX_PASSWORD_FIELD_VALUE,
        t(keyPrefix.validations.passwordMAx, {
          minValue: MAX_PASSWORD_FIELD_VALUE
        })
      ),
    confirm_password: Yup.string().when('password', {
      is: (value) => value,
      then: Yup.string()
        .required(t(keyPrefix.validations.required))
        .oneOf([Yup.ref('password'), null], t(keyPrefix.validations.passwordMatch))
    }),
    agreement: Yup.boolean()
      .required(t(keyPrefix.validations.required))
      .equals([true], t(keyPrefix.validations.agreement))
  });
};

export default useBuildValidationSchema;
