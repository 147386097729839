import { filter } from 'lodash';
import { subscriptionDbsIdsSelector } from '../subscriptions/subscriptions.selectors';

const databasesReducerSelector = (state: RootState) => state.databases;

export const databasesStateSelector = (state: RootState) => databasesReducerSelector(state).dbs;

export const bdbsSelector = (state: RootState) => databasesStateSelector(state).data;

export const bdbByIdSelector = (bdbId: string | number) => (state: RootState) =>
  bdbsSelector(state)[bdbId];

export const multipleBdbsByIdSelector = (bdbIds: number[]) => (state: RootState) => {
  const bdbs = bdbsSelector(state);

  return bdbIds.map((bdbId) => bdbs[bdbId]).filter(Boolean);
};

export const dbsWithReplicationArrSelector = (state: RootState) =>
  filter(bdbsSelector(state), (bdb) => Boolean(bdb.replica_of?.length));

export const databasesStatusAndErrorSelector = (state: RootState) => ({
  status: databasesStateSelector(state).status,
  error: databasesStateSelector(state).error
});

export const databasesStateStatusSelector = (state: RootState) =>
  databasesStatusAndErrorSelector(state)?.status;

export const databasesBySubIdSelector = (subscriptionId: number) => (state: RootState) => {
  const subBdbsIds = subscriptionDbsIdsSelector(String(subscriptionId))(state);
  const dbs = bdbsSelector(state);

  return subBdbsIds.map((bdbId) => dbs[bdbId]).filter(Boolean);
};

export const isDatabasePendingSelector = (subscriptionId: number) => (state: RootState) =>
  databasesBySubIdSelector(subscriptionId)(state).some(
    (db) => db.status === 'active-change-pending' || db.status === 'pending'
  );

export const bdbSyncSourcesSelector = (state: RootState) =>
  databasesReducerSelector(state).bdbSyncSources;

export const validateBdbSyncSourcesStateSelector = (state: RootState) =>
  databasesReducerSelector(state).validateBdbSyncSourcesState;

export const backupDbStateSelector = (state: RootState) =>
  databasesReducerSelector(state).backupDbState;

export const importDbStateSelector = (state: RootState) =>
  databasesReducerSelector(state).importDbState;

export const newPriceDataSelector = (state: RootState) =>
  databasesReducerSelector(state).newPriceData;

export const newDbRcpPlanSelector = (state: RootState) =>
  databasesReducerSelector(state).newDbRcpPlan;

export const shouldShowClusteringDialogSelector = (state: RootState) =>
  databasesReducerSelector(state).shouldShowClusteringDialog;

export const shouldShowBdbOptimizationDialogSelector = (state: RootState) =>
  databasesReducerSelector(state).shouldShowBdbOptimizationDialog;

export const databasesTablePageSizeSelector = (state: RootState) =>
  databasesReducerSelector(state).databasesTablePageSize;

export const showRedisStackCongratulationsSelector = (state: RootState) =>
  databasesReducerSelector(state).showRedisStackCongratulations;
