import {
  ProActionTypes,
  PostProPlanRequestAction,
  PostProPlanSuccessAction,
  PostProPlanFailureAction,
  PostProPlanSuccessPayload,
  PostProSubscriptionRequestAction,
  PostProSubscriptionSuccessAction,
  PostProSubscriptionFailureAction,
  ResetProStateAction,
  CalculatePriceRequestAction,
  CalculatePriceRequestPayload,
  CalculatePriceSuccessAction,
  CalculatePriceSuccessPayload,
  CalculatePriceFailureAction,
  ClearProPlanAction,
  CreateAASubscriptionRequestAction,
  CreateAASubscriptionSuccessAction,
  CreateAASubscriptionFailureAction,
  AASubscriptionRequestPayload,
  PostProPlanRequestActionPayload,
  CreateOnlyAASubscriptionRequestAction,
  PostCreateOnlyAASubscriptionSuccessAction,
  PostAaChangeRequestSuccessPayload,
  ExecuteAASubscriptionRequestAction,
  ExecuteAaSubsCallParams
} from './pro.types';

export const postProPlanRequest = (
  payload: PostProPlanRequestActionPayload
): PostProPlanRequestAction => ({
  type: ProActionTypes.POST_PRO_PLAN_REQUEST,
  payload
});

export const postProPlanSuccess = (
  payload: PostProPlanSuccessPayload
): PostProPlanSuccessAction => ({
  type: ProActionTypes.POST_PRO_PLAN_SUCCESS,
  payload
});

export const postProPlanFailure = (): PostProPlanFailureAction => ({
  type: ProActionTypes.POST_PRO_PLAN_FAILURE
});

export const clearProPlan = (): ClearProPlanAction => ({
  type: ProActionTypes.CLEAR_PRO_PLAN
});

export const postProSubscriptionRequest = (
  payload: PostProSubscriptionRequestAction['payload']
): PostProSubscriptionRequestAction => ({
  type: ProActionTypes.POST_PRO_SUBSCRIPTION_REQUEST,
  payload
});

export const postProSubscriptionSuccess = (): PostProSubscriptionSuccessAction => ({
  type: ProActionTypes.POST_PRO_SUBSCRIPTION_SUCCESS
});

export const postProSubscriptionFailure = (): PostProSubscriptionFailureAction => ({
  type: ProActionTypes.POST_PRO_SUBSCRIPTION_FAILURE
});

export const calculatePriceRequest = (
  payload: CalculatePriceRequestPayload
): CalculatePriceRequestAction => ({
  type: ProActionTypes.CALCULATE_PRICE_REQUEST,
  payload
});

export const calculatePriceSuccess = (
  payload: CalculatePriceSuccessPayload
): CalculatePriceSuccessAction => ({
  type: ProActionTypes.CALCULATE_PRICE_SUCCESS,
  payload
});

export const calculatePriceFailure = (error: string): CalculatePriceFailureAction => ({
  type: ProActionTypes.CALCULATE_PRICE_FAILURE,
  payload: error
});

export const resetProState = (): ResetProStateAction => ({
  type: ProActionTypes.RESET_PRO_STATE
});

export const createAASubscription = (
  payload: AASubscriptionRequestPayload
): CreateAASubscriptionRequestAction => ({
  type: ProActionTypes.CREATE_AA_SUBSCRIPTION_REQUEST,
  payload
});

export const createAASubscriptionSuccess = (): CreateAASubscriptionSuccessAction => ({
  type: ProActionTypes.CREATE_AA_SUBSCRIPTION_SUCCESS
});

export const createAASubscriptionFailure = (error: string): CreateAASubscriptionFailureAction => ({
  type: ProActionTypes.CREATE_AA_SUBSCRIPTION_FAILURE,
  payload: error
});

export const createOnlyAASubscription = (
  payload: AASubscriptionRequestPayload
): CreateOnlyAASubscriptionRequestAction => ({
  type: ProActionTypes.CREATE_ONLY_AA_SUBSCRIPTION_REQUEST,
  payload
});

export const postCreateOnlyAASubscriptionSuccess = (
  payload: PostAaChangeRequestSuccessPayload
): PostCreateOnlyAASubscriptionSuccessAction => ({
  type: ProActionTypes.CREATE_ONLY_AA_SUBSCRIPTION_SUCCESS,
  payload
});

export const executeAASubscription = (
  payload: ExecuteAaSubsCallParams
): ExecuteAASubscriptionRequestAction => ({
  type: ProActionTypes.EXECUTE_AA_SUBSCRIPTION_REQUEST,
  payload
});
