import { ApiPaths } from 'services/api/apiPaths';
import RedisApiAxiosInstance from '../../services/api/RedisApiAxiosInstance/RedisApiAxiosInstance';
import { SubscriptionsPaymentData } from './paymentMethods.types';

export const getPaymentsInfo = () => RedisApiAxiosInstance.get(ApiPaths.PAYMENT_INFOS);

export const deletePaymentInfo = (id: number) =>
  RedisApiAxiosInstance.delete(`${ApiPaths.PAYMENT_INFOS}/${id}`);

export const addSubsToPaymentInfo = ({ pmID, subIDs }: SubscriptionsPaymentData) =>
  RedisApiAxiosInstance.post(`${ApiPaths.PAYMENT_INFOS}/${pmID}/actions/update-subs/`, subIDs);
