import convertMonthToHours from './convertMonthToHours';

const oldCalculateSubscriptionPrice = (
  nodes: RcpPlanStatusResponse['nodes'],
  dbsPrice: number = 0,
  storagePrice: number = 0
) => {
  if (Array.isArray(nodes)) {
    const requiredCloudRecoursesTotal = nodes.reduce((acc, node) => acc + node.usd_per_month, 0);

    const monthlyPrice = dbsPrice + requiredCloudRecoursesTotal + storagePrice;

    if (monthlyPrice) {
      return {
        priceForHour: convertMonthToHours(monthlyPrice),
        monthlyPrice: monthlyPrice.toFixed(2)
      };
    }
  }

  return {
    priceForHour: 0,
    monthlyPrice: 0
  };
};

export default oldCalculateSubscriptionPrice;
