import { isInteger } from 'lodash';

const convertCommaSeparatedParamToArray = (param: string | number) => {
  if (typeof param !== 'string') {
    if (isInteger(param)) {
      return [param];
    }

    return [];
  }

  const result = param
    .replace(/\s+/g, '')
    .split(',')
    .filter((str) => str)
    .map(Number);

  if (!result || result?.some((regionId) => !Number.isInteger(regionId))) {
    return [];
  }

  return result;
};

export default convertCommaSeparatedParamToArray;
