import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../../../store/auth/auth.action';
import { mfaPayloadSelector } from '../../../../../store/auth/auth.selectors';
import { MfaTypes } from '../../../../../store/auth/auth.types';

const useMfaForm = () => {
  const dispatch = useDispatch();
  const { username, referer } = useSelector(mfaPayloadSelector);
  // sets a flag in order to remember the answer and attach it to the login request after a mfa
  // confirmation
  const migrateToSocialLoginAgreement = window.sessionStorage.getItem(
    'migrateToSocialLoginAgreement'
  );
  const migrateToSamlAgreement = window.sessionStorage.getItem('migrateToSamlAgreement');

  const handleAuthFactorSubmit = (mfaType: MfaTypes, authCode: string) => {
    dispatch(
      login({
        mfa_code: authCode,
        referer,
        username,
        mfa_type: mfaType,
        migrateToSocialLoginAgreement,
        migrateToSamlAgreement,
        callCsrfFlag: true
      })
    );
  };

  return { handleAuthFactorSubmit };
};

export default useMfaForm;
