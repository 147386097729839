import { ITheme } from '@redislabsdev/redis-ui-styles';
import styled from 'styled-components/macro';

export const RadioButtonsContainer = styled.fieldset<{
  labelsColor: keyof ITheme['semantic']['color']['text'];
}>`
  border: none;
  color: ${({ theme, labelsColor }) => theme.semantic.color.text[labelsColor]};
  display: flex;
  align-items: center;

  label {
    margin: 0;
    margin-left: 0.4rem;
  }
`;

export const Legend = styled.legend`
  margin-bottom: 1.7rem;
  font-size: 1.4rem;
`;
