import { isEmpty, values } from 'lodash';
import store from '..';
import history from '../../hashHistory';
import { buildPath, routes } from '../../utils/constants/routes';
import { sortById } from '../../utils/helpers/misc';
import subscriptionUtils from '../../utils/subscription/subscription.utils';
import { subscriptionsSelector } from './subscriptions.selectors';

export const routeToFirstSubscription = (
  subscriptions: Subscription[] = values(subscriptionsSelector(store.getState()))
) => {
  if (subscriptions.length) {
    const sortedSubs = [...sortById(subscriptions)];
    const notDeletingSub = sortedSubs.find((sub) => {
      return !subscriptionUtils.isSubDeleting(sub);
    });

    const selectedSub = isEmpty(notDeletingSub) ? sortedSubs[0] : notDeletingSub;
    history.push(
      buildPath(routes.subscriptions.subscription.db.root, { subscriptionId: selectedSub?.id })
    );
  } else if (history.location.pathname !== routes.subscriptions.root) {
    history.push(routes.subscriptions.root);
  }
};

export const getWindowOpenTarget = (subId: number) =>
  `update subscription blue snap payment ${subId}`;
