import { SignUpFormValues } from './SignUpForm.types';

export const INITIAL_VALUES: SignUpFormValues = {
  confirm_password: '',
  country_code: '',
  country: '',
  state: '',
  state_code: '',
  email: '',
  first_name: '',
  last_name: '',
  password: '',
  agreement: false
};
