import { SubscriptionRequiredPostPayload } from '../createSubscription.types';

export enum EssentialsActionTypes {
  POST_ESSENTIAL_PLAN_REQUEST = 'POST_ESSENTIAL_PLAN_REQUEST',
  POST_ESSENTIAL_PLAN_SUCCESS = 'POST_ESSENTIAL_PLAN_SUCCESS',
  POST_ESSENTIAL_PLAN_FAILURE = 'POST_ESSENTIAL_PLAN_FAILURE',

  INCREMENT_PLAN_SELECT_COUNT = 'INCREMENT_PLAN_SELECT_COUNT',
  RESET_PLAN_SELECT_COUNT = 'RESET_PLAN_SELECT_COUNT'
}

export interface PostEssentialPlanRequestAction {
  type: EssentialsActionTypes.POST_ESSENTIAL_PLAN_REQUEST;
  payload: SubscriptionRequiredPostPayload;
}

export interface PostEssentialPlanSuccessAction {
  type: EssentialsActionTypes.POST_ESSENTIAL_PLAN_SUCCESS;
}

export interface PostEssentialPlanFailureAction {
  type: EssentialsActionTypes.POST_ESSENTIAL_PLAN_FAILURE;
  payload: string;
}

interface IncrementPlanSelectCountAction {
  type: EssentialsActionTypes.INCREMENT_PLAN_SELECT_COUNT;
}

interface ResetPlanSelectCount {
  type: EssentialsActionTypes.RESET_PLAN_SELECT_COUNT;
}

export type EssentialsActions =
  | PostEssentialPlanRequestAction
  | PostEssentialPlanSuccessAction
  | PostEssentialPlanFailureAction
  | IncrementPlanSelectCountAction
  | ResetPlanSelectCount;

export interface EssentialPlan extends Plan {
  isSelectable: boolean;
}
