import { ITheme } from '@redislabsdev/redis-ui-styles';
import styled, { css } from 'styled-components/macro';

export const ToggleContainer = styled.div<{
  disabled: boolean;
  labelColor: keyof ITheme['semantic']['color']['text'];
}>`
  display: flex;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;

      label {
        cursor: not-allowed;
      }
    `}
  label {
    background-color: transparent;
  }

  input + label {
    color: ${({ theme, labelColor }) => theme.semantic.color.text[labelColor]};
  }
`;

export const ToggleStateIndicator = styled.label<{ disabled: boolean }>`
  margin-left: 0.4rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: pointer;
`;
