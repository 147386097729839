import {
  SubscriptionsActionTypes,
  GetSubscriptionRequestAction,
  GetSubscriptionSuccessAction,
  GetSubscriptionFailureAction,
  StartSubscriptionPollingRequestAction,
  DeleteSubscriptionSuccessAction,
  UpdateSubscriptionRequestAction,
  UpdateSubscriptionSuccessAction,
  UpdateSubscriptionFailureAction,
  ResetUpdateSubscriptionAction,
  SetSubscriptionsStatusAction,
  AddNewSubscriptionAction
} from './subscriptions.types';

export const startSubscriptionsPolling = (): StartSubscriptionPollingRequestAction => ({
  type: SubscriptionsActionTypes.START_SUBSCRIPTIONS_POLLING
});

export const getSubscriptions = (): GetSubscriptionRequestAction => ({
  type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_REQUEST
});

export const setSubscriptionsStatus = (
  payload: ReducerStateStatus
): SetSubscriptionsStatusAction => ({
  type: SubscriptionsActionTypes.SET_SUBSCRIPTIONS_STATUS,
  payload
});

export const getSubscriptionsSuccess = (data: SubById): GetSubscriptionSuccessAction => ({
  type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_SUCCESS,
  payload: data
});

export const getSubscriptionsFailure = (error: string): GetSubscriptionFailureAction => ({
  type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_FAILURE,
  payload: error
});

export const deleteSubscriptionSuccess = (subsId: number): DeleteSubscriptionSuccessAction => ({
  type: SubscriptionsActionTypes.DELETE_SUBSCRIPTION_SUCCESS,
  payload: subsId
});

export const updateSubscriptionRequest = (
  payload: UpdateSubscriptionRequestAction['payload']
): UpdateSubscriptionRequestAction => ({
  type: SubscriptionsActionTypes.UPDATE_SUBSCRIPTION_REQUEST,
  payload
});

export const updateSubscriptionSuccess = (
  payload: Subscription
): UpdateSubscriptionSuccessAction => ({
  type: SubscriptionsActionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
  payload
});

export const updateSubscriptionFailure = (error: string): UpdateSubscriptionFailureAction => ({
  type: SubscriptionsActionTypes.UPDATE_SUBSCRIPTION_FAILURE,
  payload: error
});

export const resetUpdateSubscriptionState = (): ResetUpdateSubscriptionAction => ({
  type: SubscriptionsActionTypes.RESET_UPDATE_SUBSCRIPTION_STATE
});

export const addNewSubscription = (subscription: Subscription): AddNewSubscriptionAction => ({
  type: SubscriptionsActionTypes.ADD_NEW_SUBSCRIPTION,
  payload: subscription
});
